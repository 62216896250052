import { useState, useEffect, useRef } from "react";
import ticking from "../sounds/clockTick.wav";
import westminsterChime from "../sounds/Westminster-chimes.mp3";
import { useOnScreen } from "hooks";
import clockImage from "./klydo_2_medium_clean.png";
import {
  FaCaretDown,
  FaMagnifyingGlassPlus,
  FaMagnifyingGlassMinus,
} from "react-icons/fa6";
import { isVideo } from "utils";
import ReactDOM from "react-dom";

// Audio files
const hourChime = new Audio(westminsterChime);
const tickTok = new Audio(ticking);

type ClockViewProps = {
  clockBodyTopOffset: number;
  clockBodyUrl: string;
  clockSize: number;
  colorScheme: string;
  loopUrl: string;
  pendulumLoopUrl: string;
  gifSize: number;
  themeName: string;
  themeID: string;
  handsColor: string;
  pendulumColor: string;
  backgroundColor: string;
  pendulumRodColor: string;
  dialsColor: string;
  hourHandWidth: number;
  isClockInDisplayMode: boolean;
  isPrototypeDisplay: boolean;
  isSecondHandVisible: boolean;
  minuteHandWidth: number;
  showBody: boolean;
  threadLength: number;
  threadWidth: number;
  timeSet: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  scale: number;
  activeCrop: boolean;
  crop?: { left: number; top: number; zoom: number };
  onCrop?: (zoom: number, left: number, top: number) => void;
  showDials?: boolean;
} & typeof defaultClockViewProps;

const defaultClockViewProps = {
  clockBodyTopOffset: 30,
  clockBodyUrl: "",
  clockSize: 355,
  colorScheme: "light",
  loopUrl: "",
  pendulumLoopUrl: "",
  gifSize: 365,
  themeName: "fox",
  themeID: "80328038108203280",
  handsColor: "#aeaeae",
  hourHandWidth: 14,
  isClockInDisplayMode: false,
  isPrototypeDisplay: false,
  isSecondHandVisible: false,
  minuteHandWidth: 10,
  showBody: true,
  threadLength: 2,
  threadWidth: 2,
  timeSet: 0,
  scale: 1,
  activeCrop: false,
  onCrop: undefined,
};
let innerStep = 1;
let docFocus = false;
let maxTop = -100;
const maxButtom = 100;
const minZoom = 1;
const secondsForMin = 3600;
const secondsForHour = 43200;
const ClockView = (props: ClockViewProps): JSX.Element => {
  const [cropStep, setCropStep] = useState(1);
  const [focusOn, setFocusOn] = useState(false); //props.activeCrop && props.loopUrl !== "")
  const [fromTop, setFromTop] = useState(props.crop?.top || 0);
  const [fromLeft, setFromLeft] = useState(props.crop?.left || 0);
  const [zoom, setZoom] = useState(props.crop?.zoom || 100);
  const indices = Array.from({ length: 12 }, (_, i) => i + 1);
  const moveUp = (s: number) =>
    setFromTop((ct) => (ct - s <= maxTop ? maxTop : ct - s));
  const moveDown = (s: number) =>
    setFromTop((cd) => (cd + s >= maxButtom ? maxButtom : cd + s));
  const moveLeft = (s: number) =>
    setFromLeft((cl) => (cl - s <= maxTop ? maxTop : cl - s));
  const moveRight = (s: number) =>
    setFromLeft((cl) => (cl + s >= maxButtom ? maxButtom : cl + s));
  const zoomIn = (s: number) => setZoom((cz) => cz + s);
  const zoomOut = (s: number) =>
    setZoom((cz) => (cz - s <= minZoom ? minZoom : cz - s));
  const releaseCtrl = (event: KeyboardEvent) => {
    if (event.key === "Control") {
      setCropStep(1);
      innerStep = 1;
    }
  };
  const KeyEventHandle = (event: KeyboardEvent) => {
    if (!docFocus) return;
    switch (event.key) {
      case "+":
        event.preventDefault();
        zoomIn(innerStep);
        break;
      case "=":
        event.preventDefault();
        zoomIn(innerStep);
        break;
      case "-": {
        event.preventDefault();
        zoomOut(innerStep);
        break;
      }
      case "ArrowUp": {
        event.preventDefault();
        moveUp(innerStep);
        break;
      }
      case "ArrowDown": {
        event.preventDefault();
        moveDown(innerStep);
        break;
      }
      case "ArrowLeft": {
        event.preventDefault();
        moveLeft(innerStep);
        break;
      }
      case "ArrowRight": {
        event.preventDefault();
        moveRight(innerStep);
        break;
      }
      case "Control": {
        event.preventDefault();
        setCropStep(0.1);
        innerStep = 0.1;
        break;
      }
    }
  };
  const clickListtener = (e: MouseEvent) => {
    const isc = (el: HTMLElement): boolean => {
      let re: HTMLElement = el;
      while ((re = re.parentElement)) if (re === ref.current) return true;
      return false;
    };
    const r = isc(e.target as HTMLElement);
    setFocusOn(r);
    docFocus = r;
  };
  const ref = useRef<HTMLDivElement>(null);
  const handMin = useRef<HTMLDivElement>(null);
  const handHour = useRef<HTMLDivElement>(null);
  const handHourS = useRef<HTMLDivElement>(null);
  const handMinS = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const ii = useRef<any>();
  useEffect(() => {
    maxTop = -100 * (zoom / 100);
    if (props.onCrop === undefined) return;
    props.onCrop(zoom, fromLeft, fromTop);
  }, [zoom, fromLeft, fromTop]);
  // useEffect(() => {
  //   if (!props.activeCrop) return
  //   docFocus = focusOn
  //   document.addEventListener('click', clickListtener)
  //   document.addEventListener('keydown', KeyEventHandle)
  //   document.addEventListener('keyup', releaseCtrl)
  //   return () => {
  //     document.removeEventListener('keydown', KeyEventHandle)
  //     document.removeEventListener('keyup', releaseCtrl)
  //     document.removeEventListener('click', clickListtener)
  //   }
  // }, [props.activeCrop])
  useEffect(() => {
    const updHands = () => {
      const dt = new Date();
      if (props.timeSet) {
        dt.setHours(props.timeSet / 60);
        dt.setMinutes(props.timeSet % 60);
      }
      const seconds = dt.getMinutes() * 60 + dt.getSeconds();
      const dgmin = (seconds / secondsForMin) * 360;
      if (handMin.current != null)
        handMin.current.style.transform = "rotate(" + dgmin + "deg)";
      if (handMinS.current != null)
        handMinS.current.style.transform = "rotate(" + dgmin + "deg)";
      const hours = (dt.getHours() % 12) * 3600 + seconds;
      const dgh = (hours / secondsForHour) * 360;
      if (handHour.current != null)
        handHour.current.style.transform = "rotate(" + dgh + "deg)";
      if (handHourS.current != null)
        handHourS.current.style.transform = "rotate(" + dgh + "deg)";
    };
    if (!isVisible || props.timeSet) {
      clearInterval(ii.current);
    } else {
      ii.current = setInterval(() => {
        updHands();
      }, 2000);
      updHands();
    }
    if (props.timeSet) {
      updHands();
    }
    return () => {
      clearInterval(ii.current);
    };
  }, [isVisible, props.timeSet]);
  const playTickTok = () => {
    //tickTok.loop = true;
    tickTok.volume = 0.05;
    tickTok.play();
  };

  // const stopTickTok = () => {
  //   tickTok.pause();
  //   tickTok.currentTime = 0;
  // };

  const onRoundHour = () => {
    hourChime.play();
  };

  return (
    <div
      ref={ref}
      style={{ position: "absolute", width: 100 + "%", height: 100 + "%" }}
    >
      <div //background
        style={{
          position: "absolute",
          top: 5 + "%",
          left: 5 + "%",
          width: 90 + "%",
          height: 90 + "%",
          backgroundColor: props.backgroundColor,
        }}
      />
      {props.activeCrop && focusOn && (
        <div
          style={{ position: "relative", width: "100%", aspectRatio: "1/1" }}
        >
          <div //maginf
            style={{
              position: "absolute",
              width: "12%",
              margin: "10%",
              zIndex: 10,
            }}
          >
            <FaMagnifyingGlassPlus
              onClick={() => zoomIn(cropStep)}
              style={{ width: "50%", height: "auto" }}
              color="white"
            />
            <FaMagnifyingGlassMinus
              onClick={() => zoomOut(cropStep)}
              style={{ width: "50%", height: "auto" }}
              color={zoom === 1 ? "#555555" : "white"}
            />
          </div>
          <div //arrows <->
            style={{
              position: "absolute",
              width: "100%",
              height: "8%",
              top: "47%",
              padding: "0 5%",
              zIndex: 10,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FaCaretDown
              onClick={() => moveLeft(cropStep)}
              style={{ rotate: "90deg", height: "100%", width: "auto" }}
              color={fromLeft <= maxTop ? "#555555" : "white"}
            />
            <FaCaretDown
              onClick={() => moveRight(cropStep)}
              style={{ rotate: "270deg", height: "100%", width: "auto" }}
              color={fromLeft >= 100 ? "#555555" : "white"}
            />
          </div>
          <div //arrows ^ | v
            style={{
              position: "absolute",
              width: "8%",
              height: "100%",
              left: "47%",
              padding: "5% 0",
              zIndex: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FaCaretDown
              onClick={() => moveUp(cropStep)}
              style={{ rotate: "180deg", width: "100%", height: "auto" }}
              color={fromTop <= maxTop ? "#555555" : "white"}
            />
            <FaCaretDown
              onClick={() => moveDown(cropStep)}
              style={{ width: "100%", height: "auto" }}
              color={fromTop >= 100 ? "#555555" : "white"}
            />
          </div>
        </div>
      )}
      <div //pendulum element
        style={{
          left: 35 + "%",
          top: 29 + "%",
          width: 30 + "%",
          height: 60 + "%",
          position: "absolute",
          transformOrigin: "50% 0px",
          animation: "2s ease-in-out 0s infinite normal none running swing-4",
        }}
      >
        <div //rod
          style={{
            position: "absolute",
            left: 48.5 + "%",
            transformOrigin: "50% 0px",
            backgroundColor: props.pendulumRodColor,
            width: 4 + "%",
            height: 71 + "%",
          }}
        ></div>
        <div //disc
          style={{
            position: "absolute",
            borderRadius: 50 + "%",
            padding: 50 + "%",
            width: 100 + "%",
            bottom: 0,
            backgroundColor: props.pendulumColor,
            boxShadow: "rgba(0, 0, 0, 0.55) 0px 2px 3.2px 0px",
          }}
        ></div>
      </div>
      <div //gif frame
        style={{
          width: 77 + "%",
          aspectRatio: "1/1",
          top: 6.95 + "%",
          left: 11.6 + "%",
          position: "absolute",
          overflow: "hidden",
        }}
      >
        {props.showDials &&
          [
            {
              x: 72.3334,
              y: 8.130206022131384,
            },
            {
              x: 89.53659397786862,
              y: 25.333400000000005,
            },
            {
              x: 95.8334,
              y: 48.8334,
            },
            {
              x: 89.53659397786862,
              y: 72.3334,
            },
            {
              x: 72.3334,
              y: 89.5365939778686,
            },
            {
              x: 48.8334,
              y: 95.8334,
            },
            {
              x: 25.33340000000001,
              y: 89.53659397786862,
            },
            {
              x: 8.130206022131377,
              y: 72.3334,
            },
            {
              x: 1.8334,
              y: 48.833400000000005,
            },
            {
              x: 8.130206022131384,
              y: 25.333399999999997,
            },
            {
              x: 25.33339999999998,
              y: 8.130206022131398,
            },
            {
              x: 48.83339999999999,
              y: 1.8334,
            },
          ].map(({ x, y }, index) => {
            return (
              <div
                key={index}
                style={{
                  top: `${y}%`,
                  left: `${x}%`,
                  position: "absolute",
                  fontSize: "1.2em",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 10,
                  color: props.dialsColor,
                  borderRadius: "50%",
                  width: "2.333%",
                  height: "2.333%",
                  backgroundColor: props.dialsColor,
                }}
              ></div>
            );
          })}
        <div
          style={{
            position: "absolute",
            top: fromTop + "%",
            left: fromLeft + "%",
            width: zoom + "%",
            aspectRatio: "1/1",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isVideo(props.loopUrl) === "image" ? (
            <img
              loading="lazy"
              src={props.loopUrl}
              style={{ width: "auto", height: "100%", objectFit: "cover" }}
            />
          ) : isVideo(props.loopUrl) === "video" ? (
            <video
              muted={true}
              src={props.loopUrl.replace(/\/c_thumb,w_[0-9]+/, "")}
              style={{ width: "auto", height: "100%", objectFit: "cover" }}
              autoPlay
              loop
            />
          ) : (
            <div
              style={{
                width: "100%",
                aspectRatio: "1",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p>This file type is not suported</p>
            </div>
          )}
        </div>
      </div>
      <time
        dateTime="2023-06-30T04:48:07.668Z"
        style={{
          padding: 38.5 + "%",
          top: 6.95 + "%",
          position: "absolute",
          left: 11.6 + "%",
        }}
      >
        <div //hour shadow
          ref={handHourS}
          style={{
            position: "absolute",
            width: 100 + "%",
            height: 100 + "%",
            left: 0.4 + "%",
            top: 1.2 + "%",
            transformOrigin: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              borderRadius: 4 + "px",
              width: 3 + "%",
              height: 25 + "%",
              bottom: 46 + "%",
              left: 48 + "%",
              background:
                "radial-gradient(ellipse at center, #000000 0%, #00000000 100%)",
            }}
          ></div>
        </div>
        <div //hour
          ref={handHour}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            transformOrigin: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              borderRadius: 4 + "px",
              width: 3 + "%",
              height: 25 + "%",
              bottom: 46 + "%",
              left: 48 + "%",
              backgroundColor: props.handsColor,
            }}
          ></div>
        </div>
        <div //minute shadow
          ref={handMinS}
          style={{
            position: "absolute",
            width: 100 + "%",
            height: 100 + "%",
            left: 1.2 + "%",
            top: 1.4 + "%",
            transformOrigin: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              borderRadius: 4 + "px",
              width: 2.5 + "%",
              height: 40 + "%",
              bottom: 45 + "%",
              left: 48.75 + "%",
              background:
                "radial-gradient(ellipse at center, #000000 0%, #00000000 100%)",
            }}
          ></div>
        </div>
        <div //minute
          ref={handMin}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            transformOrigin: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              borderRadius: 4 + "px",
              width: 2.5 + "%",
              height: 40 + "%",
              bottom: 45 + "%",
              left: 48.75 + "%",
              backgroundColor: props.handsColor,
            }}
          ></div>
        </div>
      </time>
      <img
        loading="lazy"
        src={clockImage}
        alt="Klydo Clock"
        style={{
          top: 0,
          width: 100 + "%",
          height: "auto",
          position: "absolute",
        }}
      />
    </div>
  );
};

ClockView.defaultProps = defaultClockViewProps;
export default ClockView;
