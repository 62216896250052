import React from "react";
import { useNavigate } from "react-router-dom";
import DSGrid from "themes-views/system-design/DSGrid";
import { Pack } from "context/klydo/KlydoTypes";
import { resize } from "utils";
import PackViewContainer from "ClockView/PackViewContainer";
import DSStatusChip from "themes-views/system-design/DSStatusChip";

interface PackListItemProps {
  pack: Pack;
  style?: React.CSSProperties;
  isNavigate?: boolean; // Just declare the type here
}

const PackListItem: React.FC<PackListItemProps> = ({
  pack,
  style,
  isNavigate = true, // Set the default value here
}): JSX.Element => {
  const scale = 0.4;
  const w = scale * 390;
  const h = scale * 643.5;
  const navigate = useNavigate();

  function nv() {
    if (isNavigate) {
      // It will use the default true if nothing is passed
      navigate("/pack/" + pack.id);
    }
  }

  const [status, setStatus] = React.useState<string>("");
  React.useEffect(() => {
    if (pack.result) {
      setStatus(pack.result.approved ? "approved" : "rejected");
    } else if (pack.review) {
      setStatus(pack.review.type === "delete" ? "deleted" : "in review");
    } else {
      setStatus("draft");
    }
  }, [pack.result, pack.review]);

  return (
    <DSGrid
      onClick={() => nv()}
      xs={6}
      md={8}
      style={{
        display: "flex",
        flexDirection: "column",
        margin: 1 + "rem",
        padding: 0,
      }}
    >
      <div
        style={{
          position: "relative",
          width: w + "px",
          height: h + "px",
          padding: 0,
        }}
      >
        <PackViewContainer
          packName={pack.name}
          clockBackground={resize(pack.imageUrl, 200)}
          pendulumBackground={resize(pack.pendulumImageUrl, 200)}
        />
      </div>
      <div
        style={{
          display: "flex",
          fontSize: "1.2em",
          textAlign: "center",
          fontWeight: "bold",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {status !== "" && (
          <DSStatusChip
            label={status}
            style={{
              width: "100px",
              height: "30px",
              marginLeft: "24px",
              marginTop: "20px",
            }}
          />
        )}
      </div>
    </DSGrid>
  );
};

export default PackListItem;
