import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material";
import { KLYDO_BLACK, KLYDO_YELLOW } from "./consts/colors";

const container = document.getElementById("root");
const root = createRoot(container);
const darkTheme = createTheme({
  // typography: {
  //   fontFamily: ["Gilroy", "Gilroy-Bold"].join(","),
  // },
  palette: {
    mode: "dark",
    primary: {
      main: KLYDO_YELLOW,
      contrastText: KLYDO_BLACK,
    },
    secondary: {
      main: "#212121",
      contrastText: "#626262",
    },
    background: {
      // black

      default: KLYDO_BLACK,
      paper: KLYDO_BLACK,
    },
  },
});

root.render(
  <ThemeProvider theme={darkTheme}>
    <App />
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
