import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";
import { UserAvatar } from "./userStyles";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Role } from "AppContext";
import { Profile } from "context/klydo/KlydoTypes";
import anonimusPhoto from "../../img/anonimus.png";

export type UserProfile = {
  fullName: string;
  picture: string;
  username?: string;
  email?: string;
};

interface UserDropdownProps {
  user?: UserProfile;
}

const trigger = (user: firebase.User & Role, profile: any) => (
  <span>
    <UserAvatar
      style={{ height: 40 }}
      src={
        profile?.photoUrl ? profile.photoUrl : user.photoURL ?? anonimusPhoto
      }
      size="tiny"
      circular
    />
  </span>
);

const handleLogOut = (
  _event: React.MouseEvent,
  _data: DropdownItemProps,
): void => {
  firebase.auth().signOut();
};

const options = (user: firebase.User): Array<DropdownItemProps> => [
  {
    key: "user",
    text: (
      <span>
        Signed in as <strong>{user.displayName || user.email}</strong>
      </span>
    ),
    disabled: true,
  },
  {
    key: "profile",
    icon: "user circle",
    text: "Your Profile",
    as: Link,
    to: "/my-profile",
  },
  {
    key: "sign-out",
    icon: "sign out",
    onClick: handleLogOut,
    text: "Sign Out",
    as: Link,
    to: "/",
  },
];

const UserDropdown = (props: {
  user: firebase.User & Role;
  profile: Profile;
}) => {
  return (
    <Dropdown
      trigger={trigger(props.user, props.profile)}
      options={options(props.user)}
      pointing="top right"
      icon={null}
    />
  );
};
export default UserDropdown;
