import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { Role } from "AppContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUploadResDetails, resize } from "utils";
import defImg from "../../ClockView/cat-peach.gif";
import {
  KlydoTimes,
  Profile,
  ReviewHistory,
  Theme,
 Klydo } from "context/klydo/KlydoTypes";
import useUsersList from "hooks/useUsersList";
import {
  Button,
  Checkbox,
  Dropdown,
  DropdownItemProps,
  Icon,
  Input,
  Loader,
  Menu,
  Modal,
  Table,
} from "semantic-ui-react";
import firebaseService from "firebase_services/firebaseService";
import uploadFile from "utils/uploadFile";
import {
  FaBackwardStep,
  FaCheck,
  FaLightbulb,
  FaTrash,
  FaXmark,
} from "react-icons/fa6";
import { LiaSaveSolid } from "react-icons/lia";
import { FaEdit } from "react-icons/fa";
import TimeControl from "SettingsView/TimeControl";
import { IoCheckmarkSharp, IoClose } from "react-icons/io5";
import UserDropdown from "themes-views/user/UserMenu";
import { IoIosSend } from "react-icons/io";
import { ThreeDots } from "react-loader-spinner";
import ClockView from "ClockView/ClockView";
import Editable from "SettingsView/Editable";
import KlydoColorPicker from "SettingsView/ColorPicker";

const colorSets = [
  ["#32347a", "#7a7732", "#32587a", "#53327a"],
  ["#507a32", "#5c327a", "#747a32", "#327a38"],
  ["#b0459c", "#45b059", "#8e45b0", "#b04567"],
];

const ThemeContainer = (props: {
  user: firebase.User & Role;
  profile: Profile;
  klydos: Array<Klydo>;
}) => {
  const navigate = useNavigate();
  const randomColorSet =
    colorSets[Math.floor(Math.random() * 100) % colorSets.length];
  const location = useLocation();
  const path = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1,
  );
  let date = new Date();
  const dateTimeFormat = Intl.DateTimeFormat("en-US", {
    hourCycle: "h23",
    hour: "2-digit",
    minute: "2-digit",
  });

  const scale = 1;
  const w = scale * 390;
  const h = scale * 643.5;

  const [klydo, setKlydo] = useState<Klydo>(getKlydo());

  //the fields in klydo
  const [url, setUrl] = useState<string>(
    klydo?.loopUrl ? klydo.loopUrl : defImg,
  );
  const [loopUrl, setLoopUrl] = useState<string>(
    klydo?.loopUrl ? klydo.loopUrl : defImg,
  );
  const [handsColor, setHandsColor] = useState<string>(
    klydo?.theme?.handsColor || randomColorSet[0],
  );

  const [backgroundColor, setBackgroundColor] = useState<string>(
    klydo?.theme?.backgroundColor || randomColorSet[1],
  );
  const [dialsColor, setDialsColor] = useState<string>(
    klydo?.theme?.dialsColor || randomColorSet[0],
  );
  const [pendulumColor, setPendulumColor] = useState<string>(
    klydo?.theme?.pendulumColor || randomColorSet[2],
  );
  const [pendulumRodColor, setPendulumRodColor] = useState<string>(
    klydo?.theme?.pendulumRodColor ||
      klydo?.theme?.pendulumColor ||
      randomColorSet[3],
  );
  const [times, setTimes] = useState<Array<KlydoTimes>>(klydo?.times || []);
  const [title, setTitle] = useState<string>(klydo?.name);
  const [hourShift, setHourShift] = useState(0);
  const [minShift, setMinShift] = useState(0);
  const [id, setId] = useState(
    path === "new" ? crypto.randomUUID() : klydo ? klydo.id : undefined,
  );
  const [crop, setCrop] = useState(
    klydo?.crop || { zoom: 100, left: 0, top: 0 },
  );
  const [edited, setEdited] = useState<boolean>(false);
  const [inReview, setInReview] = useState<boolean>(
    klydo?.review ? true : false,
  );
  const [showDials, setShowDials] = useState<boolean>(klydo?.dials ?? true);

  const [saving, setSaving] = useState(false);
  const [uploadingGif, setUploadingGif] = useState(false);
  const [submitKlydo, setSubmitKlydo] = useState(false);
  const [savedKlydo, setSavedKlydo] = useState(true);
  const [savingKlydo, setSavingKlydo] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [warning, setWarning] = useState("");
  const [modalDelete, setModalDelete] = useState(false);
  const [modalSubmit, setModalSubmit] = useState(false);
  const [uploadButton, setUploadButton] = useState(false);
  const [loaderDelete, setLoaderDelete] = useState(false);
  const [loaderSubmit, setLoaderSubmit] = useState(false);

  const [aiTitle, setAiTitle] = useState<string>();
  const [aiColors, setAiColors] = useState<Theme>();

  const usersList = useUsersList();
  const [usersDropdown, setUserDropDown] = useState<Array<DropdownItemProps>>();
  const [userKlydo, setUserKlydo] = useState<{ uid: string; name: string }>({
    uid: props.user.uid,
    name: props.user.displayName,
  });
  const [memoryUsage, setMemoryUsage] = useState(0);
  const [loadUpdateUser, setLoadUpdateUser] = useState(false);
  const [lastUserId, setLastUserId] = useState(props.user.uid);
  const [history, setHistory] = useState<Array<ReviewHistory>>();

  useEffect(() => {
    let k = props.klydos.find((k) => k.id == id);
    if (!k && path != "new" && props.user.role == "admin")
      k = firebaseService.allKlydos.find((i) => i.id === path);

    if (k) {
      setKlydo(k);
      setUrl(k.loopUrl ? resize(k.loopUrl, 300) : defImg);
      setTitle(k.name);
      setCrop(k.crop || { zoom: 100, left: 0, top: 0 });

      // Set theme colors with fallback to random colors if undefined
      setHandsColor(k.theme?.handsColor);
      setBackgroundColor(k.theme?.backgroundColor);
      setDialsColor(k.theme?.dialsColor);
      setPendulumColor(k.theme?.pendulumColor);
      setPendulumRodColor(k.theme?.pendulumRodColor || k.theme?.pendulumColor);
    }
  }, [props.klydos, id, props.user.role, path, randomColorSet]);

  useEffect(() => {
    if (klydo)
      firebaseService.loadHistory(klydo, userKlydo?.uid).then((data) => {
        setHistory(data.sort((a, b) => (a.started < b.started ? 1 : -1)));
      });
  }, [klydo, userKlydo]);

  useEffect(() => {
    if (usersList?.length) {
      const parsedUserDropDown = usersList
        .filter(
          (u) =>
            u.customClaims &&
            (u.customClaims.role === "editor" ||
              u.customClaims.role === "admin"),
        )
        .map((u) => ({
          key: u.id,
          text: u.displayName ?? u.email,
          value: u.id,
          onClick: setReviewToUser,
        }))
        .sort((a, b) => a.text?.localeCompare(b.text));
      setUserDropDown(parsedUserDropDown);

      if (klydo) {
        const uk = parsedUserDropDown.find((u) => klydo.userId === u.key);
        if (uk) setUserKlydo({ uid: uk.key, name: uk.text.toString() });
      } else {
        setUserKlydo({ uid: props.user.uid, name: props.user.displayName });
      }
    }
  }, [klydo, usersList]);

  useEffect(() => {
    if (userKlydo.uid !== lastUserId) {
      setLoadUpdateUser(true);
      firebaseService
        .moveKlydoToUser(lastUserId, userKlydo.uid, id)
        .then(() => setLoadUpdateUser(false));
      setLastUserId(userKlydo.uid);
    }
  }, [userKlydo]);

  function getKlydo() {
    let k = firebaseService.klydosList.find((i) => i.id === path);
    if (!k && props.user.role == "admin")
      k = firebaseService.allKlydos.find((i) => i.id === path);
    return k;
  }

  const setReviewToUser = (
    e: React.MouseEvent<HTMLDivElement>,
    f: DropdownItemProps,
  ) => {
    setUserKlydo({ uid: f.value as string, name: f.text as string });
  };

  const edtiableHook: { hook?: (v: string) => void } = {};

  const changeKlydoByField = (
    v: Partial<Klydo> | { theme: Partial<Theme> },
  ) => {
    setSaving(true);
    firebaseService.updateKlydo(
      id,
      userKlydo ? userKlydo.uid : "",
      {
        review: undefined,
        result: undefined,
        edited: klydo?.public && true,
        ...v,
      },
      () => {
        setSaving(false);
        setInReview((v as Klydo).review ? true : false);
        setEdited(klydo?.public && true);
      },
    );
  };

  const handleFileSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const file = event.target.files?.[0];
    if (!file) return;

    const validTypes = ["video/mp4", "image/gif"];
    if (!validTypes.includes(file.type)) {
      alert("Invalid file type. Only MP4 and GIF files are allowed.");
      return;
    }
    readAndPreviewFile(file);
  };

  const readAndPreviewFile = (file: File): void => {
    const reader = new FileReader();
    reader.onload = () => {
      setUrl(reader.result as string);
      setEdited(true);
      setSavedKlydo(false);
      handleFileUpload(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const handleFileUpload = async (fileUrl: string) => {
    setUploadingGif(true);
    uploadFile({
      file: fileUrl,
      folder: "gifs",
      upload_preset: "Bezalel_preset",
      onUploadProgress: (progress) => {
        setUploadProgress(progress);
      },
    })
      .then((response) => {
        setMemoryUsage(getUploadResDetails(response));
        setLoopUrl(response.secure_url);
      })
      .catch((error) => {
        console.error("Upload error:", error);
        setWarning("Upload failed!");
      })
      .finally(() => {
        setUploadingGif(false);
      });
  };
  const renderUploadStatus = (): JSX.Element => {
    if (
      uploadingGif ||
      (props.user.role === "admin" && submitKlydo && !klydo?.public)
    ) {
      const message = uploadingGif
        ? `uploading image ${uploadProgress}%`
        : "processing image...";
      return <p style={{ margin: 0 }}>{message}</p>;
    } else {
      return <></>;
    }
  };

  const getReviewWarning = () => {
    if (title && savedKlydo && url !== defImg && loopUrl !== defImg)
      return "request to publish";
    let str = "missing:\n ";
    if (!title || title.length === 0) str += "title\n";
    if (url === defImg) str += "gif\n";
    return str;
  };

  const submitButtonStyle = () => {
    const style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#4CAF50",
      color: "white",
      padding: "10px 10px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      opacity: 1,
    };

    if (
      !title ||
      uploadingGif ||
      loopUrl === defImg ||
      url === defImg ||
      (!savedKlydo && edited)
    ) {
      style.opacity = 0.5;
      style.cursor = "not-allowed";
    }

    return style;
  };

  const saveButtonStyle = () => {
    const style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "darkgreen",
      color: "white",
      padding: "10px 10px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      opacity: 1,
    };

    if (savingKlydo || uploadingGif || (savedKlydo && !edited)) {
      style.opacity = 0.5;
      style.cursor = "not-allowed";
    }

    return style;
  };

  const handleSubmitKlydo = async (): Promise<void> => {
    if (!title || loopUrl === defImg || url === defImg) {
      console.log("All fields are required.");
      setWarning("All fields are required to submit.");
      return;
    }

    setSubmitKlydo(true);
    setLoaderSubmit(true);

    try {
      await firebaseService.updateSplitedGif(
        userKlydo ? userKlydo.uid : "",
        id,
        crop,
      );
    } catch (error) {
      console.error("Submit failed:", error);
      setWarning("Submit failed!");
    } finally {
      setSubmitKlydo(false);
      setLoaderSubmit(false);
      changeKlydoByField({
        review: {
          date: firebase.firestore.FieldValue.serverTimestamp(),
          type: "edit",
        },
        edited: true,
      });
      setModalSubmit(false);
      navigate("/");
    }
  };

  const handleSaveKlydo = async (): Promise<void> => {
    setSavingKlydo(true);
    try {
      const obj: Partial<Klydo> = {
        name: title || "",
        loopUrl: loopUrl,
        theme: {
          handsColor: handsColor,
          pendulumColor: pendulumColor,
          pendulumRodColor: pendulumRodColor,
          backgroundColor: backgroundColor,
          dialsColor: dialsColor,
        },
        crop: {
          zoom: crop.zoom,
          left: crop.left,
          top: crop.top,
        },
        dials: showDials,
        edited: true,
        times: times,
        ...(path === "new" && {
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        }),
      };

      await firebaseService.saveKlydo(id, obj, userKlydo.uid);
    } catch (error) {
      console.error("Save failed:", error);
      setWarning("Save failed!");
    } finally {
      setSavedKlydo(true);
      setEdited(false);
      setSavedKlydo(true);
      setSavingKlydo(false);
      navigate("/klydo/" + id);
    }
  };

  const handleRevertChanges = (): void => {
    if (!klydo || !userKlydo?.uid) return;

    firebaseService
      .revert(klydo, userKlydo.uid)
      .then((revertedKlydo: Klydo) => {
        setPendulumColor(revertedKlydo.theme.pendulumColor);
        setPendulumRodColor(revertedKlydo.theme.pendulumRodColor);
        setPendulumColor(revertedKlydo.theme.pendulumColor);
        setPendulumRodColor(revertedKlydo.theme.pendulumRodColor);
        setHandsColor(revertedKlydo.theme.handsColor);
        setBackgroundColor(revertedKlydo.theme.backgroundColor);
        setBackgroundColor(revertedKlydo.theme.backgroundColor);
        edtiableHook?.hook(revertedKlydo.name);
        setTitle(revertedKlydo.name);
        setEdited(false);
        setSavedKlydo(true);
        setUrl(revertedKlydo.loopUrl);
        setTimes(revertedKlydo.times ?? []);
      })
      .catch((error) => {
        console.error("Failed to revert changes:", error);
      });
  };

  const handleDeleteKlydo = async (): Promise<void> => {
    setLoaderDelete(true);

    try {
      if (klydo?.public) {
        setSaving(true);
        firebaseService.updateKlydo(
          id,
          userKlydo?.uid,
          {
            result: undefined,
            review: {
              date: firebase.firestore.FieldValue.serverTimestamp(),
              type: "delete",
            },
          },
          () => {
            setSaving(false);
            setInReview(true);
            setLoaderDelete(false);
            setModalDelete(false);
          },
        );
      } else {
        await firebaseService.deleteKlydo(
          id,
          props.user.role === "admin" ? userKlydo?.uid : undefined,
        );
      }
    } catch (error) {
      console.error("Failed to delete Klydo:", error);
      setWarning("An error occurred while deleting the Klydo.");
    } finally {
      setLoaderDelete(false);
      setModalDelete(false);
      navigate("/");
    }
  };

  return (
    <div>
      <div>
        <Menu fixed={"top"} pointing inverted>
          <Menu.Item header>
            <Link to={"/"}>
              <Icon className="left arrow" />
            </Link>
          </Menu.Item>
          {props.user.role == "admin" && usersDropdown && (
            <Menu.Item>
              <Dropdown
                search
                disabled={
                  saving || uploadingGif || (submitKlydo && !klydo?.zip)
                }
                placeholder={userKlydo.name}
                options={usersDropdown}
                selection
                loading={loadUpdateUser}
              />
            </Menu.Item>
          )}
          <Menu.Item position={"right"}>
            <ThreeDots
              height="40"
              width="80"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              visible={saving}
            />
            {renderUploadStatus()}

            {path !== "new" && savedKlydo && (
              <button
                title={getReviewWarning()}
                style={submitButtonStyle()}
                onClick={() => setModalSubmit(true)}
                disabled={
                  !title || uploadingGif || loopUrl === defImg || url === defImg
                }
              >
                <IoIosSend style={{ marginRight: "8xp" }} />
                <span>Submit Review</span>
              </button>
            )}

            {edited && (
              <button style={saveButtonStyle()} onClick={handleSaveKlydo}>
                <LiaSaveSolid style={{ marginRight: "8xp" }} />
                <span>Save</span>
              </button>
            )}

            {klydo?.public && klydo?.edited && (
              <FaBackwardStep
                title="revert changes"
                style={{ marginLeft: "8px", width: 30, height: 30 }}
                onClick={handleRevertChanges}
              />
            )}
            {(!klydo?.public || klydo?.review?.type !== "delete") && (
              <FaTrash
                title={klydo?.public ? "request to remove" : "delete"}
                style={{ marginLeft: "8px", width: 30, height: 30 }}
                onClick={() => setModalDelete(true)}
              />
            )}
          </Menu.Item>
          <Menu.Item positon={"right"}>
            <UserDropdown user={props.user} profile={props.profile} />
          </Menu.Item>
        </Menu>
        <div
          className="ThemeContainer"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 2fr",
            gridGap: "1em",
            justifyItems: "center",
            paddingLeft: "2%",
            paddingRight: "2%",
            paddingTop: "85px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "sticky",
              top: "80px",
            }}
          >
            <p style={{ textAlign: "center", margin: 0 }}>
              drag & drop to change gif
            </p>
            <div
              style={{
                width: w + "px",
                height: h + "px",
                position: "relative",
              }}
            >
              <ClockView
                showDials={showDials}
                loopUrl={url}
                themeID={id}
                timeSet={hourShift * 60 + minShift}
                handsColor={handsColor}
                pendulumColor={pendulumColor}
                pendulumRodColor={pendulumRodColor}
                backgroundColor={backgroundColor}
                dialsColor={dialsColor}
                activeCrop
                onCrop={(z: number, l: number, t: number) => {
                  if (
                    (klydo?.crop &&
                      z === klydo.crop.zoom &&
                      l === klydo.crop.left &&
                      t === klydo.crop.top) ||
                    (z === 100 && l === 0 && t === 0)
                  )
                    setUploadButton(false);
                  else setUploadButton(true);
                  setCrop({ zoom: z, left: l, top: t });
                }}
                crop={crop}
              />
              <Input
                type="file"
                onChange={handleFileSelection}
                onClick={(e) => {
                  e.target.value = null;
                }}
                accept=".mp4, .gif"
                style={{
                  top: 7 + "%",
                  left: 11.5 + "%",
                  width: 77 + "%",
                  height: 47 + "%",
                  opacity: 0,
                }}
              />
            </div>
            {uploadButton && (
              <Button
                color="green"
                disabled={uploadingGif}
                onClick={() => {
                  setTimeout(() => {
                    changeKlydoByField({ crop: crop });
                    setUploadButton(false);
                  }, 0);
                }}
              >
                Upload
              </Button>
            )}
            <input
              defaultValue={0}
              type="range"
              id="hour"
              min="0"
              max="12"
              onChange={(v) => {
                setHourShift(parseInt(v.target.value));
                setEdited(true);
                setSavedKlydo(false);
              }}
            />
            <input
              defaultValue={0}
              type="range"
              id="minute"
              min="0"
              max="60"
              onChange={(v) => {
                setMinShift(parseInt(v.target.value));
                setEdited(true);
                setSavedKlydo(false);
              }}
            />
          </div>
          {klydo !== null && (
            <div
              className={"ThemeSettings"}
              style={{
                width: "80%",
                margin: "1%",
              }}
            >
              <Editable
                type={"short"}
                default={title}
                title="Title"
                onChange={(v) => {
                  if (klydo) klydo.name = v;
                  setTitle(v);
                  setEdited(true);
                  setSavedKlydo(false);
                }}
                obj={edtiableHook}
              />
              {memoryUsage > 0 && (
                <p>
                  Memory use for gif:{" "}
                  {memoryUsage > 99
                    ? memoryUsage.toFixed(0)
                    : memoryUsage.toPrecision(2)}
                  MB
                </p>
              )}
              {aiTitle && (
                <div
                  style={{
                    margin: 0,
                    position: "relative",
                    display: "inline-block",
                    fontSize: "1.6em",
                    paddingTop: 10,
                  }}
                >
                  <FaLightbulb
                    style={{ width: 15, height: 15, marginRight: 10 }}
                  />
                  {aiTitle}
                  <IoCheckmarkSharp
                    onClick={() => {
                      setTitle(aiTitle);
                      edtiableHook?.hook(aiTitle);
                      setAiTitle(undefined);
                    }}
                    style={{ width: 15, height: 15, marginLeft: 10 }}
                  />
                  <IoClose
                    style={{ width: 15, height: 15 }}
                    onClick={() => setAiTitle(undefined)}
                  />
                </div>
              )}
              <div
                style={{
                  margin: 16 + "px",
                  marginTop: 64 + "px",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                  justifyItems: "center",
                }}
              >
                <KlydoColorPicker
                  settingTitle={"Hands Color"}
                  color={handsColor}
                  onColorChange={(color) => {
                    if (klydo && klydo.theme) klydo.theme.handsColor = color;
                    setHandsColor(color);
                    setEdited(true);
                    setSavedKlydo(false);
                  }}
                />

                <KlydoColorPicker
                  settingTitle={"Pendulum Color"}
                  color={pendulumColor}
                  onColorChange={(color) => {
                    if (klydo && klydo.theme) klydo.theme.pendulumColor = color;
                    setPendulumColor(color);
                    setEdited(true);
                    setSavedKlydo(false);
                  }}
                />
                <KlydoColorPicker
                  settingTitle={"Pendulum Rod Color"}
                  color={pendulumRodColor}
                  onColorChange={(color) => {
                    if (klydo && klydo.theme)
                      klydo.theme.pendulumRodColor = color;
                    setPendulumRodColor(color);
                    setEdited(true);
                    setSavedKlydo(false);
                  }}
                />
                <KlydoColorPicker
                  settingTitle={"Background Color"}
                  color={backgroundColor}
                  onColorChange={(color) => {
                    if (klydo && klydo.theme)
                      klydo.theme.backgroundColor = color;
                    setBackgroundColor(color);
                    setEdited(true);
                    setSavedKlydo(false);
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <KlydoColorPicker
                    disabled={!showDials}
                    settingTitle={"Dials Color"}
                    color={dialsColor}
                    onColorChange={(color) => {
                      if (klydo && klydo.theme) klydo.theme.dialsColor = color;
                      setDialsColor(color);
                      setEdited(true);
                      setSavedKlydo(false);
                    }}
                  />
                  <Checkbox
                    toggle
                    checked={showDials}
                    onChange={(event, { checked }) => {
                      setShowDials(checked);
                      setEdited(true);
                      setSavedKlydo(false);
                    }}
                  />
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "2px",
                    }}
                  >
                    {`Dials ${showDials ? "on" : "off"}`}
                  </p>
                </div>
              </div>
              {aiColors && ( //// Not sure how to implemet dialsColor in aiColors
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FaLightbulb
                    style={{ width: 20, height: 20, marginRight: 10 }}
                  />
                  <div
                    style={{
                      marginRight: "4px",
                      width: 25 + "px",
                      height: 25 + "px",
                      borderRadius: 50 + "%",
                      border: "3px solid black",
                      backgroundColor: aiColors.handsColor,
                    }}
                  ></div>
                  <div
                    style={{
                      marginRight: "4px",
                      width: 25 + "px",
                      height: 25 + "px",
                      borderRadius: 50 + "%",
                      border: "3px solid black",
                      backgroundColor: aiColors.pendulumColor,
                    }}
                  ></div>
                  <div
                    style={{
                      marginRight: "4px",
                      width: 25 + "px",
                      height: 25 + "px",
                      borderRadius: 50 + "%",
                      border: "3px solid black",
                      backgroundColor: aiColors.pendulumRodColor,
                    }}
                  ></div>
                  <div
                    style={{
                      width: 25 + "px",
                      height: 25 + "px",
                      borderRadius: 50 + "%",
                      border: "3px solid black",
                      backgroundColor: aiColors.backgroundColor,
                    }}
                  ></div>
                  <IoCheckmarkSharp
                    onClick={() => {
                      setPendulumColor(aiColors.pendulumColor);
                      setPendulumRodColor(aiColors.pendulumRodColor);
                      setBackgroundColor(aiColors.backgroundColor);
                      setPendulumColor(aiColors.pendulumColor);
                      setPendulumRodColor(aiColors.pendulumRodColor);
                      setBackgroundColor(aiColors.backgroundColor);
                      setHandsColor(aiColors.handsColor);
                      setAiColors(undefined);
                    }}
                    style={{ width: 15, height: 15, marginLeft: 10 }}
                  />
                  <IoClose
                    style={{ width: 15, height: 15 }}
                    onClick={() => setAiColors(undefined)}
                  />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyItems: "left",
                }}
              >
                {/* <HashtagChoose />*/}
                <TimeControl
                  times={times}
                  onSave={(t: KlydoTimes[]) => {
                    setTimes([...t]);
                  }}
                />
              </div>
              {history === undefined ? (
                <Loader />
              ) : history.length === 0 ? (
                <></>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h3>Review History</h3>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={1}>
                          Request time
                        </Table.HeaderCell>
                        <Table.HeaderCell width={1}>
                          Request type
                        </Table.HeaderCell>
                        <Table.HeaderCell width={1}>
                          Answer time
                        </Table.HeaderCell>
                        <Table.HeaderCell width={1}>
                          Approved/Rejected
                        </Table.HeaderCell>
                        <Table.HeaderCell width={4}>Message</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {history.map((h, i) => (
                        <Table.Row>
                          <Table.Cell>
                            {h.started.toLocaleString("en-GB")}
                          </Table.Cell>
                          <Table.Cell>
                            {h.type === "delete" ? <FaTrash /> : <FaEdit />}
                          </Table.Cell>
                          <Table.Cell>
                            {h.ended.toLocaleString("en-GB")}
                          </Table.Cell>
                          <Table.Cell>
                            {h.approved ? <FaCheck /> : <FaXmark />}
                          </Table.Cell>
                          <Table.Cell>{h.msg}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Modal
        style={{ marginTop: "-20%" }}
        centered={false}
        size={"mini"}
        open={modalDelete}
        onClose={() => setModalDelete(false)}
      >
        <Modal.Header>{`Delete Klydo`}</Modal.Header>
        <Modal.Content>
          <p>
            {klydo?.public
              ? "Request to unpublish this klydo?"
              : "Are you sure you want to delete this klydo?"}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setModalDelete(false)}>
            Cancel
          </Button>
          <Button loading={loaderDelete} positive onClick={handleDeleteKlydo}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        style={{ marginTop: "-20%" }}
        centered={false}
        size={"mini"}
        open={modalSubmit}
        onClose={() => setModalSubmit(false)}
      >
        <Modal.Header>{`Submit Klydo`}</Modal.Header>
        <Modal.Content>
          <p>
            {klydo?.public
              ? "Request to submit this klydo?"
              : "Are you sure you want to submit this klydo?"}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setModalSubmit(false)}>
            Cancel
          </Button>
          <Button loading={loaderSubmit} positive onClick={handleSubmitKlydo}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        open={warning !== ""}
        style={{ color: "red" }}
        size="small"
        onClose={() => setWarning("")}
      >
        <Modal.Header>Warning!</Modal.Header>
        <Modal.Content>{warning}</Modal.Content>
      </Modal>
    </div>
  );
};

export default ThemeContainer;
