import React, { useRef } from "react";
import { Button } from "semantic-ui-react";

export type UploadFileButtonProps = {
  onChange: (file: any) => void;
  accept?: string;
};

const UploadFileButton = ({ onChange, accept }: UploadFileButtonProps) => {
  const fileInputRef = useRef<HTMLInputElement | undefined>();
  return (
    <>
      <Button
        content="Choose File"
        labelPosition="left"
        color="facebook"
        icon="file"
        onClick={() => fileInputRef.current?.click()}
      />
      <input
        accept={accept}
        ref={fileInputRef}
        type="file"
        hidden
        onChange={(e) => {
          onChange(e);
        }}
      />
    </>
  );
};

export default UploadFileButton;
