import { createContext } from "react";
import firebase from "firebase/compat/app";

export type Role = { role: "admin" | "editor" | "clock" };
export type AppContextProps = {
  user: firebase.User & Role;
};

const AppContext = createContext<AppContextProps>({} as AppContextProps);

export default AppContext;
