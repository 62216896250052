import React from "react";
import { ImageListItemBar } from "@mui/material";

const DSImageListItemBar = (
  props: React.ComponentProps<typeof ImageListItemBar>,
) => {
  return <ImageListItemBar {...props} />;
};

export default DSImageListItemBar;
