import React from "react";
import GiftForm from "../GiftForm";
import GiftOccasion from "../GiftOccasion";
import GiftKlydoGreet from "../GiftKlydoGreet";
import GiftViewContainer from "../GiftViewContainer";
export type Step = {
  id: string;
  title: string;
  description: string;
  component: React.JSX.Element;
};

const steps: Step[] = [
  {
    id: "occasion",
    title: "What's the occasion?",
    description: " Choose the event that’s about to get even more memorable.",
    component: <GiftOccasion />,
  },
  {
    id: "greet",
    title: "Choose a KlydoGreet",
    description:
      "Pick the perfect animated greeting to surprise your gift recipient the moment they open their Klydoclock.",
    component: <GiftKlydoGreet />,
  },
  {
    id: "personal-greet",
    title: "Your personal greeting",
    description:
      "Add a personal message to make your KlydoGreet even more special.",
    component: <GiftForm />,
  },
  {
    id: "preview",
    title: "Preview",
    description: "See your personalized KlydoGreet in action!",
    component: <GiftViewContainer />,
  },
];

export default steps;
