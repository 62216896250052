import React from "react";
import { Checkbox } from "@mui/material";
import { Klydo } from "context/klydo/KlydoTypes";
import ThemesListItem from "themes-views/ThemesListItem";
import InView from "./InView";

interface KlydoCardProps {
  klydo: Klydo;
  onLoad: () => void;
  isSelected: boolean;
  onToggleSelection: (id: string) => void;
}

const KlydoCard: React.FC<KlydoCardProps> = ({
  klydo,
  onLoad,
  isSelected,
  onToggleSelection,
}) => {
  return (
    <InView
      onEnterView={onLoad}
      style={{ cursor: "pointer", padding: "4px", borderRadius: "4px" }}
    >
      <Checkbox
        checked={isSelected}
        onChange={() => onToggleSelection(klydo.id)}
        style={{ marginRight: "8px", flexShrink: 0, padding: "1px" }}
      />
      <ThemesListItem klydo={klydo} isNavigate={false} />
    </InView>
  );
};

export default KlydoCard;
