import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Klydo } from "../../../context/klydo/KlydoTypes";
import { GiftCategory, KlydoGiftProps } from "../../../api/gift";
import useGiftCategories from "../hooks/useGiftCategories";
import axios from "axios";
import { parseCloudinaryUrl } from "../../../utils";
import { SplashScreen } from "../../../LoginView/SplashScreen";

export type GiftContextType = {
  selectedOccasion?: GiftCategory;
  selectedKlydoGreet?: Klydo;
  klydoGiftProps: KlydoGiftProps;
  setSelectedOccasion: React.Dispatch<React.SetStateAction<GiftCategory>>;
  setSelectedKlydoGreet: React.Dispatch<React.SetStateAction<Klydo>>;
  setKlydoGiftProps: React.Dispatch<React.SetStateAction<KlydoGiftProps>>;
  giftCategories: GiftCategory[];
};
const GiftContext = createContext<GiftContextType>({} as GiftContextType);

export const useGiftContext = () => useContext(GiftContext);

export const GiftContextProvider = ({ children }) => {
  const { data: giftCategories, isLoading } = useGiftCategories();
  const [initializing, setInitializing] = useState(true);
  const [selectedOccasion, setSelectedOccasion] = useState<GiftCategory>();
  const [selectedKlydoGreet, setSelectedKlydoGreet] = useState<Klydo>();
  const [klydoGiftProps, setKlydoGiftProps] = useState<KlydoGiftProps>();
  const prefetchController = useRef(new AbortController());
  const fetchAll = () => {
    if (initializing) {
      prefetchController.current = new AbortController();
      Promise.all(
        giftCategories?.map((category) => {
          return Promise.all(
            category.klydos.slice(0, 5).map((klydo) => {
              return axios.get(
                parseCloudinaryUrl({
                  url: klydo.convertedLoopUrl || klydo.loopUrl,
                  isStatic: false,
                  size: 200,
                }),
                {
                  signal: prefetchController.current.signal,
                },
              );
            }),
          );
        }),
      )
        .then(() => {
          setInitializing(false);
        })
        .catch((e) => {
          // got aborted
          if (e.name === "CanceledError") {
            return;
          } else {
            console.error(e);
          }
        });
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    if (selectedOccasion) {
      prefetchController.current.abort();
      Promise.all(
        selectedOccasion?.klydos?.map((klydoGreet) => {
          return axios
            .get(
              parseCloudinaryUrl({
                url: klydoGreet.convertedLoopUrl || klydoGreet.loopUrl,
                isStatic: false,
                size: 200,
              }),
              {
                signal: controller.signal,
              },
            )
            .then(() => fetchAll())
            .catch((e) => {
              // got aborted
              if (e.name === "CanceledError") {
                return;
              } else {
                console.error(e);
              }
            });
        }),
      );
    }
    return () => {
      controller.abort();
    };
  }, [selectedOccasion, giftCategories]);

  useEffect(() => {
    if (klydoGiftProps?.senderImageUrl) {
      axios.get(klydoGiftProps.senderImageUrl);
    }
  }, [klydoGiftProps]);

  useEffect(() => {
    if (!isLoading) {
      fetchAll();
    }
  }, [isLoading, giftCategories]);
  if (isLoading) {
    return SplashScreen();
  }
  return (
    <GiftContext.Provider
      value={{
        selectedOccasion,
        selectedKlydoGreet,
        klydoGiftProps,
        setSelectedOccasion,
        setSelectedKlydoGreet,
        setKlydoGiftProps,
        giftCategories,
      }}
    >
      {children}
    </GiftContext.Provider>
  );
};
