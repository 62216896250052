import React from "react";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";

const getStatusColor = (status, palette) => {
  switch (status) {
    case "draft":
      return palette.grey[600];
    case "in review":
      return palette.warning.main;
    case "approved":
      return palette.success.main;
    case "public":
      return palette.primary.main;
    case "rejected":
      return palette.error.main;
    case "deleted":
      return palette.error.dark;
    default:
      return palette.grey[400];
  }
};

const DSStatusChip = (props: React.ComponentProps<typeof Chip>) => {
  const theme = useTheme();

  return (
    <Chip
      label={props.label}
      style={{
        backgroundColor: getStatusColor(props.label, theme.palette),
        color: "#fff",
        ...props.style,
      }}
    />
  );
};

export default DSStatusChip;
