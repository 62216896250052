import React, { useState } from "react";
import { FaEdit, FaSave } from "react-icons/fa";
import { getContrastYIQ } from "utils";

const EditableBatch = (props: {
  type: "name" | "color";
  default: string;
  onChange: (value: string) => void;
  title: string;
}) => {
  const [active, setActive] = useState(false);
  const [val, setVal] = useState<string>(props.default);
  const [newVal, setNewVal] = useState<string>(props.default);
  const [error, setError] = useState("");

  return (
    <div style={{ display: "flex", flexDirection: "column", fontSize: "1em" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {props.title && (
          <p
            style={{
              margin: 0,
              paddingRight: 15,
              width: props.type === "color" ? 150 : "auto",
            }}
          >
            {props.title}
          </p>
        )}
        {active ? (
          <>
            <input
              autoFocus
              style={{
                outline: 0,
                border: "unset",
                borderBottom: "solid 1px",
                padding: 0,
                width: props.type === "color" ? "60px" : "auto",
              }}
              onChange={(e) => {
                setNewVal(e.target.value);
              }}
              defaultValue={val}
            />
            <FaSave
              size={12}
              style={{ marginLeft: 10, justifySelf: "flex-end" }}
              onClick={(e) => {
                e.stopPropagation();
                if (
                  props.type === "color" &&
                  !newVal.toLowerCase().match(/^#[0-9a-f]{6}$/)
                ) {
                  setError("Must be color format (ex: #ffffff)");
                  return;
                }
                props.onChange(newVal);
                setActive(false);
                setVal(newVal);
                setError("");
              }}
            />
          </>
        ) : (
          <>
            <p
              style={{
                margin: 0,
                marginRight: "10px",
                textAlign: "center",
                background: props.type === "color" ? val : "transparent",
                color: props.type === "color" ? getContrastYIQ(val) : "black",
              }}
            >
              {val}
            </p>
            <FaEdit
              size={12}
              style={{ marginLeft: 10, justifySelf: "flex-end" }}
              onClick={(e) => {
                e.stopPropagation();
                setActive(true);
              }}
            />
          </>
        )}
      </div>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default EditableBatch;
