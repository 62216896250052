import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
  keepPreviousData,
} from "@tanstack/react-query";
import { EditorError } from "./types";

export type UseEditorQueryResult<TData> = UseQueryResult<TData, EditorError>;
export type UseEditorQueryOptions<
  TQueryFnData,
  TData,
  TQueryKey extends QueryKey = QueryKey,
> = UseQueryOptions<TQueryFnData, EditorError, TData, TQueryKey> & {
  keepPreviousData?: boolean;
};

const useEditorQuery = <
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UseEditorQueryOptions<TQueryFnData, TData, TQueryKey>,
): UseEditorQueryResult<TData> => {
  if (options.keepPreviousData) {
    options.placeholderData = keepPreviousData;
  }
  return useQuery<TQueryFnData, EditorError, TData, TQueryKey>(options);
};

export default useEditorQuery;
