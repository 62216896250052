import { useRef, useState } from "react";
import DSBox from "../system-design/DSBox";
import DSTextField from "../system-design/DSTextField";
import DSTypography from "../system-design/DSTypography";
import DSAvatar from "../system-design/DSAvatar";
import uploadFile from "../../utils/uploadFile";
import { useTheme } from "@mui/material";
import { useGiftContext } from "./Context/GiftContextProvider";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isMobile } from "react-device-detect";
import ClearIcon from "@mui/icons-material/Clear";
import { KLYDO_BLACK } from "../../consts/colors";
import DSIconButton from "../system-design/DSIconButton";
import AddIcon from "@mui/icons-material/Add";

const GiftForm = () => {
  const defualtPhoto =
    "https://res.cloudinary.com/klydoclock/image/upload/profile/defaultPhoto_mc2iv6.png";
  const theme = useTheme();
  const { setKlydoGiftProps, klydoGiftProps } = useGiftContext();
  const [greeting, setGreeting] = useState(
    klydoGiftProps?.greetingContent || "",
  );
  const [name, setName] = useState(klydoGiftProps?.senderName || "");
  const [url, setUrl] = useState(
    klydoGiftProps?.senderImageUrl || defualtPhoto,
  );
  const fileInputRef = useRef(null);

  const handleFileUpload = async (fileUrl: string) => {
    uploadFile({
      file: fileUrl,
      folder: "profile",
      upload_preset: "GiftPicture",
    })
      .then((response) => {
        setUrl(response.secure_url);
        setKlydoGiftProps({
          ...klydoGiftProps,
          senderImageUrl: response.secure_url,
        });
      })
      .catch((error) => {
        console.error("Upload error:", error);
      });
  };

  const readAndPreviewFile = (file: File): void => {
    const reader = new FileReader();
    reader.onload = () => {
      handleFileUpload(reader.result as string);
      setUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const handleFileSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const file = event.target.files?.[0];
    if (!file) return;

    readAndPreviewFile(file);
  };

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleGreetChange = (greet: string) => {
    if (greet.length > 163) return alert("Greeting is too long");
    setGreeting(greet);
    setKlydoGiftProps({
      ...klydoGiftProps,
      greetingContent: greet,
    });
  };

  const handleNameChange = (senderName: string) => {
    if (senderName.length > 14) return alert("Name is too long");
    setName(senderName);
    setKlydoGiftProps({
      ...klydoGiftProps,
      senderName: senderName,
      senderImageUrl: url,
    });
  };

  const handleDateChange = (selectedDate: Dayjs) => {
    setKlydoGiftProps({
      ...klydoGiftProps,
      date: selectedDate?.valueOf(),
    });
  };

  const handleRemoveImage = () => {
    setUrl(defualtPhoto);
    setKlydoGiftProps({
      ...klydoGiftProps,
      senderImageUrl: defualtPhoto,
    });
    fileInputRef.current.value = "";
  };

  return (
    <DSBox
      width={"100%"}
      sx={{
        backgroundColor: theme.palette.background.paper,
        paddingTop: { xs: 2, md: 10 },
        paddingBottom: { xs: 10, md: 0 },
      }}
    >
      <DSBox
        gap={isMobile ? 0.5 : 5}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row-reverse" },
          alignItems: "center",
          textAlign: "left",
        }}
      >
        <DSBox
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            position: "relative",
          }}
        >
          <DSTypography
            variant="h6"
            lineHeight={"18.96px"}
            fontSize={"16px"}
            fontWeight={400}
            fontFamily={"Gilroy-SemiBold"}
            color={"#C9C9C9"}
          >
            {url !== defualtPhoto ? "Change photo" : "Add your photo"}
          </DSTypography>
          <DSBox py={0.5} />
          <DSBox
            gap={0}
            position="relative"
            display="inline-block"
            sx={{ textAlign: "right" }}
          >
            {url !== defualtPhoto && (
              <DSIconButton
                onClick={handleRemoveImage}
                style={{
                  backgroundColor: "transparent",
                  borderRadius: "50%",
                  position: "absolute",
                  left: 110,
                  top: -13,
                  color: "white",
                  cursor: "pointer",
                  fontFamily: "Gilroy-Bold",
                  fontSize: 20,
                }}
              >
                <ClearIcon />
              </DSIconButton>
            )}
            <DSAvatar
              onClick={handleAvatarClick}
              sx={{ width: 140, height: 140, cursor: "pointer" }}
            >
              <img
                src={url}
                alt="User Default"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                  border: "3px solid white",
                  backgroundColor: KLYDO_BLACK,
                }}
              />
            </DSAvatar>
            {url !== defualtPhoto ? (
              <></>
            ) : (
              <DSBox
                onClick={handleAvatarClick}
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: "50%",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  transition: "transform 0.3s ease",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <AddIcon sx={{ fontSize: 90, opacity: 0.75 }} />
              </DSBox>
            )}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleFileSelection}
            />
          </DSBox>
        </DSBox>
        <DSBox
          width={isMobile ? "80%" : "30%"}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <DSBox>
            <DSTypography
              variant="h6"
              lineHeight={"18.96px"}
              fontSize={"16px"}
              fontWeight={400}
              color={"#C9C9C9"}
              fontFamily={"Gilroy-SemiBold"}
            >
              *From
            </DSTypography>
            <DSBox py={0.5} />
            <DSTextField
              placeholder="Daddy"
              value={name}
              fullWidth
              onChange={(e) => handleNameChange(e.target.value)}
              required
              slotProps={{
                input: {
                  inputProps: { maxLength: 14 },
                },
              }}
              sx={{
                backgroundColor: "#212121",
                ".MuiInputBase-input": {
                  fontSize: "15px",
                },
              }}
            />
          </DSBox>
          <DSBox py={1} />
          <DSBox>
            <DSTypography
              variant="h6"
              lineHeight={"18.96px"}
              fontSize={"16px"}
              fontWeight={400}
              color={"#C9C9C9"}
              fontFamily={"Gilroy-SemiBold"}
            >
              Write your greeting
            </DSTypography>
            <DSBox py={0.5} />
            <DSTextField
              placeholder="Dear Ron..."
              value={greeting}
              onChange={(e) => handleGreetChange(e.target.value)}
              multiline
              fullWidth
              rows={5}
              maxRows={6}
              slotProps={{
                input: {
                  inputProps: { maxLength: 163 },
                  style: {
                    padding: "20px",
                  },
                },
              }}
              sx={{
                backgroundColor: "#212121",
                ".MuiInputBase-input": {
                  overflowWrap: "break-word",
                  fontSize: "15px",
                },
              }}
            />
            <DSBox py={0.5} />
            <DSTypography
              variant="h6"
              lineHeight={"18.96px"}
              fontSize={"16px"}
              fontWeight={400}
              color={"#C9C9C9"}
              fontFamily={"Gilroy-SemiBold"}
            >
              {greeting.length}/163
            </DSTypography>
          </DSBox>
          <DSBox py={1} />
          <DSBox>
            <DSTypography
              variant="h6"
              lineHeight={"18.96px"}
              fontSize={"16px"}
              fontWeight={400}
              color={"#C9C9C9"}
              fontFamily={"Gilroy-SemiBold"}
            >
              Date of occasion
            </DSTypography>
            <DSBox py={0.5} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={!klydoGiftProps?.date ? "MM/DD/YYYY" : ""}
                value={
                  klydoGiftProps?.date
                    ? dayjs(new Date(klydoGiftProps?.date))
                    : null
                }
                onChange={(newDate) => handleDateChange(newDate)}
                sx={{
                  backgroundColor: "#212121",
                  width: "100%",
                }}
              />
            </LocalizationProvider>
          </DSBox>
        </DSBox>
      </DSBox>
    </DSBox>
  );
};

export default GiftForm;
