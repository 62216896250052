import { Klydo } from "context/klydo/KlydoTypes";

import firebaseService from "firebase_services/firebaseService";
import { friendlyFromHex } from "utils";

class KlydosService {
  private klydos: Klydo[];
  private firebaseService = firebaseService;
  public async getAllKlydos(): Promise<Klydo[]> {
    if (!this.klydos?.length) {
      const data = await this.firebaseService.db.collection("klydos").get();
      this.klydos = data.docs.map(
        (doc) =>
          ({
            ...doc.data(),
            idf: friendlyFromHex(doc.id),
            id: doc.id,
          }) as Klydo & {
            idf: string;
          }
      );
    }
    return this.klydos;
  }
}
const klydosService = new KlydosService();
export default klydosService;
