import { Role } from "AppContext";
import firebase from "firebase/compat/app";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownItemProps,
  Grid,
  GridColumn,
  Icon,
  Menu,
} from "semantic-ui-react";
import PackCreationForm from "./PackCreationForm";
import { Pack, Profile } from "context/klydo/KlydoTypes";
import Packview from "ClockView/Packview";
import uploadFile from "utils/uploadFile";
import { getRandomTag } from "utils";
import packService from "firebase_services/packService";
import UserDropdown from "themes-views/user/UserMenu";
import useUsersList from "hooks/useUsersList";

export type PacksCreationContainerProps = {
  user: firebase.User & Role;
  profile: Profile;
};

const PacksCreationContainer = (props: PacksCreationContainerProps) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const [pendulumUrl, setPendulumUrl] = useState<string | undefined>();
  const [logoUrl, setLogoUrl] = useState<string | undefined>();
  const [title, setTitle] = useState<string>("");
  const [artists, setArtists] = useState<string[]>([]);
  const [description, setDescription] = useState<string>("");
  const [klydos, setKlydos] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [review, setReview] = useState<boolean>(false);
  const [approved, setApproved] = useState<boolean>(false);
  const [rejected, setRejected] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);

  const [creatorId, setCreatorId] = useState<string>();
  const usersList = useUsersList();
  const [usersDropdown, setUserDropDown] = useState<Array<DropdownItemProps>>();
  const location = useLocation();

  const path = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1,
  );
  const id = path !== "new" ? path : undefined;
  const navigate = useNavigate();

  const setReviewToUser = (
    e: React.MouseEvent<HTMLDivElement>,
    f: DropdownItemProps,
  ) => {
    setCreatorId(f.value as string);
  };

  useEffect(() => {
    if (usersList?.length) {
      const parsedUserDropDown = usersList
        .filter(
          (u) =>
            u.customClaims &&
            (u.customClaims.role === "editor" ||
              u.customClaims.role === "admin"),
        )
        .map((u) => ({
          key: u.id,
          text: u.displayName ?? u.email,
          value: u.id,
          onClick: setReviewToUser,
        }))
        .sort((a, b) => a.text?.localeCompare(b.text));
      setUserDropDown(parsedUserDropDown);
    }
  }, [usersList]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      packService.getPackFromDraft(id, props.user).then((pack) => {
        setTitle(pack.name);
        setDescription(pack.description);
        setImageUrl(pack.imageUrl);
        setPendulumUrl(pack.pendulumImageUrl);
        setKlydos(pack.klydos);
        console.log(pack.klydos);
        setLogoUrl(pack.logoUrl);
        setArtists(pack.artists || []);
        setLoading(false);
        if (pack.review) {
          setReview(true);
        }
        if (pack.result?.approved?.valueOf()) {
          setApproved(pack.result.approved);
        }
        if (pack.result?.approved?.valueOf() === false) {
          setRejected(true);
        }
        setCreatorId(pack.creatorId);
        setSaved(pack.saved);
      });
    } else {
      // set empty values
      setTitle("");
      setDescription("");
      setImageUrl("");
      setPendulumUrl("");
      setKlydos([]);
      setArtists([]);
      setLogoUrl("");
      setLoading(false);
      setCreatorId(props.user.uid);
    }
  }, [id, props.user]);
  const scale = 1;
  const w = scale * 390;
  const h = scale * 643.5;

  const onDelete = async (): Promise<void> => {
    try {
      await packService.deletePackFromDraft(id, props.user);
    } catch (error) {
      console.error("Failed to delete Klydo:", error);
    } finally {
      navigate("/");
    }
  };

  const onSave = async () => {
    setSaved(true);
    const [packImage, pendulumImage, logoImage] = await Promise.all([
      uploadFile({
        file: imageUrl,
        folder: "pack_covers",
        upload_preset: "coverpacks",
      }),
      uploadFile({
        file: pendulumUrl,
        folder: "pack_pendulum_covers",
        upload_preset: "packpendulumconvers",
      }),
      uploadFile({
        file: logoUrl,
        folder: "pack_logos",
        upload_preset: "packlogos",
      }),
    ]);
    const packImageUrl = packImage.secure_url;
    const pendulumImageUrl = pendulumImage.secure_url;
    const logoImageUrl = logoImage.secure_url;

    const pack: Omit<Pack, "id"> & { id?: string } = {
      name: title,
      description,
      pub: false,
      tag: getRandomTag(),
      klydos: klydos,
      imageUrl: packImageUrl,
      logoUrl: logoImageUrl,
      artists: artists,
      pendulumImageUrl: pendulumImageUrl,
      edited: true,
      saved: true,
      creatorId: creatorId,
      ...(path === "new" && {
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      }),
    };
    if (id) {
      pack.id = id;
      await packService.upsertDraft(pack);
    } else {
      const newPackId = await packService.upsertDraft(pack);
      navigate(`/pack/${newPackId}`);
    }
  };

  const onSubmit = async () => {
    const obj = {
      review: {
        date: firebase.firestore.FieldValue.serverTimestamp(),
        type: "edit" as "edit",
      },
      edited: false,
    };
    await packService.updatePack(id, obj).then(() => {
      navigate("/");
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <Menu fixed={"top"} pointing inverted>
        <Menu.Item>
          <Link to={"/"}>
            <Icon className="left arrow" />
          </Link>
        </Menu.Item>
        {props.user.role === "admin" && usersDropdown && (
          <Menu.Item header position="left">
            <Dropdown
              search
              placeholder={props.user.displayName}
              options={usersDropdown}
              selection
              defaultValue={creatorId}
              disabled={review || approved}
            />
          </Menu.Item>
        )}
        <Menu.Item positon={"right"}>
          <UserDropdown user={props.user} profile={props.profile} />
        </Menu.Item>
      </Menu>
      <Grid
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 2fr",
          gridGap: "1em",
          alignItems: "center",
          justifyItems: "center",
          paddingLeft: "2%",
          paddingRight: "2%",
          paddingTop: "85px",
        }}
        columns={2}
      >
        <GridColumn>
          <div
            style={{
              width: w + "px",
              height: h + "px",
              position: "relative",
            }}
          >
            <Packview
              packName={title}
              clockBackground={imageUrl}
              pendulumBackground={pendulumUrl}
            />
          </div>
        </GridColumn>
        {approved || rejected ? (
          <div style={{ fontSize: "20px" }}>
            {" "}
            {rejected ? "The pack rejected" : "The pack rejected approved"}{" "}
          </div>
        ) : (
          <GridColumn>
            <PackCreationForm
              publicationText={
                review ? "Update Submit Review" : "Submit Review"
              }
              onSubmit={onSubmit}
              saved={saved}
              approved={approved}
              onSave={onSave}
              onDelete={onDelete}
              artists={artists}
              onArtistsChange={(a) => setArtists(a)}
              onDescriptionChange={(f) => setDescription(f)}
              description={description}
              onTitleChange={(title) => setTitle(title)}
              title={title}
              onKlydosChange={(klydos) => setKlydos(klydos)}
              klydos={klydos}
              onClockImageChange={(clockImage) => setImageUrl(clockImage)}
              clockImage={imageUrl}
              pendulumImage={pendulumUrl}
              onPendulumImageChange={(pendulumImage) => {
                setPendulumUrl(pendulumImage);
                setLogoUrl(pendulumImage);
              }}
            />
          </GridColumn>
        )}
      </Grid>
    </>
  );
};

export default PacksCreationContainer;
