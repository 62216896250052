import { ImgHTMLAttributes } from "react";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  scrollPosition: ScrollPosition;
}

const KlydoImage = ({ src, scrollPosition, style }: ImageProps) => {
  return (
    <LazyLoadImage scrollPosition={scrollPosition} src={src} style={style} />
  );
};

export default trackWindowScroll(KlydoImage);
