import React, { useState } from "react";
import { Popup } from "semantic-ui-react";
import { FaEdit, FaSave } from "react-icons/fa";
import KSocialIcon from "themes-views/KSocialIcons";

const EditableSocial = (props: {
  link: string;
  onChange: (value: string) => void;
  onRemove: () => void;
  network: string;
}) => {
  const [active, setActive] = useState(false);
  const [val, setVal] = useState<string>(props.link);
  const [newVal, setNewVal] = useState<string>(props.link);

  return (
    <Popup
      key={props.network}
      position="right center"
      basic
      on="click"
      pinned
      onClose={() => setActive(false)}
      trigger={
        <div style={{ position: "relative" }}>
          <KSocialIcon
            as="div"
            network={props.network}
            style={{ height: 35, width: 35, margin: 5 }}
            title={props.network}
          />
          <p
            title="remove"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "#000000",
              cursor: "default",
              fontSize: 10,
              fontWeight: 900,
            }}
            onClick={(e) => {
              e.stopPropagation();
              props.onRemove();
            }}
          >
            x
          </p>
        </div>
      }
    >
      {active ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <input
            defaultValue={val}
            onChange={(e) => setNewVal(e.target.value)}
          ></input>
          <FaSave
            style={{ margin: "0 20px" }}
            onClick={() => {
              props.onChange(newVal);
              setVal(newVal);
              setActive(false);
            }}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <a href={val} target="_blank" rel="noreferrer">
            {val}
          </a>
          <FaEdit
            title="edit"
            style={{ margin: "0 20px" }}
            onClick={() => setActive(true)}
          />
        </div>
      )}
    </Popup>
  );
};

export default EditableSocial;
