import React from "react";
import { Button } from "@mui/material";
import { KLYDO_WHITE } from "../../consts/colors";

const DSSecondaryButton = (props: React.ComponentProps<typeof Button>) => {
  return (
    <Button
      color="secondary"
      variant="contained"
      {...props}
      sx={{
        ...props.sx,
        borderRadius: 50,
        fontFamily: "Gilroy-Bold",
        textTransform: "none",
        padding: "7px 25px",
        "&:hover": {
          backgroundColor: "#2F2F2F",
          color: KLYDO_WHITE,
          transform: "scale(1.03)",
        },
      }}
    />
  );
};

export default DSSecondaryButton;
