import { Role } from "AppContext";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import firebaseService from "firebase_services/firebaseService";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Header,
  Icon,
  Input,
  Menu,
  Placeholder,
  Button,
  Loader,
  Modal,
  Form,
  Popup,
} from "semantic-ui-react";
import UserDropdown from "./user/UserMenu";
import { Profile, SocialMedia, Theme } from "context/klydo/KlydoTypes";
import { FaCheck, FaClock, FaPencil } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import { TbCloudX } from "react-icons/tb";
import ReactFlagsSelect from "react-flags-select";
import { UserAvatar } from "themes-views/user/userStyles";
import img_placeholder from "LoginView/img_placeholder.png";

import ButtonLoader from "./ButtonLoader";
import { socialMediaList, statesList } from "utils";
import EditableBatch from "./EditableBatch";
import ClockView from "ClockView/ClockView";
import EditableSocial from "./user/EditableSocial";
import EditableProfile from "./user/EditableProfile";
import KSocialIcon from "./KSocialIcons";
type Status = "waiting" | "uploading" | "deleted" | "failed" | "done";
export function BatchUpload(props: {
  user: firebase.User & Role;
  profile: Profile;
}) {
  const [gifs, setGifs] = useState<
    Array<{
      status: Status;
      id: string;
      name: string;
      theme: Theme;
      file: File;
      srcUrl: any;
    }>
  >([]);
  const [sendToReview, setSndToReview] = useState(false);
  const [loadGifs, setLoadGifs] = useState(false);
  const [createUserLoading, setCreateUserLoading] = useState(false);
  const [userExist, setUserExist] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [userId, setUserId] = useState("");
  const [inProccesGifs, setInProccessgifs] = useState(false);
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [newName, setNewName] = useState("new user");
  const [newPassword, setNewPassword] = useState<string>();
  const [socialMedia, setSocialMedia] = useState<SocialMedia[]>([]);
  const [newSocial, setNewSocial] = useState("");
  const [newCountry, setNewCountry] = useState<string>();
  const [newState, setNewState] = useState<string>();
  const [newDescription, setNewDescription] = useState<string>();
  const [newPhotoUrl, setNewPhotoUrl] = useState<string | ArrayBuffer>();
  const [userImgUrl, setUserImgUrl] = useState<string>();
  useEffect(() => {
    if (gifs.length) {
      if (gifs.find((g) => g.status === "waiting")) {
        setSent(false);
        uploadGif();
      }
    }
  }, [gifs.length]);
  const closeModal = () => {
    setOpenModal(false);
    setNewName("");
    setNewPassword("");
    setNewCountry("");
    setNewState("");
    setNewDescription("");
  };
  const uploadGif = async () => {
    if (gifs.find((g) => g.status === "uploading")) return;
    const gif = gifs.findIndex((g) => g.status === "waiting");
    if (gif !== -1) {
      changeStatus("uploading");
      const url = "https://api.cloudinary.com/v1_1/KlydoClock/auto/upload";
      const formData = new FormData();
      formData.append("file", gifs[gif].file);
      formData.append("folder", "gifs");
      formData.append("upload_preset", "Bezalel_preset");
      try {
        const response = await fetch(url, {
          method: "POST",
          body: formData,
        });
        const res = await response.json();
        const img = res.secure_url;
        if (img) {
          try {
            await firebaseService.adminSetGifs(
              userId,
              img,
              gifs[gif].id,
              gifs[gif].name,
              gifs[gif].theme,
            );
            changeStatus("done");
          } catch (e) {
            console.log(e);
            changeStatus("failed");
          }
        }
      } catch (e) {
        changeStatus("failed");
      }
    } else {
      setGifs(gifs.filter((g) => g.status !== "deleted"));
      setInProccessgifs(false);
      return;
    }
    uploadGif();

    function changeStatus(status: Status) {
      setGifs(gifs.filter((g) => g.status !== "deleted"));
      const tmp = [...gifs];
      tmp[gif].status = status;
      setGifs(tmp);
    }
  };

  return (
    <div>
      <div>
        <Menu fixed={"top"} pointing inverted style={{ height: 75 }}>
          <Menu.Item header>
            <Link to={"/"}>
              <Icon className="left arrow" />
            </Link>
          </Menu.Item>
          <Menu.Item position={"right"}>
            <UserDropdown user={props.user} profile={props.profile} />
          </Menu.Item>
        </Menu>
      </div>
      <div
        style={{
          paddingTop: 160,
          paddingLeft: "20%",
          paddingRight: "20%",
          width: "100%",
        }}
      >
        <Input
          list="emailsFilter"
          loading={createUserLoading}
          fluid
          placeholder="Select user"
          onChange={(e) => {
            setEmail(e.target.value);
            if (
              String(email)
                .toLowerCase()
                .match(
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                )
            ) {
              setValidEmail(true);
            } else setValidEmail(false);
            const user = firebaseService.users.find(
              (u) => u.email === e.target.value,
            );
            if (user) {
              setUserId(user.uid);
              setUserExist(true);
            } else setUserExist(false);
          }}
        />
        <datalist id="emailsFilter">
          {firebaseService.users
            .filter(
              (u) =>
                (u.email as string)
                  .toLowerCase()
                  .startsWith(email.toLowerCase()) ||
                (u.displayName as string)
                  .toLowerCase()
                  .includes(email.toLowerCase()),
            )
            .map((u, i) =>
              i > 4 ? null : (
                <option key={u.email} value={u.email}>
                  {(u.email as string)
                    .toLowerCase()
                    .startsWith(email.toLowerCase())
                    ? u.email
                    : u.displayName}
                </option>
              ),
            )}
        </datalist>
        {validEmail ? (
          userExist ? (
            <p>
              Account exist<br></br>Klydos will upload to this account
            </p>
          ) : (
            <p>
              Account not exist<br></br>create?{" "}
              <Button
                onClick={() => setOpenModal(true)}
                style={{
                  backgroundColor: "transparent",
                  color: "cornflowerblue",
                  paddingLeft: 5,
                }}
              >
                Create
              </Button>
            </p>
          )
        ) : (
          <></>
        )}
        {gifs.length !== 0 && (
          <>
            <Grid style={{ paddingTop: 50 }} container spacing={0}>
              {gifs.map((gif, index) =>
                gif.srcUrl ? (
                  <div key={gif.id} style={{ position: "relative" }}>
                    <div
                      style={{ position: "relative", width: 156, height: 258 }}
                    >
                      <ClockView
                        loopUrl={gif.srcUrl}
                        themeID={gif.id}
                        handsColor={gif.theme.handsColor}
                        pendulumColor={gif.theme.pendulumColor}
                        pendulumRodColor={gif.theme.pendulumRodColor}
                        backgroundColor={gif.theme.backgroundColor}
                        dialsColor={gif.theme.dialsColor}
                        activeCrop={false}
                      />
                    </div>
                    <div style={{ position: "absolute", top: 15, left: 20 }}>
                      {gif.status === "waiting" ? (
                        <FaClock
                          color="white"
                          style={{ mixBlendMode: "difference" }}
                        />
                      ) : gif.status === "uploading" ? (
                        <Loader
                          size="tiny"
                          color="white"
                          style={{
                            mixBlendMode: "difference",
                            position: "absolute",
                            top: 10,
                            left: 10,
                          }}
                          active
                        />
                      ) : gif.status === "failed" ? (
                        <TbCloudX
                          color="white"
                          style={{ mixBlendMode: "difference" }}
                        />
                      ) : (
                        <>
                          <FaCheck
                            color="white"
                            style={{ mixBlendMode: "difference" }}
                          />
                          <Popup
                            on="click"
                            position="left center"
                            trigger={
                              <FaPencil
                                color="white"
                                style={{ mixBlendMode: "difference" }}
                              />
                            }
                          >
                            <EditableBatch
                              type="name"
                              title="Name:"
                              default={gif.name}
                              onChange={(v) => {
                                firebaseService.updateKlydo(
                                  gif.id,
                                  userId,
                                  { name: v },
                                  () => {
                                    setGifs((prev) => {
                                      prev[index].name = v;
                                      return prev.slice();
                                    });
                                  },
                                );
                              }}
                            />
                            <p style={{ margin: "1em 0 0.2em 0" }}>Theme:</p>
                            <EditableBatch
                              type="color"
                              title="Background color:"
                              default={gif.theme.backgroundColor}
                              onChange={(v) => {
                                firebaseService.updateKlydo(
                                  gif.id,
                                  userId,
                                  { theme: { backgroundColor: v } },
                                  () => {
                                    setGifs((prev) => {
                                      prev[index].theme.backgroundColor = v;
                                      return prev.slice();
                                    });
                                  },
                                );
                              }}
                            />
                            <EditableBatch
                              type="color"
                              title="Hands color:"
                              default={gif.theme.handsColor}
                              onChange={(v) => {
                                firebaseService.updateKlydo(
                                  gif.id,
                                  userId,
                                  { theme: { handsColor: v } },
                                  () => {
                                    setGifs((prev) => {
                                      prev[index].theme.handsColor = v;
                                      return prev.slice();
                                    });
                                  },
                                );
                              }}
                            />
                            <EditableBatch
                              type="color"
                              title="Pendulum color:"
                              default={gif.theme.pendulumColor}
                              onChange={(v) => {
                                firebaseService.updateKlydo(
                                  gif.id,
                                  userId,
                                  { theme: { pendulumColor: v } },
                                  () => {
                                    setGifs((prev) => {
                                      prev[index].theme.pendulumColor = v;
                                      return prev.slice();
                                    });
                                  },
                                );
                              }}
                            />
                            <EditableBatch
                              type="color"
                              title="Pendulum rod color:"
                              default={gif.theme.pendulumRodColor}
                              onChange={(v) => {
                                firebaseService.updateKlydo(
                                  gif.id,
                                  userId,
                                  { theme: { pendulumRodColor: v } },
                                  () => {
                                    setGifs((prev) => {
                                      prev[index].theme.pendulumRodColor = v;
                                      return prev.slice();
                                    });
                                  },
                                );
                              }}
                            />
                            <EditableBatch
                              type="color"
                              title="Dials color:"
                              default={gif.theme.dialsColor}
                              onChange={(v) => {
                                firebaseService.updateKlydo(
                                  gif.id,
                                  userId,
                                  { theme: { dialsColor: v } },
                                  () => {
                                    setGifs((prev) => {
                                      prev[index].theme.dialsColor = v;
                                      return prev.slice();
                                    });
                                  },
                                );
                              }}
                            />
                          </Popup>
                        </>
                      )}
                    </div>
                    <div style={{ position: "absolute", top: 15, left: 145 }}>
                      {gif.status !== "uploading" && (
                        <AiOutlineClose
                          color="white"
                          style={{ mixBlendMode: "difference" }}
                          onClick={() => {
                            if (gif.status === "waiting") {
                              const tmp2 = [...gifs];
                              tmp2[index].status = "deleted";
                              setGifs(tmp2);
                            } else {
                              firebaseService
                                .adminDeleteKlydo(userId, gif.id)
                                .then(() => {
                                  const tmp2 = [...gifs];
                                  tmp2[index].status = "deleted";
                                  setGifs(tmp2);
                                  setGifs(
                                    gifs.filter((g) => g.status !== "deleted"),
                                  );
                                });
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                ) : null,
              )}
              {loadGifs && (
                <Placeholder style={{ height: 150, width: 150 }}>
                  <Placeholder.Image />
                </Placeholder>
              )}
            </Grid>
            <ButtonLoader
              style={{ marginTop: 30 }}
              fluid
              disabled={inProccesGifs || sent}
              color="black"
              title="send to review"
              loading={sendToReview}
              onClick={() => {
                return new Promise<void>((v, x) => {
                  setSndToReview(true);
                  firebaseService
                    .adminSendToReview(
                      userId,
                      gifs
                        .filter((g) => g.status !== "deleted")
                        .map((g) => g.id),
                    )
                    .then(() => {
                      setSndToReview(false);
                      setSent(true);
                      v();
                    })
                    .catch((e) => {
                      setSent(false);
                      console.log(e);
                      x();
                    });
                });
              }}
            />
          </>
        )}
        {userExist && !inProccesGifs ? (
          <div
            style={{
              height: 300,
              width: "100%",
              marginTop: 55,
              borderColor: "lightgray",
              borderWidth: 3,
              borderStyle: "dashed",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <Header icon>
                <Icon name="cloud upload" />
                Drag & Drop to upload gifs
              </Header>
            </div>
            <Input
              id="user-photo"
              onChange={async (e) => {
                const files = e.target.files;
                if (!files.length) return;
                setInProccessgifs(true);
                for (let i = 0; i < files.length; i++) {
                  let place: number;
                  setGifs((old) => {
                    place = old.length;
                    return [
                      ...old,
                      {
                        status: "waiting",
                        id: crypto.randomUUID(),
                        name: "new klydo",
                        theme: {
                          backgroundColor: "#507a32",
                          handsColor: "#5c327a",
                          pendulumColor: "#747a32",
                          pendulumRodColor: "#327a38",
                          dialsColor: "#327a38", /////Verify default color with the BOSS!!!
                        },
                        file: files[i],
                        srcUrl: undefined,
                      },
                    ];
                  });
                  const fr = new FileReader();
                  fr.onload = function () {
                    setGifs((prev) => {
                      prev[place].srcUrl = fr.result;
                      return prev.slice();
                    });
                  };
                  setLoadGifs(fr.readyState === fr.LOADING);
                  fr.readAsDataURL(files[i]);
                }
              }}
              multiple
              type="file"
              style={{ width: 100 + "%", height: 100 + "%", opacity: 0 }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <Modal open={openModal} style={{ top: 150 }} onClose={closeModal}>
        <Modal.Header>New User: {email}</Modal.Header>
        <Modal.Content>
          <div
            style={{
              width: 120,
              height: 120,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <span style={{ position: "absolute" }}>
              <UserAvatar
                style={{
                  height: 120,
                  width: 120,
                  background: "black",
                  margin: 0,
                }}
                src={newPhotoUrl || img_placeholder}
                size="small"
                circular
                referrerPolicy="no-referrer"
              />
            </span>
            <Input
              id="user-photo"
              onChange={(e) => {
                const fr = new FileReader();
                fr.onload = function () {
                  setNewPhotoUrl(fr.result);
                };
                const file = e.target.files[0];
                if (!file) return;
                fr.readAsDataURL(file);
                setTimeout(() => {
                  setCreateUserLoading(true);
                  const url =
                    "https://api.cloudinary.com/v1_1/KlydoClock/auto/upload";
                  const formData = new FormData();
                  formData.append("file", file);
                  formData.append("folder", "profile");
                  formData.append("upload_preset", "profiles");
                  return fetch(url, {
                    method: "POST",
                    body: formData,
                  })
                    .then((response) => {
                      return response.json();
                    })
                    .then((res) => {
                      const img = res.secure_url;
                      if (img) {
                        setUserImgUrl(img);
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                      setNewPhotoUrl(undefined);
                    })
                    .finally(() => setCreateUserLoading(false));
                }, 0);
              }}
              type="file"
              style={{ width: 100 + "%", height: 100 + "%", opacity: 0 }}
            />
          </div>
          <Form
            size="large"
            className="AdminRegister"
            onSubmit={(e) => {
              e.preventDefault();
              setCreateUserLoading(true);
              firebaseService
                .adminCreateNewUser(
                  email,
                  newPassword,
                  socialMedia,
                  userImgUrl,
                  newName,
                  newCountry,
                  newState,
                  newDescription,
                )
                .then((uid) => {
                  setCreateUserLoading(false);
                  setUserId(uid);
                  setUserExist(true);
                  firebaseService.adminUsersList();
                })
                .then(() => {
                  setCreateUserLoading(false);
                  closeModal();
                })
                .catch(console.log);
            }}
          >
            <Form.Input
              required
              icon="lock"
              label="Password"
              placeholder="Password"
              type="password"
              onChange={(_e, data) => setNewPassword(data.value)}
            />
            <Form.Input
              icon="user"
              label="Name"
              placeholder="Name"
              onChange={(_e, data) => setNewName(data.value)}
            />
            <div style={{ display: "flex" }} itemType="none">
              {socialMedia.map((s, i) => (
                <EditableSocial
                  network={s.network}
                  key={i}
                  onChange={(v) =>
                    setSocialMedia((prev) => {
                      prev[i].link = v;
                      return [...prev];
                    })
                  }
                  onRemove={() => {
                    setSocialMedia((prev) => {
                      prev.splice(i, 1);
                      return prev.slice();
                    });
                  }}
                  link={s.link}
                />
              ))}
              <Popup
                position="right center"
                basic
                on="click"
                pinned
                onClose={() => setNewSocial("")}
                trigger={
                  newSocial ? (
                    <KSocialIcon
                      network={newSocial}
                      title={newSocial}
                      style={{ height: 35, width: 35, margin: 5 }}
                    />
                  ) : socialMedia.length === 0 ? (
                    <Button
                      basic
                      color="blue"
                      content="Add Social Media"
                      onClick={(e) => e.preventDefault()}
                    />
                  ) : (
                    <Button
                      style={{ height: 35, width: 35, margin: 5 }}
                      basic
                      circular
                      icon="plus"
                      onClick={(e) => e.preventDefault()}
                    />
                  )
                }
              >
                {newSocial ? (
                  <>
                    <EditableProfile
                      active={newSocial !== ""}
                      type={"short"}
                      default={""}
                      style={{ fontSize: "2em" }}
                      onChange={(val) => {
                        if (val === "") {
                          setNewSocial("");
                          return;
                        }
                        setSocialMedia((prev) => {
                          prev.push({ network: newSocial, link: val });
                          return [...prev];
                        });
                        setNewSocial("");
                      }}
                    />
                  </>
                ) : (
                  socialMediaList.map((s) => (
                    <KSocialIcon
                      network={s}
                      key={s}
                      title={s}
                      style={{ height: 35, width: 35, margin: 5 }}
                      onClick={() => {
                        setNewSocial(s);
                      }}
                    />
                  ))
                )}
              </Popup>
            </div>
            <p style={{ fontWeight: 600 }}>Country</p>
            <ReactFlagsSelect
              className={
                !newCountry || newCountry === ""
                  ? "flag-select"
                  : "flag-select-after"
              }
              placeholder="Country"
              selected={newCountry}
              searchable
              onSelect={(code) => {
                setNewCountry(code);
              }}
            />

            {newCountry && newCountry === "US" && (
              <div>
                <Form.Input
                  required={newCountry && newCountry === "US"}
                  list="list"
                  icon="map marker alternate"
                  label="State"
                  placeholder="State"
                  onChange={(_e, data) => setNewState(data.value)}
                />
                <datalist id="list">
                  {statesList.map((v) => (
                    <option key={v.value} value={v.label}>
                      {v.label}
                    </option>
                  ))}
                </datalist>
              </div>
            )}
            <Form.Input
              control="description"
              as="textarea"
              icon="edit"
              label="Description"
              placeholder="Description"
              onChange={(e) => setNewDescription(e.target.value)}
            />
            <Form.Button
              color="instagram"
              type="submit"
              disabled={!newPassword || createUserLoading}
              fluid
              size="large"
              loading={createUserLoading}
              style={{ margin: 5, width: 200 }}
            >
              Create
            </Form.Button>
          </Form>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    </div>
  );
}
