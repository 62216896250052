import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import ClockView from "../ClockView/ClockView";
import { Klydo } from "../context/klydo/KlydoTypes";
import { FaEdit, FaGlobe, FaRegClock, FaRegHeart } from "react-icons/fa";
import {
  FaFileCircleCheck,
  FaFileCircleExclamation,
  FaFileCircleQuestion,
} from "react-icons/fa6";

import { resize } from "utils";

interface ThemesListItemProps {
  klydo: Klydo;
  style?: React.CSSProperties;
  isNavigate?: boolean; // Just declare the type here
}

const ThemesListItem: React.FC<ThemesListItemProps> = ({
  klydo,
  style,
  isNavigate = true, // Set the default value here
}): JSX.Element => {
  const scale = 0.4;
  const w = scale * 390;
  const h = scale * 643.5;
  const navigate = useNavigate();

  function nv() {
    if (isNavigate) {
      // It will use the default true if nothing is passed
      navigate("/klydo/" + klydo.id);
    }
  }

  return (
    <Grid
      onClick={() => nv()}
      xs={6}
      md={8}
      style={{
        display: "flex",
        flexDirection: "column",
        margin: 1 + "rem",
        padding: 0,
      }}
    >
      <div
        style={{
          position: "relative",
          width: w + "px",
          height: h + "px",
          padding: 0,
        }}
      >
        <ClockView
          loopUrl={resize(klydo.loopUrl, 200)}
          themeName={klydo.name}
          themeID={klydo.id}
          crop={klydo.crop}
          handsColor={klydo.theme?.handsColor}
          backgroundColor={klydo.theme?.backgroundColor}
          pendulumColor={klydo.theme?.pendulumColor}
          pendulumRodColor={
            klydo.theme?.pendulumRodColor || klydo.theme?.pendulumColor
          }
          dialsColor={klydo.theme?.dialsColor}
        />
      </div>
      <div
        style={{
          display: "flex",
          fontSize: "1.2em",
          textAlign: "center",
          fontWeight: "bold",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {klydo.public && (
          <>
            <p
              data-title="Public"
              style={{
                margin: 0,
                position: "relative",
                display: "inline-block",
              }}
            >
              <FaGlobe style={{ width: 24, height: 24 }} />
            </p>
            <div
              title="number of likes"
              style={{
                position: "relative",
                display: "inline-block",
              }}
            >
              <FaRegHeart style={{ width: 24, height: 24 }} />

              <div
                style={{
                  textAlign: "center",
                  fontSize: "0.6em",
                  position: "absolute",
                  top: "6%",
                  width: "100%",
                }}
              >{`${klydo.fav ?? 0}`}</div>
            </div>

            <div
              title="total view time"
              style={{
                position: "relative",
                display: "inline-block",
              }}
            >
              <FaRegClock style={{ width: 19, height: 19 }} />
              <div
                style={{
                  padding: 0,
                  fontSize: "0.6em",
                  fontWeight: "normal",
                  position: "absolute",
                  top: 16,
                  width: 18,
                }}
              >
                {`${klydo.time ?? 0}s`}
              </div>
            </div>
          </>
        )}
        {klydo.result &&
          (klydo.result.approved ? (
            <p
              data-title="Approved"
              style={{
                position: "relative",
                display: "inline-block",
              }}
            >
              <FaFileCircleCheck style={{ width: 24, height: 24 }} />
            </p>
          ) : (
            <p
              data-title={`${klydo.result.msg}`}
              style={{
                position: "relative",
                display: "inline-block",
              }}
            >
              <FaFileCircleExclamation style={{ width: 24, height: 24 }} />
            </p>
          ))}
        {klydo.review && (
          <p
            data-title="in review"
            style={{
              position: "relative",
              display: "inline-block",
            }}
          >
            <FaFileCircleQuestion style={{ width: 24, height: 24 }} />
          </p>
        )}
        {klydo.edited && (
          <p
            data-title="Changed"
            style={{
              position: "relative",
              display: "inline-block",
            }}
          >
            <FaEdit style={{ width: 24, height: 24 }} />
          </p>
        )}
      </div>
      <div style={{ textAlign: "center", width: w + "px" }}>
        <p style={{ display: "inline", marginRight: 4 + "px" }}>
          {klydo?.name}
        </p>
      </div>
    </Grid>
  );
};

export default ThemesListItem;
