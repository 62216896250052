import { Role } from "AppContext";
import { Pack, Profile } from "context/klydo/KlydoTypes";
import firebase from "firebase/compat/app";
import firebaseService from "firebase_services/firebaseService";

class PackService {
  private firebaseService = firebaseService;
  public async upsertDraft(
    pack: Omit<Pack, "id"> & { id?: string },
  ): Promise<string> {
    if (pack.id) {
      await this.firebaseService.db
        .collection("draftPacks")
        .doc(pack.id)
        .update(pack);
      return pack.id;
    } else {
      const result = await this.firebaseService.db
        .collection("draftPacks")
        .add(pack);
      return result.id;
    }
  }

  public async getDraft(id: string) {
    const data = await this.firebaseService.db
      .collection("draftPacks")
      .doc(id)
      .get();
    return { ...data.data(), id: data.id } as Pack;
  }

  public async getPacksByArtist(profile: Profile) {
    const data = await this.firebaseService.db
      .collection("draftPacks")
      .where("artists", "array-contains", profile.id)
      .get();
    return data.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as Pack[];
  }

  public async getPacksByCreator(user: firebase.User & Role) {
    const data = await this.firebaseService.db
      .collection("draftPacks")
      .where("creatorId", "==", user.uid)
      .get();
    return data.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as Pack[];
  }

  public async updatePack(packId: string, pack: Partial<Pack>): Promise<void> {
    await this.firebaseService.db
      .collection("draftPacks")
      .doc(packId)
      .update(pack);
  }

  public async deletePackFromDraft(
    packId: string,
    user: firebase.User & Role,
  ): Promise<void> {
    const packRef = this.firebaseService.db
      .collection("draftPacks")
      .doc(packId);

    if (user.role === "admin") {
      await packRef.delete();
    } else {
      const packDoc = await packRef.get();
      if (packDoc.exists && packDoc.data().creator === user.uid) {
        await packRef.delete();
      }
    }
  }

  public async getPackFromDraft(
    packId: string,
    user: firebase.User & Role,
  ): Promise<Partial<Pack>> {
    const packRef = this.firebaseService.db
      .collection("draftPacks")
      .doc(packId);

    if (user.role === "admin") {
      const packDoc = await packRef.get();
      return { ...packDoc.data(), id: packDoc.id } as Pack;
    } else {
      const packDoc = await packRef.get();
      if (packDoc.exists && packDoc.data().creator === user.uid) {
        return { ...packDoc.data(), id: packDoc.id } as Pack;
      }
    }
  }
}
const packService = new PackService();
export default packService;
