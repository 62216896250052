enum Env {
  DEVELOPMENT = "development",
  STAGING = "staging",
  PRODUCTION = "production",
}
const env: Env = (process.env.REACT_APP_ENV as Env) || Env.DEVELOPMENT;

interface ConfigSettings {
  baseApiUrl: string;
  callBackWebsite: string;
  baseUrl: string;
  authTokenName: string;
}

const settingsList: { [key in Env]: ConfigSettings } = {
  [Env.DEVELOPMENT]: {
    baseApiUrl: "http://localhost:8080/api",
    callBackWebsite: "http://localhost:3000",
    baseUrl: window.location.origin,
    authTokenName: "kldJWT",
  },
  [Env.STAGING]: {
    baseApiUrl: "https://klydo-main-service-staging.herokuapp.com/api",
    callBackWebsite: "https://klydoclock.com/",
    baseUrl: window.location.origin,
    authTokenName: "kldJWT",
  },
  [Env.PRODUCTION]: {
    baseApiUrl: "https://klydo-main-service.herokuapp.com/api",
    callBackWebsite: "https://klydoclock.com/",
    baseUrl: window.location.origin,
    authTokenName: "kldJWT",
  },
};

export const configSettings = settingsList[env];
