import giftClient from "../../../api/gift";
import useEditorQuery from "../../../hooks/useEditorQuery";

const useGiftCategories = () => {
  return useEditorQuery({
    queryKey: ["gift-categories"],
    queryFn: () => giftClient.listCategories(),
    refetchOnWindowFocus: false,
  });
};

export default useGiftCategories;
