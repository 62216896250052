import { baseClient } from "./baseRequest";

export type OrderInfo = {
  data: {
    first_name: string;
    last_name: string;
    status_name: string;
    info: {
      serial_number: string[];
    }[];
  };
};

export type OrderClient = {
  getOrderInfo(orderId: string): Promise<OrderInfo>;
};

const orderClient: OrderClient = {
  getOrderInfo: async (orderId) => {
    return baseClient.get({
      url: `/orders/${orderId}`,
    });
  },
};

export default orderClient;
