import React from "react";
import { IconButton } from "@mui/material";
import { KLYDO_BLACK, KLYDO_YELLOW } from "../../consts/colors";

const DSPrimaryIconButton = (
  props: React.ComponentProps<typeof IconButton>,
) => {
  return (
    <IconButton
      {...props}
      sx={{
        ...props.sx,
        borderRadius: 50,
        backgroundColor: KLYDO_YELLOW,
        color: KLYDO_BLACK,
        "&:hover": {
          backgroundColor: "#FFDF6A",
          transform: "scale(1.03)",
        },
        "&:disabled": {
          backgroundColor: "transparent",
          color: "#535252",
        },
      }}
    />
  );
};

export default DSPrimaryIconButton;
