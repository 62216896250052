import React from "react";
import "./PackViewContainer.css"; // Import the CSS file for styling
import pack_medium from "./pack_medium.png"; // Import the image file
const PackViewContainer = ({
  packName,
  clockBackground,
  pendulumBackground,
}) => {
  return (
    <div className="pack-clock-container">
      <div className="clock">
        {clockBackground && (
          <img
            src={clockBackground}
            alt="Clock Background"
            className="pack-clock-background"
          />
        )}
        <div className="pack-title-in">{packName}</div>
        <img src={pack_medium} alt="Wall Clock" className="pack-wall-clock" />
        {pendulumBackground && (
          <div
            className="pack-pendulum"
            style={{ backgroundImage: `url(${pendulumBackground})` }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default PackViewContainer;
