import { KlydoTimes, weekEnum } from "context/klydo/KlydoTypes";

export const calcTimes = (times: Array<KlydoTimes>): number => {
  const allArrAs = (n: number, as: boolean = true): Array<boolean> => {
    const a = new Array<boolean>();
    for (let i = 0; i < n; i++) a.push(as);
    return a;
  };
  const timeToMin = (t: string): number => {
    return parseInt(t.split(":")[0]) * 60 + parseInt(t.split(":")[1]);
  };
  const wToDay = (d: string): number => {
    switch (d.toUpperCase()) {
      case "SUNDAY":
        return weekEnum.SUNDAY;
      case "MONDAY":
        return weekEnum.MONDAY;
      case "TUESDAY":
        return weekEnum.TUESDAY;
      case "WENDNESDAY":
        return weekEnum.WENDNESDAY;
      case "THURSDAY":
        return weekEnum.THURSDAY;
      case "FRIDAY":
        return weekEnum.FRIDAY;
      case "SATURDAY":
        return weekEnum.SATURDAY;
      default:
        return 0;
    }
  };
  const mToDay = (d: string): number => {
    return parseInt(d);
  };
  const yToDay = (d: string): number => {
    const month = parseInt(d.split("/")[1]);
    const day = parseInt(d.split("/")[0]);
    let dec = 0;
    for (let i = 1; i < month; i++) {
      if (i === 2) dec = 3;
      else if (i === 4 || i === 6 || i === 9 || i === 11) dec += 1;
    }
    const res = (month - 1) * 31 + day - dec;
    return res;
  };

  if (times.length === 0) return 100;
  let timeArr: Array<boolean> = [];
  let dateArr: Array<boolean> = [];
  times.forEach((t) => {
    if (t.type === "daily") {
      if (timeArr.length === 0) timeArr = allArrAs(1439, t.negative);
      for (let i = timeToMin(t.start); i <= timeToMin(t.end); i++)
        timeArr[i] = !t.negative;
    } else {
      switch (t.type) {
        case "weekly":
          if (dateArr.length === 0) dateArr = allArrAs(7, t.negative);
          for (let i = wToDay(t.start); i <= wToDay(t.end); i++)
            dateArr[i] = !t.negative;
          break;
        case "monthly":
          if (dateArr.length === 0) dateArr = allArrAs(31, t.negative);
          for (let i = mToDay(t.start); i <= mToDay(t.end); i++)
            dateArr[i] = !t.negative;
          break;
        case "yearly":
          if (dateArr.length === 0) dateArr = allArrAs(365, t.negative);
          for (let i = yToDay(t.start); i <= yToDay(t.end); i++)
            dateArr[i] = !t.negative;
          break;
      }
    }
  });

  let res =
    (timeArr.length
      ? timeArr.reduce((p, c) => (c ? p + 1 : p), 0) / timeArr.length
      : 1) *
    (dateArr.length
      ? dateArr.reduce((p, c) => (c ? p + 1 : p), 0) / dateArr.length
      : 1) *
    100;
  return res;
};
