import { ChangeEvent, useEffect, useState } from "react";
import { Form, InputOnChangeData, Message } from "semantic-ui-react";
import { LoginContainerWebsite } from "./loginStyles";
import { configSettings as config } from "config";
import { Link } from "react-router-dom";
import "./registerForm.css";

const ForgotPassword: React.FC = (): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorHeader, setErrorHeader] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const handleEmailInputChange = (
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
  ): void => {
    const { value } = data;
    setError(false);
    setSuccess(false);
    if (value.length > 0 && value.includes("@")) {
      setEmail(value);
    }
  };

  // TODO  - add apiServices
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    setError(false);
    try {
      const response = await fetch(
        `${config.baseApiUrl}/users/website-forgot-password`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            email,
            callbackUrl: `${config.baseUrl}/reset-password-website`,
          }),
        },
      );
      const data = await response.json();
      if (data.success) {
        setSuccess(true);
        setLoading(false);
      } else {
        setLoading(false);
        const { message, validation } = data;
        const errorMessage = validation?.body.message || "";
        if (validation && validation.body.key === "email") {
          setEmailError(true);
        }
        setError(true);
        setErrorHeader(message ? message : "Error");
        setErrorMessage(errorMessage);
      }
    } catch (error) {
      setError(true);
      setErrorHeader("Something went wrong");
      setErrorMessage("Please try again later");
      console.log(error);
    }
  }

  useEffect((): void => {
    if (success) {
      setEmail("");
    }
    const origin = window.location.origin;
    window.parent.postMessage(
      {
        event_id: "kld_reset_login_message",
        data: {
          origin: `${origin}/dailies`,
        },
      },
      "*", //FIXME"www.BetterToBeRealParrentPage.com"
    );
  }, [success, loading, error]);

  const handleDismiss = (): void => {
    setError(false);
    setSuccess(false);
    setLoading(false);
  };

  return (
    <LoginContainerWebsite style={{ placeItems: "unset" }}>
      <Form size="large" className="register" onSubmit={handleSubmit}>
        <Form.Input
          fluid
          icon="user"
          label="Your Klydo Account Email"
          iconPosition="left"
          placeholder="E-mail address"
          onChange={handleEmailInputChange}
          error={emailError}
        />
        <Form.Button
          color="instagram"
          type="submit"
          disabled={!email}
          fluid
          size="large"
          loading={loading}
        >
          Request New Password
        </Form.Button>
        <div style={{ width: "100%" }}>
          {" "}
          <Link to="/website" style={{ color: "slategray" }}>
            Return to Login!
          </Link>
        </div>
      </Form>
      {error && (
        <Message
          error
          icon="warning circle"
          header={errorHeader}
          content={errorMessage}
          onDismiss={handleDismiss}
          style={{
            position: "absolute",
            boxShadow: "5px 17px 17px rgba(0,0,0,0.35)",
          }}
        />
      )}
      {success && (
        <Message
          success
          icon="info circle"
          header="Change user account password email sent"
          content={`Please check your email: ${email} to update your account`}
          onDismiss={handleDismiss}
          style={{ position: "absolute" }}
        />
      )}
    </LoginContainerWebsite>
  );
};

export default ForgotPassword;
