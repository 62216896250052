import { ChangeEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, InputOnChangeData, Message } from "semantic-ui-react";
import { LoginContainerWebsite } from "./loginStyles";
import { configSettings as config } from "config";

const LoginWebsite: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorHeader, setErrorHeader] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const handleEmailInputChange = (
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
  ): void => {
    const { value } = data;
    setEmail(value);
  };

  const handlePasswordInputChange = (
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
  ): void => {
    const { value } = data;
    setPassword(value);
  };

  // TODO  - add apiServices appsouce
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      const response = await fetch(`${config.baseApiUrl}/users/login`, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email,
          password,
        }),
      });
      if (response.status === 200) {
        const data = await response.json();
        const { authToken } = data;
        sessionStorage.setItem(config.authTokenName, authToken);
        const origin = window.location.origin;
        window.parent.postMessage(
          {
            event_id: "kld_login_message",
            data: {
              origin: `${origin}/dailies`,
            },
          },
          "*", //FIXME"www.BetterToBeRealParrentPage.com"
        );
        navigate("/dailies");
      } else {
        const data = await response.json();
        const { message, validation } = data;
        const errorMessage = validation?.body.message || "";
        if (validation && validation.body.key === "email") {
          setEmailError(true);
        }
        setError(true);
        setErrorHeader(message ? message : "Error");
        setErrorMessage(errorMessage);
      }
    } catch (error) {
      setError(true);
      setErrorHeader("Something went wrong");
      setErrorMessage("Please try again later");
      console.log(error);
    }
  }

  return (
    <LoginContainerWebsite>
      <Form size="large" onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Form.Input
          fluid
          icon="user"
          iconPosition="left"
          placeholder="E-mail address"
          onChange={handleEmailInputChange}
          error={emailError}
        />
        <Form.Input
          fluid
          icon="lock"
          iconPosition="left"
          placeholder="Password"
          type="password"
          onChange={handlePasswordInputChange}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0.5em 0 1.5em",
          }}
        >
          <Link
            to="/forgot-password-website"
            style={{ color: "slategray", margin: "0 0.5em" }}
          >
            Forgot Password?
          </Link>
        </div>
        <Form.Button
          color="instagram"
          type="submit"
          disabled={!email || !password}
          fluid
          size="large"
        >
          Login
        </Form.Button>
      </Form>
      {error && (
        <Message
          error
          icon="warning circle"
          header={errorHeader}
          content={errorMessage}
        />
      )}
    </LoginContainerWebsite>
  );
};

export default LoginWebsite;
