import styled from "styled-components";
import { Header } from "semantic-ui-react";

export const LogoHeader = styled(Header)`
  font-family: MuseoModerno, cursive;
  color: white;
  font-size: 8vw;
  font-weight: 500;
  text-shadow: black -20px -6px 20px;
  @media (max-width: 768px) {
    font-size: 9vw;
  }
`;

export const LogoTagline = styled(Header)`
  font-family: MuseoModerno, cursive;
  color: white;
  font-size: 1.75vw;
  margin-top: -1vw;
  border: 0px solid white;
  font-weight: 300;
  text-shadow: black -3px 0px 5px;
  @media (max-width: 768px) {
    font-size: 2.75vw;
  }
`;
