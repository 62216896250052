export enum UserRole {
  USER = "user",
  ADMIN = "admin",
}
export type Theme = {
  backgroundColor: string;
  handsColor: string;
  pendulumColor: string;
  pendulumRodColor: string;
  dialsColor?: string;
};

export type ReviewScheme = {
  type: "delete" | "edit";
  date: any;
  msg?: string;
  approved?: boolean;
};
export type ReviewHistory = {
  ended: Date;
  started: Date;
  approved: boolean;
  msg?: string;
  type?: string;
};
export enum weekEnum {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WENDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}
export type timeTypes = "daily" | "weekly" | "monthly" | "yearly";
export type KlydoTimes = {
  type: timeTypes;
  start: string;
  end: string;
  negative: boolean;
};

export type Pack = {
  id: string;
  tag: string;
  logoUrl?: string;
  artists?: string[];
  name: string;
  description?: string;
  result?: ReviewScheme;
  review?: ReviewScheme;
  imageUrl?: string;
  pendulumImageUrl?: string;
  edited?: boolean;
  pub: boolean;
  klydos?: string[];
  createdAt?: any;
  creatorId?: string;
  saved?: boolean;
};

export type Klydo = {
  name: string;
  createdAt: any;
  review?: ReviewScheme;
  reviewHistory?: Array<ReviewScheme>;
  result?: ReviewScheme;
  loopUrl: string;
  convertedLoopUrl: string;
  theme: Theme;
  id: string;
  idf?: string;
  userId: string;
  public: boolean;
  time: number;
  fav: number;
  zip: boolean;
  edited: boolean;
  times?: Array<KlydoTimes>;
  crop?: { zoom: number; left: number; top: number };
  dials?: boolean;
};

export type Profile = {
  clock?: string;
  friendlyClock?: string;
  country?: string;
  description?: string;
  name?: string;
  photoUrl?: string;
  email?: string;
  state?: string;
  id: string;
  socialMedia?: Array<SocialMedia>;
};
export type SocialMedia = { network: string; link: string };
