import { LogoHeader, LogoTagline } from "./logoStyles";

const Logo: React.FC = (): JSX.Element => {
  return (
    <>
      <LogoHeader as="h1">Klydo</LogoHeader>
      <LogoTagline as="h2">Whatever makes you tick</LogoTagline>
    </>
  );
};

export { Logo };
