import React, { useEffect, useState } from "react";
import {
  FormField,
  Button,
  Form,
  Input,
  TextArea,
  Grid,
  GridRow,
  Modal,
} from "semantic-ui-react";
import UploadFileButton from "themes-views/UploadFileButton";
import KlydosService from "../../firebase_services/klydosService";
import { Klydo } from "context/klydo/KlydoTypes";
import useUsersList, { User } from "hooks/useUsersList";
import KlydosPickDialog from "./KlydosPickDialog";

export type PackCreationFormProps = {
  onClockImageChange: (file: string) => void;
  clockImage?: string;
  onPendulumImageChange: (file: string) => void;
  pendulumImage?: string;
  onTitleChange: (title: string) => void;
  title?: string;
  onDescriptionChange: (description: string) => void;
  description?: string;
  onKlydosChange: (klydos: string[]) => void;
  klydos?: string[];
  onSubmit: () => void;
  onSave: () => void;
  onDelete: () => void;
  artists?: string[];
  onArtistsChange: (artists: string[]) => void;
  publicationText?: string;
  saved?: boolean;
  approved?: boolean;
};

const PackCreationForm = ({
  onClockImageChange,
  clockImage,
  onPendulumImageChange,
  pendulumImage,
  artists,
  onArtistsChange,
  onTitleChange,
  title,
  onSubmit,
  onDescriptionChange,
  description,
  onKlydosChange,
  klydos,
  publicationText,
  onSave,
  onDelete,
  saved,
  approved,
}: PackCreationFormProps) => {
  const [allKlydos, setAllKlydos] = useState<Klydo[]>([]);
  const [isKlydoModalOpen, setIsKlydoModalOpen] = useState(false);
  const [selectedKlydos, setSelectedKlydos] = useState(klydos);
  const usersList = useUsersList();
  const [usersOptions, setUsersOptions] = useState<User[]>([]);
  const [edit, setEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalSubmit, setModalSubmit] = useState(false);

  useEffect(() => {
    KlydosService.getAllKlydos().then((k) => setAllKlydos(k));
  }, []);

  useEffect(() => {
    setUsersOptions(
      usersList.filter((u) =>
        ["admin", "editor"].includes(u.customClaims?.role),
      ),
    );
  }, [usersList]);

  const handleKlydoSelection = (selected: string[]) => {
    setSelectedKlydos(selected);
    onKlydosChange(selected);
  };

  return (
    <div>
      <Form>
        <FormField
          required={true}
          label="Pack's name"
          control={Input}
          value={title}
          placeholder="My new pack"
          onChange={(_e, data) => {
            onTitleChange(data.value);
            setEdit(true);
          }}
        />
        <FormField
          label="Description"
          control={TextArea}
          value={description}
          onChange={(_e, data) => {
            onDescriptionChange(data.value as string);
            setEdit(true);
          }}
        />
        <Grid>
          <GridRow
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <FormField
              required
              label="Pack Cover"
              type="file"
              value={clockImage}
              onChange={(f) => {
                const fileReader = new FileReader();
                fileReader.onload = (e) => {
                  onClockImageChange(e.target.result.valueOf() as string);
                  setEdit(true);
                };
                fileReader.readAsDataURL(f.target.files[0]);
              }}
              control={UploadFileButton}
              accept="image/*"
            />

            <FormField
              required
              label="Pack Icon"
              type="file"
              value={pendulumImage}
              onChange={(f) => {
                const fileReader = new FileReader();
                fileReader.onload = (e) => {
                  onPendulumImageChange(e.target.result.valueOf() as string);
                  setEdit(true);
                };
                fileReader.readAsDataURL(f.target.files[0]);
              }}
              control={UploadFileButton}
              accept="image/png"
            />
          </GridRow>
        </Grid>
        <Form onSubmit={onSubmit}>
          <div>
            Selected Klydos:{" "}
            {selectedKlydos
              .map((id) => allKlydos.find((k) => k.id === id)?.name)
              .join(", ")}
          </div>
          <Button
            type="button"
            onClick={() => {
              setIsKlydoModalOpen(true);
              setEdit(true);
            }}
          >
            Select Klydos
          </Button>
          {isKlydoModalOpen && (
            <KlydosPickDialog
              allKlydos={allKlydos}
              selectedKlydos={selectedKlydos}
              onPickKlydos={handleKlydoSelection}
              onClose={() => setIsKlydoModalOpen(false)}
            />
          )}
        </Form>
        <Form.Select
          multiple
          search
          label={"Artists"}
          options={usersOptions.map((user) => ({
            label: user.displayName || user.email,
            key: user.id,
            text: user.displayName || user.email,
            value: user.id,
          }))}
          placeholder={"Artists"}
          onChange={(e: React.SyntheticEvent<HTMLElement>, d) => {
            onArtistsChange(
              usersOptions
                .filter((user) => (d.value as string[]).includes(user.id))
                .map((k) => k.id),
            );
            setEdit(true);
          }}
          value={usersOptions
            .filter((user) => artists.includes(user.id))
            .map((user) => user.id)}
          required
          fluid
          selection
        ></Form.Select>
        <Button
          color="facebook"
          type="submit"
          onClick={() => {
            onSave();
            setEdit(false);
          }}
          disabled={
            (!edit && saved) ||
            !title ||
            !clockImage ||
            !pendulumImage ||
            selectedKlydos.length === 0
          }
        >
          Save
        </Button>
        <Button
          color="facebook"
          type="submit"
          onClick={() => setModalSubmit(true)}
          disabled={
            !title ||
            !clockImage ||
            !pendulumImage ||
            selectedKlydos.length === 0 ||
            !saved ||
            approved
          }
        >
          {publicationText}
        </Button>
        <Button
          color="youtube"
          type="reset"
          onClick={() => setModalDelete(true)}
          disabled={!saved}
        >
          Delete
        </Button>
      </Form>
      <Modal
        style={{ marginTop: "-20%" }}
        centered={false}
        size={"mini"}
        open={modalDelete}
        onClose={() => setModalDelete(false)}
      >
        <Modal.Header>{`Delete pack`}</Modal.Header>
        <Modal.Content>
          <p>
            {approved
              ? "The pack is already public, you can't delete it"
              : "Are you sure you want to delete this klydo?"}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setModalDelete(false)}>
            Cancel
          </Button>
          <Button disabled={approved} positive onClick={onDelete}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        style={{ marginTop: "-20%" }}
        centered={false}
        size={"mini"}
        open={modalSubmit}
        onClose={() => setModalSubmit(false)}
      >
        <Modal.Header>{`Submit pack`}</Modal.Header>
        <Modal.Content>
          <p>
            {approved
              ? "Request to submit this pack?"
              : "Are you sure you want to submit this pack? pay attention once you submit you can't make any changes"}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setModalSubmit(false)}>
            Cancel
          </Button>
          <Button disabled={approved} positive onClick={onSubmit}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default PackCreationForm;
