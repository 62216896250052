import React from "react";
import { IconButton } from "@mui/material";
import { KLYDO_WHITE } from "../../consts/colors";

const DSSecondaryIconButton = (
  props: React.ComponentProps<typeof IconButton>,
) => {
  return (
    <IconButton
      {...props}
      sx={{
        ...props.sx,
        borderRadius: 50,
        backgroundColor: "#212121",
        color: "#626262",
        "&:hover": {
          backgroundColor: "#2F2F2F",
          color: KLYDO_WHITE,
          transform: "scale(1.03)",
        },
        "&:disabled": {
          backgroundColor: "transparent",
          color: "#535252",
        },
      }}
    />
  );
};

export default DSSecondaryIconButton;
