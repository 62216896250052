import React, { Component } from "react";
import { Button, Loader } from "semantic-ui-react";

class ButtonLoader extends Component<
  any,
  { loading: boolean; error: boolean },
  any
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      error: false,
    };
  }
  error = () => {
    this.setState({ error: true });
    setTimeout(() => {
      this.setState({ error: false });
    }, 5000);
  };
  render() {
    return (
      <Button
        {...this.props}
        onClick={
          this.state.loading
            ? undefined
            : () => {
                this.setState({ loading: true });
                this.props
                  .onClick()
                  .catch(this.error)
                  .finally(() => this.setState({ loading: false }));
              }
        }
      >
        <>
          {this.state.loading && <Loader active />}
          {this.state.error && <i className="nc-icon nc-simple-remove"></i>}
          {this.props.title}
        </>
      </Button>
    );
  }
}
export default ButtonLoader;
