const firebaseConfig =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? {
        apiKey: "AIzaSyARKs0hpikW3U23NEpU9FMVHYmOddPDInM",
        authDomain: "klydo-development.firebaseapp.com",
        projectId: "klydo-development",
        storageBucket: "klydo-development.appspot.com",
        messagingSenderId: "238468556119",
        appId: "1:238468556119:web:93efd26e522a6c0604ca12",
        measurementId: "G-1JZK5J64WR",
      }
    : {
        apiKey: "AIzaSyC5XG3LtScVeWzLYbiYE-JXY4GLFRnGduQ",
        authDomain: "dazzling-mantra-347515.firebaseapp.com",
        projectId: "dazzling-mantra-347515",
        storageBucket: "dazzling-mantra-347515.appspot.com",
        messagingSenderId: "917257785826",
        appId: "1:917257785826:web:33e6acd03b4fc97148a9bb",
        measurementId: "G-W7R10B8C9R",
      };
export default firebaseConfig;
