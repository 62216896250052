import { useEffect, useState } from "react";
import useAppContext from "./useAppContext";
import firebaseService from "firebase_services/firebaseService";
import { Role } from "AppContext";

export type User = {
  id: string;
  email: string;
  displayName?: string;
  customClaims?: Role;
};

const useUsersList = () => {
  const { user } = useAppContext();
  const [usersList, setUsersList] = useState<User[]>([]);
  useEffect(() => {
    if (user.role === "admin") {
      const usersList = Array<User>();
      firebaseService.users.forEach((u) => {
        usersList.push({
          id: u.uid,
          email: u.email,
          displayName: u.displayName,
          customClaims: u.customClaims,
        });
      });
      usersList.sort((a, b) => {
        return a.displayName
          ? a.displayName?.localeCompare(b.displayName || b.email)
          : a.email?.localeCompare(b.displayName || b.email);
      });

      setUsersList(usersList);
    }
  }, [user.role]);

  return usersList;
};

export default useUsersList;
