import { useState } from "react";
import ColorModal from "./ColorModal";

type KlydoColorPickerProps = {
  color: string;
  settingTitle: string;
  onColorChange: (color: string) => void;
  disabled?: boolean;
};

const KlydoColorPicker = (props: KlydoColorPickerProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const [position, setPosition] = useState<{ x: number; y: number }>();
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <input
            disabled={props.disabled ?? false}
            type="color"
            value={props.color}
            onChange={(e) => props.onColorChange(e.target.value)}
            style={{
              width: 50 + "px",
              height: 50 + "px",
              backgroundColor: props.color,
              opacity: props.disabled ? 0.5 : 1,
              cursor: props.disabled ? "not-allowed" : "pointer",
            }}
          />
        </div>

        <p
          style={{
            textAlign: "center",
            padding: 16 + "px",
            opacity: props.disabled ? 0.5 : 1,
          }}
        >
          {props.settingTitle}
        </p>
      </div>
    </>
  );
};

export default KlydoColorPicker;
