import { ChangeEvent, useEffect, useState } from "react";
import { Form, InputOnChangeData, Message, Segment } from "semantic-ui-react";
import { LoginContainer, LoginContent } from "./loginStyles";
import { configSettings as config } from "config";
import { Link } from "react-router-dom";
import "./registerForm.css";
import { Logo, LogoTagline } from "../components";

const ResetPassword: React.FC = (): JSX.Element => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorHeader, setErrorHeader] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const handlePasswordInputChange = (
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
  ): void => {
    const { value } = data;
    setError(false);
    setSuccess(false);
    setPassword(value);
  };

  const handleConfirmPasswordInputChange = (
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
  ): void => {
    const { value } = data;
    setError(false);
    setSuccess(false);
    setConfirmPassword(value);
  };

  // TODO  - add apiServices
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    setError(false);
    try {
      const response = await fetch(
        `${config.baseApiUrl}/users/reset-password`,
        {
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            password,
            confirmPassword: confirmPassword,
          }),
        },
      );
      const data = await response.json();
      if (data.success) {
        setSuccess(true);
        setLoading(false);
      } else {
        setLoading(false);
        const { message, validation } = data;
        const errorMessage = validation?.body.message || "";
        setError(true);
        setErrorHeader(message ? message : "Error");
        setErrorMessage(errorMessage);
      }
    } catch (error) {
      setError(true);
      setErrorHeader("Something went wrong");
      setErrorMessage("Please try again later");
      console.log(error);
    }
  }

  useEffect((): void => {
    if (success) {
      setPassword("");
      setConfirmPassword("");
    }
  }, [success, loading, error]);

  const handleDismiss = (): void => {
    setError(false);
    setSuccess(false);
    setLoading(false);
  };

  return (
    <LoginContainer>
      <LoginContent>
        <div>
          <Logo />
          <LogoTagline
            as="h3"
            style={{ fontSize: "2em", marginTop: "1em", marginBottom: "-1em" }}
          >
            Change your password
          </LogoTagline>
          <Segment
            inverted
            style={{ backgroundColor: "transparent", marginTop: "3em" }}
          >
            <Form size="large" className="register" onSubmit={handleSubmit}>
              <Form.Input
                fluid
                icon="lock"
                label="Desired Password"
                iconPosition="left"
                placeholder="Password"
                type="password"
                onChange={handlePasswordInputChange}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                label="Confirm Password"
                placeholder="Confirm Password"
                type="password"
                onChange={handleConfirmPasswordInputChange}
              />
              <Form.Button
                color="instagram"
                type="submit"
                disabled={!password || !confirmPassword}
                fluid
                size="large"
                loading={loading}
              >
                Update Password
              </Form.Button>
              <div style={{ textAlign: "center", width: "100%" }}>
                {" "}
                <Link to="/" style={{ color: "white" }}>
                  Return to Login!
                </Link>
              </div>
            </Form>
            {error && (
              <Message
                error
                icon="warning circle"
                header={errorHeader}
                content={errorMessage}
                onDismiss={handleDismiss}
                style={{
                  position: "absolute",
                  boxShadow: "5px 17px 17px rgba(0,0,0,0.35)",
                }}
              />
            )}
            {success && (
              <Message
                info
                icon="info circle"
                header="Reset password successful"
                content={`Please return to login!`}
                onDismiss={handleDismiss}
                style={{ position: "absolute" }}
              />
            )}
          </Segment>
        </div>
      </LoginContent>
    </LoginContainer>
  );
};

export default ResetPassword;
