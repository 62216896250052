import { ChangeEvent, useEffect, useState } from "react";
import { Form, InputOnChangeData, Message, Segment } from "semantic-ui-react";
import { LoginContainer, LoginContent } from "./loginStyles";
import { configSettings as config } from "config";
import { Link } from "react-router-dom";
import "./registerForm.css";
import { Logo, LogoTagline } from "../components";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const ForgotPassword: React.FC = (): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorHeader, setErrorHeader] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const handleEmailInputChange = (
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
  ): void => {
    const { value } = data;
    setError(false);
    setSuccess(false);
    if (value.length > 0 && value.includes("@")) {
      setEmail(value);
    }
  };

  // TODO  - add apiServices
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    setError(false);
    event.preventDefault();
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setSuccess(true);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setErrorHeader("Something went wrong");
        setErrorMessage("Please try again later");
        console.log(error);
      });
  }

  useEffect((): void => {
    if (success) {
      setEmail("");
    }
  }, [success, loading, error]);

  const handleDismiss = (): void => {
    setError(false);
    setSuccess(false);
    setLoading(false);
  };

  return (
    <LoginContainer>
      <LoginContent>
        <div>
          <Logo />
          <LogoTagline
            as="h3"
            style={{ fontSize: "2em", marginTop: "1em", marginBottom: "-1em" }}
          >
            Password Reset for Account
          </LogoTagline>
          <Segment
            inverted
            style={{ backgroundColor: "transparent", marginTop: "3em" }}
          >
            <Form size="large" className="register" onSubmit={handleSubmit}>
              <Form.Input
                fluid
                icon="user"
                label="Your Klydo Account Email"
                iconPosition="left"
                placeholder="E-mail address"
                onChange={handleEmailInputChange}
                error={emailError}
              />
              <Form.Button
                color="instagram"
                type="submit"
                disabled={!email}
                fluid
                size="large"
                loading={loading}
              >
                Request New Password
              </Form.Button>
              <div style={{ textAlign: "center", width: "100%" }}>
                {" "}
                <Link to="/" style={{ color: "white" }}>
                  Return to Login!
                </Link>
              </div>
            </Form>
            {error && (
              <Message
                error
                icon="warning circle"
                header={errorHeader}
                content={errorMessage}
                onDismiss={handleDismiss}
                style={{
                  position: "absolute",
                  boxShadow: "5px 17px 17px rgba(0,0,0,0.35)",
                }}
              />
            )}
            {success && (
              <Message
                success
                icon="info circle"
                header="Change user account password email sent"
                content={`Please check your email: ${email} to update your account`}
                onDismiss={handleDismiss}
                style={{ position: "absolute" }}
              />
            )}
          </Segment>
        </div>
      </LoginContent>
    </LoginContainer>
  );
};

export default ForgotPassword;
