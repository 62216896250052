import firebase from "firebase/compat/app";
import { Role } from "AppContext";
import { Pack, Profile } from "context/klydo/KlydoTypes";
import PackListItem from "./PackListItem";
import { Menu, Icon, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import UserDropdown from "themes-views/user/UserMenu";

interface PacksContainerProps {
  user: firebase.User & Role;
  profile: Profile;
  packs: Pack[];
}

const PacksContainer: React.FC<PacksContainerProps> = ({
  user,
  profile,
  packs,
}) => {
  if (!packs)
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  return (
    <>
      <Menu fixed={"top"} pointing inverted>
        <Menu.Item header position="left">
          <Link to={"/"}>
            <Icon className="left arrow" />
          </Link>
        </Menu.Item>
        <Menu.Item positon={"right"}>
          <UserDropdown user={user} profile={profile} />
        </Menu.Item>
      </Menu>
      <Grid style={{ paddingTop: "85px" }} container spacing={0}>
        {packs.map((pack) => (
          <PackListItem key={pack.id} pack={pack} />
        ))}
      </Grid>
    </>
  );
};

export default PacksContainer;
