import { KlydoTimes, timeTypes } from "context/klydo/KlydoTypes";
import { useEffect, useState } from "react";
import { FaEdit, FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";
import {
  Input,
  Checkbox,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  Select,
  Table,
} from "semantic-ui-react";
import { calcTimes } from "./TimeCalc";
import { percentToPresent } from "utils";

const tTypes = ["daily", "weekly", "monthly", "yearly"];
const weekDays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];
enum weekEnum {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WENDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

const TimeControl = (props: {
  times: KlydoTimes[];
  onSave: (t: KlydoTimes[]) => void;
}) => {
  const [times, setTimes] = useState(props.times);
  const [editTimes, setEditTimes] = useState([] as KlydoTimes[]);
  const [warnings, setWarnings] = useState<Array<string>>([]);
  const [globalWarning, setGlobalWarning] = useState("");
  const [modal, setModal] = useState(false);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    setTimes(props.times);
  });
  const closeModal = () => {
    setEditTimes([]);
    setModal(false);
  };
  const checkGlobalWarning = (): boolean => {
    let type = undefined as timeTypes;
    let res = true;
    editTimes.forEach((t) => {
      if (!!!type) {
        if (t.type !== "daily") type = t.type;
      } else {
        if (t.type !== "daily" && t.type !== type) {
          res = false;
        }
      }
    });
    res
      ? setGlobalWarning("")
      : setGlobalWarning("Only one type of date is permitted");
    return res;
  };
  const resetTypeInput = (i: number, type: timeTypes) => {
    switch (type) {
      case "daily": {
        editTimes[i].start = "00:00";
        editTimes[i].end = "23:59";
        break;
      }
      case "weekly": {
        editTimes[i].start = "sunday";
        editTimes[i].end = "saturday";
        break;
      }
      case "monthly": {
        editTimes[i].start = "1";
        editTimes[i].end = "31";
        break;
      }
      case "yearly": {
        editTimes[i].start = "01/01";
        editTimes[i].end = "31/12";
        break;
      }
    }
    setEditTimes([...editTimes]);
  };
  const dateChek = (s: string): string => {
    const chk = s.split("/");
    if (chk.length !== 2) return "Date format is dd/mm";
    if (chk[0].length === 0 || chk[1].length === 0) return "Incomplete date";
    if (
      !(
        /^(([0-2]?[0-9])|(3[0-1]))$/.test(chk[0]) &&
        /^((0?[0-9])|(1[0-2]))$/.test(chk[1])
      )
    )
      return "Incorect Date";
    if (
      (chk[0] === "30" && /^0?2$/.test(chk[1])) ||
      (chk[0] === "31" && /^(0?[2469]|11)$/.test(chk[1]))
    )
      return "Date do not exist";
    return "";
  };
  const inputType = (i: number) => {
    switch (editTimes[i].type) {
      case "daily":
        return (
          <div
            style={{
              width: "400px",
              marginLeft: 12,
              marginRight: 12,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Input
              label="start time:"
              value={editTimes[i].start}
              onChange={(e) => {
                editTimes[i].start = e.target.value;
                setEditTimes([...editTimes]);
              }}
              type="time"
            />
            <Input
              label="end time:"
              value={editTimes[i].end}
              onChange={(e) => {
                editTimes[i].end = e.target.value;
                setEditTimes([...editTimes]);
              }}
              type="time"
            />
          </div>
        );
      case "weekly":
        return (
          <div
            style={{
              width: "400px",
              marginLeft: 12,
              marginRight: 12,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Select
              value={editTimes[i].start}
              onChange={(_, d) => {
                editTimes[i].start = d.value as string;
                setEditTimes([...editTimes]);
              }}
              selection
              options={weekDays.map((t) => ({ value: t, text: t }))}
            ></Select>
            <Select
              value={editTimes[i].end}
              onChange={(_, d) => {
                editTimes[i].end = d.value as string;
                setEditTimes([...editTimes]);
              }}
              selection
              options={weekDays.map((t) => ({ value: t, text: t }))}
            ></Select>
          </div>
        );
      case "monthly":
        return (
          <div
            style={{
              width: "400px",
              marginLeft: 12,
              marginRight: 12,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Input
              label="start day:"
              value={editTimes[i].start}
              onChange={(e) => {
                editTimes[i].start = e.target.value;
                setEditTimes([...editTimes]);
              }}
              type="number"
              max={31}
              min={1}
            />
            <Input
              label="end day:"
              value={editTimes[i].end}
              onChange={(e) => {
                editTimes[i].end = e.target.value;
                setEditTimes([...editTimes]);
              }}
              type="number"
              max={31}
              min={1}
            />
          </div>
        );
      case "yearly":
        return (
          <div
            style={{
              width: "400px",
              marginLeft: 12,
              marginRight: 12,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Input
              style={{ width: "190px" }}
              value={editTimes[i].start}
              onChange={(e) => {
                const w = dateChek(e.target.value);
                if (w === "" || w === "Incomplete date") {
                  editTimes[i].start = e.target.value;
                  setEditTimes([...editTimes]);
                }
              }}
            >
              <div
                className="ui label"
                style={{ margin: 0, alignItems: "center", fontSize: "1em" }}
              >
                start date:
              </div>
              <input style={{ width: "55px" }} />
            </Input>
            <Input
              style={{ width: "190px" }}
              value={editTimes[i].end}
              onChange={(e) => {
                const w = dateChek(e.target.value);
                if (
                  w === "" ||
                  w === "Incomplete date" ||
                  w === "Date format is dd/mm"
                ) {
                  editTimes[i].end = e.target.value;
                  setEditTimes([...editTimes]);
                }
              }}
            >
              <div
                className="ui label"
                style={{ margin: 0, alignItems: "center", fontSize: "1em" }}
              >
                end date:
              </div>
              <input style={{ width: "55px" }} />
            </Input>
          </div>
        );
    }
  };
  const checkWarnings = (): boolean => {
    let res = true;
    let typwarn = !checkGlobalWarning();
    const warnArr = Array<string>(editTimes.length);
    const inOrderDaily = (s: string, e: string): boolean => {
      const st = s.split(":");
      const et = e.split(":");
      return !(
        parseInt(st[0]) > parseInt(et[0]) ||
        (parseInt(st[0]) === parseInt(et[0]) &&
          parseInt(st[1]) > parseInt(et[1]))
      );
    };
    const inOrderYearly = (s: string, e: string): boolean => {
      const st = s.split("/");
      const et = e.split("/");
      return !(
        parseInt(st[1]) > parseInt(et[1]) ||
        (parseInt(st[1]) === parseInt(et[1]) &&
          parseInt(st[0]) > parseInt(et[0]))
      );
    };
    editTimes.forEach((t, i) => {
      let warn = "";
      if (typwarn && t.type !== "daily") {
        warn = "Only one type of date is permitted";
      } else {
        const filtered = editTimes.filter(
          (f, j) => i !== j && f.type === t.type,
        );
        switch (t.type) {
          case "daily": {
            if (!inOrderDaily(t.start, t.end)) warn = "Wrong order";
            break;
          }
          case "yearly": {
            warn = dateChek(t.start) || dateChek(t.end);
            if (warn !== "") break;
            if (!inOrderYearly(t.start, t.end)) warn = "Wrong order";
            break;
          }
          case "monthly": {
            const start = parseInt(t.start);
            const end = parseInt(t.end);
            if (Number.isNaN(start) || Number.isNaN(end)) {
              warn = "Incorect input - must be a number";
            } else if (start > end) warn = "Wrong order";
            else if (start < 1 || start > 31 || end < 1 || end > 31)
              warn = "Incorect input - a number between 1 to 31";
            break;
          }
          case "weekly": {
            if (weekEnum[t.start.toUpperCase()] > weekEnum[t.end.toUpperCase()])
              warn = "Wrong order";
            break;
          }
        }
      }
      if (warn !== "") res = false;
      warnArr[i] = warn;
    });
    setWarnings([...warnArr]);
    return res;
  };

  return (
    <>
      <div style={{ marginBottom: 14 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <h3>Best fit at:</h3>
          <FaEdit
            style={{ width: 20, height: 30, marginLeft: 10, paddingBottom: 10 }}
            onClick={() => {
              times.length === 0
                ? setEditTimes([
                    {
                      type: "daily",
                      start: "00:00",
                      end: "23:59",
                      negative: false,
                    },
                  ])
                : setEditTimes(JSON.parse(JSON.stringify(times)));
              setModal(true);
            }}
          />
        </div>
        {times?.length > 0 ? (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}>Include/Exclude</Table.HeaderCell>
                <Table.HeaderCell>Period type</Table.HeaderCell>
                <Table.HeaderCell>Start</Table.HeaderCell>
                <Table.HeaderCell>End</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {times.map((t, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{t.negative ? "-" : "+"}</Table.Cell>
                  <Table.Cell>{t.type}</Table.Cell>
                  <Table.Cell>{t.start}</Table.Cell>
                  <Table.Cell>{t.end}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colspan="4" textAlign="center">
                  <b>
                    Available {percentToPresent(calcTimes(times))} of the year
                  </b>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        ) : (
          <p style={{ margin: 0 }}>all year round</p>
        )}
      </div>
      <Modal
        open={modal}
        onClose={closeModal}
        style={{ position: "absolute", top: 10 + "%" }}
      >
        <ModalHeader>Define times your klydo best fit</ModalHeader>
        <ModalContent>
          <div style={{ color: "red" }}>{globalWarning}</div>
          {editTimes?.map((t, i) => (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "12px",
                }}
              >
                {t.negative ? <FaMinus /> : <FaPlus />}
                <Select
                  value={editTimes[i].type}
                  onChange={(_e, d) => {
                    if (editTimes[i].type !== d.value) {
                      resetTypeInput(i, d.value as timeTypes);
                      editTimes[i].type = d.value as timeTypes;
                    }
                    checkGlobalWarning();
                    setEditTimes([...editTimes]);
                  }}
                  options={tTypes.map((t) => ({ value: t, text: t }))}
                ></Select>
                {inputType(i)}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                  }}
                >
                  <Checkbox
                    label="Do not show in this time"
                    checked={editTimes[i].negative}
                    onClick={() => {
                      editTimes[i].negative = !editTimes[i].negative;
                      setEditTimes([...editTimes]);
                    }}
                  />
                </div>
                <FaTrash
                  onClick={() => {
                    editTimes.splice(i, 1);
                    setEditTimes([...editTimes]);
                  }}
                />
              </div>
              <p style={{ marginLeft: 226, color: "red" }}>
                {warnings[i] ?? ""}
              </p>
            </>
          ))}
          <Button
            onClick={() => {
              editTimes.push({
                type: "daily",
                start: "00:00",
                end: "23:59",
                negative: false,
              });
              setEditTimes([...editTimes]);
            }}
          >
            Add time or date
          </Button>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Button onClick={closeModal}>Cancel</Button>
            <Button
              disabled={saving}
              onClick={() => {
                if (checkWarnings()) {
                  setSaving(true);
                  props.onSave(editTimes);
                  setTimes(JSON.parse(JSON.stringify(editTimes)));
                  closeModal();
                  setSaving(false);
                }
              }}
            >
              <div style={{ display: "flex" }}>
                <ThreeDots
                  height="20"
                  width="40"
                  radius="9"
                  color="#4fa94d"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  visible={saving}
                />
                Save
              </div>
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TimeControl;
