import { ChangeEvent, useEffect, useState } from "react";
import {
  Form,
  Input,
  InputOnChangeData,
  Message,
  Popup,
  Segment,
} from "semantic-ui-react";
import { LoginContainer, LoginContent } from "./loginStyles";
import { Link, useNavigate } from "react-router-dom";
import { Logo, LogoTagline } from "../components";
import "./registerForm.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import ReactFlagsSelect from "react-flags-select";
import { labelValueState, statesList } from "utils";
import { UserAvatar } from "themes-views/user/userStyles";
import img_placeholder from "./img_placeholder.png";
import firebaseService from "firebase_services/firebaseService";

const Login: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [photoUrl, setPhotoUrl] = useState<string | ArrayBuffer>();
  const [imgUrl, setImgUrl] = useState<string>();
  const [isImgAnimated, setIsImgAnimated] = useState(false);
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmUpdates, setConfirmUpdates] = useState(false);
  const [passwordError, setPasswordError] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorHeader, setErrorHeader] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [fieldsError, setFieldsError] = useState<{
    email?: string;
    password?: string;
    name?: string;
    country?: string;
    state?: string;
    photo?: boolean;
    passConfirm?: string;
  }>({});

  const handleEmailInputChange = (
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
  ): void => {
    const { value } = data;
    setFieldsError({ ...fieldsError, email: undefined });
    setSuccess(false);
    setEmail(value);
  };

  const handleFirstNameInputChange = (
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
  ): void => {
    const { value } = data;
    setFieldsError({ ...fieldsError, name: undefined });
    setSuccess(false);
    setFirstName(value);
  };

  const handleStateInputChange = (
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
  ): void => {
    const { value } = data;
    setFieldsError({ ...fieldsError, state: undefined });

    setSuccess(false);
    setState(value);
  };

  const handlePasswordInputChange = (
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
  ): void => {
    const { value } = data;
    setFieldsError({ ...fieldsError, password: undefined });
    setSuccess(false);
    setPassword(value);
  };

  const handleConfirmPasswordInputChange = (
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
  ): void => {
    const { value } = data;
    if (value.length && value != password) {
      setPasswordError("confirmation password does not match to password");
      return;
    }
    setPasswordError(undefined);
    setSuccess(false);
    setPasswordConfirmation(value);
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!checkValidation()) return;
    setLoading(true);
    setError(false);

    const obj: {
      country: string;
      name: string;
      photoUrl: string;
      email: string;
      state?: string;
      password: string;
    } = {
      country: country,
      name: firstName,
      photoUrl: imgUrl,
      email: email,
      password: password,
    };
    if (state) obj.state = state;
    firebaseService
      .createNewUser(obj, isImgAnimated)
      .then((u) => {
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(() => {
            setLoading(false);
            if (confirmUpdates)
              firebaseService.confirmUpdates(email, firstName).catch((e) => {});
            navigate("/");
          })
          .catch(() => {
            setError(true);
            setErrorHeader("Error");
            setErrorMessage("login failed");
          });
      })
      .catch((e) => {
        console.log(e);

        setError(true);
        setErrorHeader("Error");
        setErrorMessage(e.message);
      });
  }

  const checkValidation = () => {
    let valid = true;
    const obj: {
      email?: string;
      password?: string;
      name?: string;
      country?: string;
      state?: string;
      photo?: boolean;
      passConfirm?: string;
    } = {};
    if (
      !email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
    ) {
      valid = false;
      obj.email = "invalid email";
    }
    if (password.length < 6) {
      valid = false;
      obj.password = "minimum password length 6 characters";
    }
    if (!firstName.length) {
      valid = false;
      obj.name = "Name is required";
    }
    if (!country) {
      valid = false;
      obj.country = "Country is required";
    }
    if (country && country == "US") {
      if (!state) {
        obj.state = "State is required";
        valid = false;
      } else if (labelValueState(state) == "not found") {
        valid = false;
        obj.state = "state is invalid";
      }
    }
    if (!imgUrl) {
      valid = false;
      obj.photo = true;
    }
    if (!passwordConfirmation) {
      valid = false;
      obj.passConfirm = "Please confirm your password";
    }

    setFieldsError(obj);
    return valid;
  };

  useEffect((): void => {
    if (success) {
      setEmail("");
      setPassword("");
      setPasswordConfirmation("");
    }
  }, [success, loading, error]);

  const handleDismiss = (): void => {
    setError(false);
    setSuccess(false);
    setLoading(false);
  };

  return (
    <LoginContainer>
      <LoginContent>
        <div>
          <Logo />
          <LogoTagline
            as="h3"
            style={{ fontSize: "2em", marginTop: "1em", marginBottom: "-1em" }}
          >
            Register for Klydo account
          </LogoTagline>
          <Segment
            inverted
            style={{ backgroundColor: "transparent", marginTop: "3em" }}
          >
            <Popup
              size="mini"
              position="bottom center"
              style={{
                color: "#9f3a38",
                borderColor: "#9f3a38",
                fontWeight: 700,
              }}
              open={fieldsError.photo}
              content="profile image is required"
              trigger={
                <div
                  style={{
                    width: 120,
                    height: 120,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <span style={{ position: "absolute" }}>
                    <UserAvatar
                      style={{ height: 120, width: 120 }}
                      src={photoUrl || img_placeholder}
                      size="small"
                      circular
                      referrerPolicy="no-referrer"
                    />
                  </span>
                  <Input
                    id="user-photo"
                    onChange={(e) => {
                      setFieldsError({ ...fieldsError, photo: false });
                      const fr2 = new FileReader();
                      const fr = new FileReader();
                      fr.onload = function () {
                        setPhotoUrl(fr.result);
                      };
                      fr2.onload = function () {
                        setTimeout(() => {
                          const url =
                            "https://api.cloudinary.com/v1_1/KlydoClock/auto/upload";
                          const formData = new FormData();
                          formData.append("file", file);
                          formData.append("folder", "profile");
                          formData.append("upload_preset", "profiles");
                          return fetch(url, {
                            method: "POST",
                            body: formData,
                          })
                            .then((response) => {
                              return response.json();
                            })
                            .then((res) => {
                              const img = res.secure_url;
                              if (img) {
                                setImgUrl(img);
                                setIsImgAnimated(
                                  file.type == "image/gif" ||
                                    (file.type == "image/webp" &&
                                      fr2.result.toString().indexOf("ANMF") !=
                                        -1),
                                );
                                // firebaseService.updateUserProfile({ fb: "photoUrl", user: "photoURL", value: img })
                              }
                            })
                            .catch((e) => {
                              console.log(e);
                              setPhotoUrl(undefined);
                            });
                        }, 0);
                      };
                      const file = e.target.files[0];
                      if (!file) return;
                      fr.readAsDataURL(file);
                      fr2.readAsText(file);
                    }}
                    type="file"
                    style={{ width: 100 + "%", height: 100 + "%", opacity: 0 }}
                  />
                </div>
              }
            />

            <Form size="large" className="register" onSubmit={handleSubmit}>
              <Form.Input
                type="email"
                fluid
                icon="user"
                label="Your Email"
                iconPosition="left"
                placeholder="E-mail address"
                onChange={handleEmailInputChange}
                error={fieldsError.email}
                required
              />
              <Form.Input
                required
                fluid
                icon="user"
                label="Name"
                iconPosition="left"
                placeholder="Name"
                onChange={handleFirstNameInputChange}
                error={fieldsError.name}
              />
              <p style={{ fontWeight: 600 }}>
                Country <span style={{ color: "red" }}>*</span>
              </p>
              <Popup
                position="bottom center"
                size="mini"
                style={{
                  color: "#9f3a38",
                  borderColor: "#9f3a38",
                  fontWeight: 700,
                }}
                content={fieldsError.country}
                open={fieldsError.country != undefined}
                trigger={
                  <ReactFlagsSelect
                    className={
                      !country || country == ""
                        ? "flag-select"
                        : "flag-select-after"
                    }
                    placeholder="Country"
                    selected={country}
                    searchable
                    onSelect={(code) => {
                      setFieldsError({ ...fieldsError, country: undefined });
                      setCountry(code);
                    }}
                  />
                }
              />

              {country && country == "US" && (
                <div>
                  <Form.Input
                    required={country && country == "US"}
                    fluid
                    list="list"
                    icon="map marker alternate"
                    label="State"
                    iconPosition="left"
                    placeholder="State"
                    onChange={handleStateInputChange}
                    error={fieldsError.state}
                  />
                  <datalist id="list">
                    {statesList.map((v) => (
                      <option key={v.value} value={v.label}>
                        {v.label}
                      </option>
                    ))}
                  </datalist>
                </div>
              )}
              <Form.Input
                required
                fluid
                icon="lock"
                label="Desired Password"
                iconPosition="left"
                placeholder="Password"
                type="password"
                onChange={handlePasswordInputChange}
              />
              <Form.Input
                required
                fluid
                icon="lock"
                iconPosition="left"
                label="Confirm Password"
                placeholder="Confirm Password"
                type="password"
                onChange={handleConfirmPasswordInputChange}
                error={passwordError || fieldsError.passConfirm}
              />
              <Form.Checkbox
                onChange={(e, data) => setConfirmUpdates(data.checked)}
                label="I accept to receiving updates"
              />
              <Form.Button
                color="instagram"
                type="submit"
                disabled={!email || !password}
                fluid
                size="large"
                loading={loading}
              >
                Register
              </Form.Button>
              <div style={{ textAlign: "center", width: "100%" }}>
                {" "}
                <Link to="/" style={{ color: "white" }}>
                  Return to Login!
                </Link>
              </div>
            </Form>
            {error && (
              <Message
                error
                icon="warning circle"
                header={errorHeader}
                content={errorMessage}
                onDismiss={handleDismiss}
                style={{
                  top: 510,
                  position: "absolute",
                  boxShadow: "5px 17px 17px rgba(0,0,0,0.35)",
                }}
              />
            )}
            {success && (
              <Message
                info
                icon="info circle"
                header="Verification email sent"
                content={`Please check your email: ${email} to confirm your account`}
                onDismiss={handleDismiss}
                style={{ position: "absolute" }}
              />
            )}
          </Segment>
        </div>
      </LoginContent>
    </LoginContainer>
  );
};

export default Login;
