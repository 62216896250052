import { Link, useTheme } from "@mui/material";
import DSBox from "../system-design/DSBox";
import DSTypography from "../system-design/DSTypography";
import { useGiftContext } from "./Context/GiftContextProvider";
import GiftView from "./GiftView";
import DSPaper from "../system-design/DSPaper";
import logo from "./assets/logo-05-05.png";
import { isIOS, isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import DSSwitch from "../system-design/DSSwitch";

const ShopifyProduct = () => {
  useEffect(() => {
    const scriptURL =
      "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";

    const shopifyBuyInit = () => {
      const isInitialized = !!document.getElementById(
        "product-component-1730996882575",
      )?.childNodes?.length;
      if (isInitialized) return;

      // @ts-expect-error shopify buy button
      const client = window.ShopifyBuy.buildClient({
        domain: "klydo-clock.myshopify.com",
        storefrontAccessToken: "600122e52a9023f56c762e13e0fe0846",
      });

      // @ts-expect-error shopify buy button
      window.ShopifyBuy.UI.onReady(client).then((ui) => {
        ui.createComponent("product", {
          id: "8623837708573",
          node: document.getElementById("product-component-1730996882575"),
          moneyFormat: "%24%7B%7Bamount%7D%7D",
          options: {
            product: {
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "calc(25% - 20px)",
                    "margin-left": "20px",
                    "margin-bottom": "50px",
                  },
                },
                button: {
                  "font-weight": "bold",
                  color: "#000000",
                  ":hover": { color: "#000000", "background-color": "#e6c241" },
                  "background-color": "#ffd848",
                  ":focus": { "background-color": "#e6c241" },
                  "border-radius": "40px",
                },
              },
              buttonDestination: "checkout",
              events: {
                click: function (checkoutUrl) {
                  console.log("checkoutUrl", checkoutUrl);

                  // You can send this token to your server for tracking
                  // Example: fetch('/track-checkout', { method: 'POST', body: JSON.stringify({ token }) })
                },
              },
              contents: {
                img: false,
                button: false,
                buttonWithQuantity: true,
                title: false,
                price: false,
              },
              text: { button: "Buy now" },
            },
            productSet: {
              styles: {
                products: {
                  "@media (min-width: 601px)": {
                    "margin-left": "-20px",
                  },
                },
              },
            },
            modalProduct: {
              contents: {
                img: false,
                imgWithCarousel: true,
                button: false,
                buttonWithQuantity: true,
              },
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0px",
                    "margin-bottom": "0px",
                  },
                },
                button: {
                  "font-weight": "bold",
                  color: "#000000",
                  ":hover": { color: "#000000", "background-color": "#e6c241" },
                  "background-color": "#ffd848",
                  ":focus": { "background-color": "#e6c241" },
                  "border-radius": "40px",
                },
              },
              text: { button: "Add to cart" },
            },
            option: {
              styles: {
                label: {
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "bold",
                  color: "#ffffff",
                },
                select: {
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "bold",
                },
              },
              googleFonts: ["Montserrat"],
            },
            cart: {
              styles: {
                button: {
                  "font-weight": "bold",
                  color: "#000000",
                  ":hover": { color: "#000000", "background-color": "#e6c241" },
                  "background-color": "#ffd848",
                  ":focus": { "background-color": "#e6c241" },
                  "border-radius": "40px",
                },
              },
              text: { total: "Subtotal", button: "Checkout" },
              popup: false,
            },
            toggle: {
              styles: {
                toggle: {
                  "font-weight": "bold",
                  "background-color": "#ffd848",
                  ":hover": { "background-color": "#e6c241" },
                  ":focus": { "background-color": "#e6c241" },
                },
                count: { color: "#000000", ":hover": { color: "#000000" } },
                iconPath: { fill: "#000000" },
              },
            },
          },
        });
      });
    };

    const loadScript = () => {
      const script = document.createElement("script");
      script.src = scriptURL;
      script.async = true;
      script.onload = shopifyBuyInit;
      document.body.appendChild(script);
    };

    // @ts-expect-error shopify buy button
    if (window.ShopifyBuy) {
      // @ts-expect-error shopify buy button
      if (window.ShopifyBuy.UI) {
        shopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
  }, []);

  return <div id="product-component-1730996882575"></div>;
};

const GiftDone = () => {
  const theme = useTheme();
  const { klydoGiftProps, selectedKlydoGreet } = useGiftContext();
  const [showGreeting, setShowGreeting] = useState(false);
  const handleToggle = (event) => {
    setShowGreeting(event.target.checked);
  };
  const fromShopify = klydoGiftProps?.orderId === "shopify-redirect";

  // After the shopify buy button is ready, do it better
  if (fromShopify) {
    return (
      <DSPaper
        style={{
          height: "100vh",
          overflow: "scroll",
        }}
      >
        <DSBox
          color="transparent"
          sx={{
            backgroundColor: theme.palette.background.paper,
            paddingY: 4,
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            boxShadow: "none",
          }}
        >
          <img width={isMobile ? "120" : "150"} src={logo} />
        </DSBox>
        <DSBox
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            backgroundColor: theme.palette.background.paper,
            height: "100vh",
          }}
        >
          <DSBox
            gap={isMobile ? 0.5 : 20}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row-reverse" },
              paddingTop: { md: 8 },
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <DSBox
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                paddingTop: { md: 2 },
              }}
            >
              <DSBox>
                <DSTypography
                  lineHeight={1}
                  fontStyle={"bold"}
                  fontSize={47.52}
                  fontWeight={400}
                  fontFamily={"Gilroy-Bold"}
                >
                  Your KlydoGreet
                </DSTypography>

                <DSTypography
                  lineHeight={1}
                  fontStyle={"bold"}
                  fontSize={47.52}
                  fontWeight={400}
                  fontFamily={"Gilroy-Bold"}
                >
                  is ready!
                </DSTypography>
              </DSBox>
              <DSBox py={1} />
              {!fromShopify ? (
                <>
                  <DSBox sx={{ paddingBottom: { xs: 2 } }}>
                    <DSTypography
                      fontSize={18}
                      fontWeight={400}
                      fontFamily={"Gilroy-Medium"}
                    >
                      Your personalized gift is
                    </DSTypography>
                    <DSTypography
                      fontSize={18}
                      fontWeight={400}
                      fontFamily={"Gilroy-Medium"}
                    >
                      ready! We can't wait to hear
                    </DSTypography>
                    <DSTypography
                      fontSize={18}
                      fontWeight={400}
                      fontFamily={"Gilroy-Medium"}
                    >
                      all about their reaction!
                    </DSTypography>
                  </DSBox>
                  {!isMobile && (
                    <DSBox>
                      <DSTypography
                        mb={1}
                        fontSize={15}
                        fontWeight={400}
                        fontFamily={"Roboto-Regular"}
                      >
                        Have questions or something to share?
                      </DSTypography>
                      <DSTypography
                        mb={1}
                        fontSize={15}
                        fontWeight={400}
                        fontFamily={"Roboto-Regular"}
                      >
                        We'd love to hear from you!
                      </DSTypography>
                      <DSTypography
                        mb={1}
                        fontSize={15}
                        fontWeight={400}
                        fontFamily={"Roboto-Regular"}
                      >
                        <Link
                          color="inherit"
                          href="https://www.klydoclock.com/pages/contact-us"
                        >
                          Contact Us
                        </Link>
                      </DSTypography>
                    </DSBox>
                  )}
                </>
              ) : (
                <ShopifyProduct />
              )}
            </DSBox>
            <DSBox
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: isIOS && isMobile ? "250px" : "290px",
                  height: isIOS && isMobile ? "420px" : "483px",
                  position: "relative",
                }}
              >
                <GiftView
                  gift={klydoGiftProps}
                  klydo={selectedKlydoGreet}
                  isToggled={showGreeting}
                />
              </div>
              <DSBox
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <DSTypography
                  fontWeight={400}
                  fontFamily={"Gilroy-SemiBold"}
                  fontSize={"16px"}
                  color={showGreeting ? "#A5A5A5" : "#FFFFFF"}
                >
                  Greet
                </DSTypography>

                <DSSwitch checked={showGreeting} onChange={handleToggle} />

                <DSTypography
                  fontWeight={400}
                  fontFamily={"Gilroy-SemiBold"}
                  fontSize={"16px"}
                  color={showGreeting ? "#FFFFFF" : "#A5A5A5"}
                >
                  Message
                </DSTypography>
              </DSBox>
            </DSBox>

            <DSBox py={1} />
            {isMobile && (
              <DSBox>
                <DSTypography
                  mb={1}
                  fontSize={15}
                  fontWeight={400}
                  fontFamily={"Gilroy-light"}
                >
                  Have questions or something to share?
                </DSTypography>
                <DSTypography
                  mb={1}
                  fontSize={15}
                  fontWeight={400}
                  fontFamily={"Gilroy-light"}
                >
                  We'd love to hear from you!
                </DSTypography>
                <DSTypography
                  mb={1}
                  fontSize={15}
                  fontWeight={400}
                  fontFamily={"Gilroy-light"}
                >
                  <Link
                    color="inherit"
                    href="https://www.klydoclock.com/pages/contact-us"
                  >
                    Contact Us
                  </Link>
                </DSTypography>
              </DSBox>
            )}
          </DSBox>
        </DSBox>
      </DSPaper>
    );
  }
  return (
    <DSPaper
      style={{
        height: "100vh",
        overflow: "scroll",
      }}
    >
      <DSBox
        color="transparent"
        sx={{
          backgroundColor: theme.palette.background.paper,
          paddingY: 4,
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          boxShadow: "none",
        }}
      >
        <img width={isMobile ? "120" : "150"} src={logo} />
      </DSBox>
      <DSBox
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          backgroundColor: theme.palette.background.paper,
          height: "100vh",
        }}
      >
        <DSBox
          gap={isMobile ? 0.5 : 20}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row-reverse" },
            paddingTop: { md: 8 },
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <DSBox
            sx={{
              flexDirection: "column",
              paddingTop: { md: 2 },
            }}
          >
            <DSBox>
              <DSTypography
                lineHeight={1}
                fontStyle={"bold"}
                fontSize={47.52}
                fontWeight={400}
                fontFamily={"Gilroy-Bold"}
              >
                Your KlydoGreet
              </DSTypography>

              <DSTypography
                lineHeight={1}
                fontStyle={"bold"}
                fontSize={47.52}
                fontWeight={400}
                fontFamily={"Gilroy-Bold"}
              >
                is ready!
              </DSTypography>
            </DSBox>
            <DSBox py={1} />
            <DSBox sx={{ paddingBottom: { xs: 2 } }}>
              <DSTypography
                fontSize={18}
                fontWeight={400}
                fontFamily={"Gilroy-Medium"}
              >
                Your personalized gift is
              </DSTypography>
              <DSTypography
                fontSize={18}
                fontWeight={400}
                fontFamily={"Gilroy-Medium"}
              >
                ready! We can't wait to hear
              </DSTypography>
              <DSTypography
                fontSize={18}
                fontWeight={400}
                fontFamily={"Gilroy-Medium"}
              >
                all about their reaction!
              </DSTypography>
            </DSBox>
            {!isMobile && (
              <DSBox>
                <DSTypography
                  mb={1}
                  fontSize={15}
                  fontWeight={400}
                  fontFamily={"Roboto-Regular"}
                >
                  Have questions or something to share?
                </DSTypography>
                <DSTypography
                  mb={1}
                  fontSize={15}
                  fontWeight={400}
                  fontFamily={"Roboto-Regular"}
                >
                  We'd love to hear from you!
                </DSTypography>
                <DSTypography
                  mb={1}
                  fontSize={15}
                  fontWeight={400}
                  fontFamily={"Roboto-Regular"}
                >
                  <Link
                    color="inherit"
                    href="https://www.klydoclock.com/pages/contact-us"
                  >
                    Contact Us
                  </Link>
                </DSTypography>
              </DSBox>
            )}
          </DSBox>
          <DSBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: isIOS && isMobile ? "250px" : "290px",
                height: isIOS && isMobile ? "420px" : "483px",
                position: "relative",
              }}
            >
              <GiftView
                gift={klydoGiftProps}
                klydo={selectedKlydoGreet}
                isToggled={showGreeting}
              />
            </div>
            <DSBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                width: "100%",
              }}
            >
              <DSTypography
                fontWeight={400}
                fontFamily={"Gilroy-SemiBold"}
                fontSize={"16px"}
                color={showGreeting ? "#A5A5A5" : "#FFFFFF"}
              >
                Greet
              </DSTypography>

              <DSSwitch checked={showGreeting} onChange={handleToggle} />

              <DSTypography
                fontWeight={400}
                fontFamily={"Gilroy-SemiBold"}
                fontSize={"16px"}
                color={showGreeting ? "#FFFFFF" : "#A5A5A5"}
              >
                Message
              </DSTypography>
            </DSBox>
          </DSBox>

          <DSBox py={1} />
          {isMobile && (
            <DSBox>
              <DSTypography
                mb={1}
                fontSize={15}
                fontWeight={400}
                fontFamily={"Gilroy-light"}
              >
                Have questions or something to share?
              </DSTypography>
              <DSTypography
                mb={1}
                fontSize={15}
                fontWeight={400}
                fontFamily={"Gilroy-light"}
              >
                We'd love to hear from you!
              </DSTypography>
              <DSTypography
                mb={1}
                fontSize={15}
                fontWeight={400}
                fontFamily={"Gilroy-light"}
              >
                <Link
                  color="inherit"
                  href="https://www.klydoclock.com/pages/contact-us"
                >
                  Contact Us
                </Link>
              </DSTypography>
            </DSBox>
          )}
        </DSBox>
      </DSBox>
    </DSPaper>
  );
};
export default GiftDone;
