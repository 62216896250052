import firebaseConfig from "./firebaseServiceConfig";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import {
  Klydo,
  Profile,
  ReviewHistory,
  SocialMedia,
  Theme,
} from "context/klydo/KlydoTypes";

export class FirebaseService {
  db: firebase.firestore.Firestore;
  storage: firebase.storage.Storage;
  functions: firebase.functions.Functions;
  private auth: firebase.auth.Auth;

  listeners = new Map<string, (d: any) => void>();

  constructor() {
    firebase.initializeApp(firebaseConfig);
    this.db = firebase.firestore();
    this.storage = firebase.storage();
    this.functions = firebase.app().functions("us-central1");
    this.auth = firebase.auth();
  }

  public getToken() {
    return this.auth.currentUser?.getIdToken();
  }

  public getUserId() {
    return this.auth.currentUser?.uid;
  }

  public signOut() {
    return this.auth.signOut();
  }

  addListener(k: string, listener: (d: any) => void) {
    this.listeners.set(k, listener);
  }
  removeListener(k: string) {
    this.listeners.delete(k);
  }

  private obj2paths(
    key: string,
    obj: Partial<Klydo> | { theme: Partial<Theme> },
  ): Array<string> {
    const res = Array<string>();
    const keys = Object.keys(obj);
    for (let k of keys) {
      if (k === "createdAt" || k === "date" || k === "times") {
        res.push((key ? key + "." : "") + k);
      } else if (typeof obj[k] == "object") {
        const r = this.obj2paths(k, obj[k]);
        for (let t of r) res.push((key ? key + "." : "") + t);
      } else {
        res.push((key ? key + "." : "") + k);
      }
    }
    return res;
  }
  updatorData: Partial<Klydo> | { theme: Partial<Theme> } = {};
  updator: any;
  updateKlydo(
    id: string,
    userId: string,
    klydo: Partial<Klydo> | { theme: Partial<Theme> },
    cb: () => void,
  ) {
    this.updatorData = { ...this.updatorData, ...klydo };
    clearTimeout(this.updator);
    this.updator = setTimeout(async () => {
      const fields = this.obj2paths("", this.updatorData);
      for (let k of Object.keys(this.updatorData)) {
        if (this.updatorData[k] === undefined)
          this.updatorData[k] = firebase.firestore.FieldValue.delete();
      }
      await this.db
        .collection("users")
        .doc(userId === "" ? firebase.auth().currentUser.uid : userId)
        .collection("my_klydos")
        .doc(id)
        .set(this.updatorData, { mergeFields: fields });
      this.updatorData = {};
      cb();
    }, 100);
  }

  updateGif = async (
    url: string,
    userId: string,
    id: string,
    isPublic: boolean,
  ) => {
    const usedUser = !userId.length ? firebase.auth().currentUser.uid : userId;
    const obj: any = {
      review: firebase.firestore.FieldValue.delete(),
      result: firebase.firestore.FieldValue.delete(),
      edited: isPublic,
      loopUrl: url,
      zip: false,
    };
    await this.db
      .collection("users")
      .doc(usedUser)
      .collection("my_klydos")
      .doc(id)
      .set(obj, { merge: true });
  };

  updateSplitedGif = async (
    userId: string,
    id: string,
    crop?: { zoom: number; left: number; top: number },
  ) => {
    try {
      const usedUser = userId.length ? userId : firebase.auth().currentUser.uid;
      const doc = await this.db
        .collection("users")
        .doc(usedUser)
        .collection("my_klydos")
        .doc(id)
        .get();
      const data = doc.data();

      if (!data) {
        throw new Error("No data found for specified ID");
      }

      await this.functions.httpsCallable("splitGifBridge")({
        url: data.loopUrl,
        id: usedUser,
        klydo: id,
        crop: crop,
      });
    } catch (error) {
      console.error("Error updating split GIF:", error);
      throw error;
    }
  };

  saveKlydo = async (id: string, klydo: Partial<Klydo>, userId?: string) => {
    const usedUser = userId || firebase.auth().currentUser?.uid;
    if (!usedUser) {
      throw new Error("User ID is required but not provided");
    }
    await this.db
      .collection("users")
      .doc(usedUser)
      .collection("my_klydos")
      .doc(id)
      .set(klydo, { merge: true });
  };

  klydosList = Array<Klydo>();
  userProfile: Profile;

  statsListener: () => void = null;
  async generateData(user: any) {
    this.klydosList = [];
    let needStatListen = true;

    return new Promise<void>((v, x) => {
      let jobs: any = {};
      type Job = "profile" | "klydos" | "oneKlydo";
      function start(job: Job) {
        jobs[job] = false;
      }
      const done = (job: Job) => {
        jobs[job] = true;
        if (!Object.entries(jobs).filter((i) => !i[1]).length) v();
      };
      start("profile");
      this.db
        .collection("users")
        .doc(firebase.auth().currentUser.uid)
        .onSnapshot((snap) => {
          this.userProfile = { ...snap.data(), id: snap.id };
          if (snap.data()?.clock)
            this.getClockFriendlyId(snap.data().clock)
              .then((r) => (this.userProfile.friendlyClock = r.data))
              .catch(console.log);
          if (this.listeners.has("profile")) {
            this.listeners.get("profile")(this.userProfile);
          }
          done("profile");
        });
      start("klydos");
      this.db
        .collection("users")
        .doc(firebase.auth().currentUser.uid)
        .collection("my_klydos")
        .onSnapshot(
          (snapshot) => {
            snapshot.docChanges().forEach((change) => {
              const doc = change.doc;
              switch (change.type) {
                case "added":
                  this.klydosList.push({
                    ...doc.data(),
                    id: doc.id,
                    userId: doc.ref.parent.parent.id,
                  } as Klydo);
                  break;
                case "modified":
                  const index = this.klydosList.findIndex(
                    (kl) => kl.id === doc.id,
                  );
                  this.klydosList[index] = {
                    ...doc.data(),
                    id: doc.id,
                    userId: doc.ref.parent.parent.id,
                  } as Klydo;
                  break;
                case "removed":
                  this.klydosList = this.klydosList.filter(
                    (k) => k.id !== doc.id,
                  );
                  this.statsListener();
                  needStatListen = true;
                  break;
              }
            });
            this.klydosList = this.klydosList.sort(
              (a, b) => b.createdAt?.toDate() - a.createdAt?.toDate(),
            );
            if (this.listeners.has("my_klydos"))
              this.listeners.get("my_klydos")!(this.klydosList);

            if (needStatListen) {
              if (!this.klydosList.length) {
                done("klydos");
                return;
              }

              if (
                user.role == "admin" &&
                RegExp(
                  "^/klydo/(?:{{0,1}(?:[0-9a-f]){8}-(?:[0-9a-f]){4}-(?:[0-9a-f]){4}-(?:[0-9a-f]){4}-(?:[0-9a-f]){12}}{0,1})$",
                ).test(window.location.pathname)
              ) {
                start("oneKlydo");
                if (
                  !this.klydosList.find(
                    (k) => k.id == window.location.pathname.substring(7),
                  )
                )
                  this.db
                    .collectionGroup("my_klydos")
                    .get()
                    .then((docs) => {
                      const doc = docs.docs.find(
                        (d) => d.id == window.location.pathname.substring(7),
                      );
                      this.allKlydos.push({
                        ...doc.data(),
                        id: doc.id,
                        userId: doc.ref.parent.parent.id,
                      } as Klydo);
                      done("oneKlydo");
                    });
                else done("oneKlydo");
              }

              needStatListen = false;
              this.statsListener = this.db
                .collection("stats")
                .where(
                  firebase.firestore.FieldPath.documentId(),
                  "in",
                  this.klydosList.map((k) => k.id),
                )
                .onSnapshot(
                  (snashot) => {
                    snashot.docs.forEach((stat) => {
                      const klydo = this.klydosList.find(
                        (mk) => mk.id === stat.id,
                      );
                      klydo.time = stat.data().time;
                      klydo.fav = stat.data().favs;
                    });
                    if (this.listeners.has("my_klydos"))
                      this.listeners.get("my_klydos")!(this.klydosList);
                    done("klydos");
                  },
                  (error) => {
                    console.log(error);
                    done("klydos");
                  },
                  () => done("klydos"),
                );
            }
          },
          (error) => console.log(error),
        );
    });
  }

  deleteKlydo = async (klydoId, userId?: string) => {
    return new Promise((v, x) => {
      let obj: { klydo: string; uid?: string } = { klydo: klydoId };
      if (userId) obj.uid = userId;
      this.functions
        .httpsCallable("deleteReviewCollection")(obj)
        .then(v)
        .catch(x);
    });
  };

  allKlydos = Array<Klydo>();
  users = Array<any>();
  adminAllKlydos = async () => {
    this.allKlydos = [];
    return new Promise<void>((v, x) => {
      let initStats = true;

      this.db.collectionGroup("my_klydos").onSnapshot(
        (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const doc = change.doc;
            switch (change.type) {
              case "added":
                this.allKlydos.push({
                  ...doc.data(),
                  id: doc.id,
                  userId: doc.ref.parent.parent.id,
                } as Klydo);
                break;
              case "modified":
                const index = this.allKlydos.findIndex(
                  (kl) => kl.id === doc.id,
                );
                this.allKlydos[index] = {
                  ...doc.data(),
                  id: doc.id,
                  userId: doc.ref.parent.parent.id,
                } as Klydo;
                break;
              case "removed":
                this.allKlydos = this.allKlydos.filter((k) => k.id !== doc.id);
                break;
            }
          });
          this.allKlydos = this.allKlydos.sort(
            (a, b) => b.createdAt?.toDate() - a.createdAt?.toDate(),
          );
          if (this.listeners.has("admin_klydos")) {
            this.listeners.get("admin_klydos")!(this.allKlydos);
          }

          if (initStats) {
            initStats = false;
            this.db.collection("stats").onSnapshot(
              (snashot) => {
                snashot.docs.forEach((stat) => {
                  const klydo = this.allKlydos.find((mk) => mk.id === stat.id);
                  if (klydo) {
                    klydo.time = stat.data().time;
                    klydo.fav = stat.data().favs;
                  }
                });
                if (this.listeners.has("admin_klydos"))
                  this.listeners.get("admin_klydos")!(this.allKlydos);
                v();
              },
              (error) => {
                console.log(error);
                x();
              },
            );
          }
        },
        (error) => console.log(error),
      );
    });
  };

  adminUsersList = async () => {
    const d = await this.functions.httpsCallable("getUsers")();
    this.users = d.data;
  };

  adminCreateNewUser = (
    email: string,
    password: string,
    socialMedia: SocialMedia[],
    photoUrl?: string,
    name?: string,
    country?: string,
    state?: string,
    description?: string,
  ): Promise<string> => {
    return new Promise((v: any, x) => {
      this.functions
        .httpsCallable("addNewUserDetails")({
          email: email,
          password: password,
          socialMedia: socialMedia,
          name: name ? name : "new user",
          role: "editor",
          country: country,
          state: state,
          description: description,
          pic: photoUrl,
        })
        .then((user: any) => v(user.data.uid))
        .catch(x);
    });
  };

  adminSetGifs = (
    userId: string,
    loopUrl: string,
    id: string,
    name: string,
    theme: Theme,
  ) => {
    return new Promise((v, x) => {
      this.db
        .collection("users")
        .doc(userId)
        .collection("my_klydos")
        .doc(id)
        .set(
          {
            loopUrl: loopUrl,
            zip: false,
            theme: theme,
            name: name,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true },
        )
        .then(() => {
          this.functions
            .httpsCallable(
              "splitGifBridge",
            )({ url: loopUrl, klydo: id, id: userId })
            .then(v)
            .catch(x);
        })
        .catch(x);
    });
  };

  adminSendToReview = (userId: string, ids: Array<string>) => {
    return new Promise((v, x) => {
      const ref = this.db
        .collection("users")
        .doc(userId)
        .collection("my_klydos");
      Promise.all(
        ids.map((id) =>
          ref.doc(id).set(
            {
              review: {
                date: firebase.firestore.FieldValue.serverTimestamp(),
                type: "edit",
              },
            },
            { merge: true },
          ),
        ),
      )
        .then(v)
        .catch(x);
    });
  };

  adminDeleteKlydo = (userId: string, id: string) => {
    return new Promise((v, x) => {
      this.db
        .collection("users")
        .doc(userId)
        .collection("my_klydos")
        .doc(id)
        .delete()
        .then(v)
        .catch(x);
    });
  };

  createNewUser = (
    user: {
      country: string;
      name: string;
      photoUrl: string;
      email: string;
      state?: string;
      password: string;
    },
    isAnimatedPhoto: boolean = false,
  ) => {
    return new Promise<void>((v, x) => {
      this.functions
        .httpsCallable("createUserEditor")(user)
        .then(() => {
          if (isAnimatedPhoto) {
            this.functions
              .httpsCallable("splitGifBridge")({
                url: user.photoUrl,
                artist: true,
              })
              .then(() => v());
          } else v();
        })
        .catch(x);
    });
  };

  confirmUpdates = (email: string, name: string) => {
    return new Promise((v, x) => {
      this.functions
        .httpsCallable("editorNews")({ email: email, name: name })
        .then(v)
        .catch(x);
    });
  };

  updateUserAnimatedPhoto = async (
    url: string,
    data: { fb: string; user: string | null; value: any },
    updateUser: boolean = true,
  ) => {
    try {
      this.updateUserProfile(data, updateUser).then(() => {
        this.functions.httpsCallable("splitGifBridge")({
          url: url,
          artist: true,
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  updateUserProfile = async (
    data: { fb: string; user: string | null; value: any },
    updateUser: boolean = true,
  ) => {
    try {
      await this.functions.httpsCallable("updateUser")(data);
    } catch (e) {
      console.log(e);
    }
  };

  search = async (collection: string, field, text: string) => {
    this.db.collection(collection).get();
  };
  revert = async (klydo: Klydo, userId?: string): Promise<Klydo> => {
    const uid = userId ?? firebase.auth().currentUser.uid;
    const pKlydo = await this.db.collection("klydos").doc(klydo.id).get();
    const theme = pKlydo.get("theme");
    const name = pKlydo.get("name");
    const url = pKlydo.get("loopUrl");
    const times = pKlydo.get("times");
    await this.db
      .collection("users")
      .doc(uid)
      .collection("my_klydos")
      .doc(klydo.id)
      .update({
        edited: false,
        theme: theme,
        review: firebase.firestore.FieldValue.delete(),
        loopUrl: url,
        name: name,
        times: times ?? firebase.firestore.FieldValue.delete(),
        zip: true,
      });
    return {
      ...klydo,
      edited: false,
      theme: theme,
      name: name,
      loopUrl: url,
      times: times,
    };
  };
  loadHistory = async (
    klydo: Klydo,
    userId?: string,
  ): Promise<Array<ReviewHistory>> => {
    const uid = userId ?? firebase.auth().currentUser.uid;
    const revs = await this.db
      .collection("users")
      .doc(uid)
      .collection("my_klydos")
      .doc(klydo.id)
      .collection("reviews")
      .get();
    return revs.docs.map((d) => {
      const dd = d.data();
      return {
        msg: dd.msg,
        started: new Date(dd.started.seconds * 1000),
        ended: new Date(dd.ended.seconds * 1000),
        approved: dd.approved,
        type: dd.type,
      };
    });
  };

  moveKlydoToUser = async (fromUid: string, toUid, klydoId: string) => {
    const klydoRef = this.db
      .collection("users")
      .doc(fromUid)
      .collection("my_klydos")
      .doc(klydoId);
    const doc = await klydoRef.get();
    const batch = this.db.batch();
    batch.set(
      this.db
        .collection("users")
        .doc(toUid)
        .collection("my_klydos")
        .doc(klydoId),
      { ...doc.data() },
      { merge: true },
    );
    batch.delete(klydoRef);
    await batch.commit();
  };

  updateClock = async (clockId) => {
    try {
      await this.functions.httpsCallable("updateEditorClock")({
        clock: clockId,
      });
    } catch (e: any) {
      throw new Error(e.message);
    }
  };

  disconnectClock = async () => {
    try {
      await this.functions.httpsCallable("disconnectClock")();
    } catch (e: any) {
      throw new Error(e.message);
    }
  };

  getClockFriendlyId = async (id: string) => {
    try {
      return await this.functions.httpsCallable("getClockFriendlyId")({
        uid: id,
      });
    } catch (e) {
      console.log(e);
    }
  };
}

const firebaseService = new FirebaseService();

export default firebaseService;
