import styled from "styled-components";
import { Button } from "semantic-ui-react";

export const LoginContainer = styled.div`
  overflow-x: scroll;
  height: 100%;
  display: grid;
  place-items: center;
  background-image: linear-gradient(135deg, #d74316 10%, #433833 100%);
`;
export const LoginContainerWebsite = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
`;

export const LoginContent = styled.div`
  width: 70%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const GoogleSignInButton = styled(Button)`
  width: 100%;
  border-radius: 6px;

  i {
    width: 3em;
    border-right: 1px solid white;
  }
`;
