import useEditorQuery from "../../../hooks/useEditorQuery";
import orderClient from "../../../api/order";

const useOrderQuery = (orderId: string) => {
  return useEditorQuery({
    queryKey: ["order", orderId],
    queryFn: async () => orderClient.getOrderInfo(orderId),
    enabled: !!orderId && orderId !== "shopify-redirect",
  });
};
export default useOrderQuery;
