import { Pack } from "context/klydo/KlydoTypes";
import useAppContext from "./useAppContext";
import packService from "firebase_services/packService";
import useEditorQuery from "./useEditorQuery";

const usePacks = () => {
  const { user } = useAppContext();
  return useEditorQuery<Pack[], Pack[]>({
    queryKey: ["packs", user],
    queryFn: () => packService.getPacksByCreator(user),
    enabled: !!user,
  });
};

export default usePacks;
