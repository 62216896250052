import giftClient from "../../../api/gift";
import useEditorQuery from "../../../hooks/useEditorQuery";

const useOrderGift = (orderId: string) => {
  return useEditorQuery({
    queryKey: ["order-gift", orderId],
    queryFn: async () => giftClient.getOrderGift(orderId),
    enabled: !!orderId,
  });
};

export default useOrderGift;
