import React, { useEffect, useState } from "react";
import { Klydo } from "context/klydo/KlydoTypes";
import KlydoCard from "./KlydoCard";
import DSDialogActions from "themes-views/system-design/DSDialogActions";
import DSTextField from "themes-views/system-design/DSTextField";
import DSDialog from "themes-views/system-design/DSDialog";
import DSButton from "themes-views/system-design/DSButtom";
import DSPrimaryButton from "themes-views/system-design/DSPrimaryButtom";
import DSDialogContent from "themes-views/system-design/DSDialogContent";
import DSGrid from "themes-views/system-design/DSGrid";
import DSCircularProgress from "themes-views/system-design/DSCircularProgress";

interface KlydosPickDialogProps {
  onClose: () => void;
  allKlydos: Klydo[];
  selectedKlydos: string[];
  onPickKlydos: (selectedKlydos: string[]) => void;
}

const KlydosPickDialog: React.FC<KlydosPickDialogProps> = ({
  onClose,
  allKlydos,
  selectedKlydos,
  onPickKlydos,
}) => {
  const [selected, setSelected] = useState<string[]>(selectedKlydos);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  const handleToggleSelection = (id: string) => {
    setSelected((prev) => {
      const index = prev.indexOf(id);
      if (index > -1) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const handleCancel = () => {
    setSelected(selectedKlydos);
    onClose();
  };

  const handleSave = (e: React.MouseEvent) => {
    e.preventDefault();
    onPickKlydos(selected);
    onClose();
  };

  const filteredKlydos = allKlydos.filter((klydo) =>
    klydo.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <DSDialog open={true} onClose={handleCancel} fullWidth maxWidth="md">
      <DSDialogContent>
        <DSTextField
          label="Search Klydos"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginBottom: "20px" }}
        />
        <DSGrid container spacing={2}>
          {filteredKlydos.map((klydo) => (
            <DSGrid item key={klydo.id} xs={6} sm={4} md={3}>
              <KlydoCard
                onLoad={() => setLoading(false)}
                klydo={klydo}
                isSelected={selected.includes(klydo.id)}
                onToggleSelection={handleToggleSelection}
              />
            </DSGrid>
          ))}
          {loading && <DSCircularProgress />}
        </DSGrid>
      </DSDialogContent>
      <DSDialogActions>
        <DSButton onClick={handleCancel}>Cancel</DSButton>
        <DSPrimaryButton onClick={handleSave}>Save</DSPrimaryButton>
      </DSDialogActions>
    </DSDialog>
  );
};

export default KlydosPickDialog;
