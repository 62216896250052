import React, { useState } from "react";
import { FaEdit, FaSave, FaMinus } from "react-icons/fa";

const EditableProfile = (props: {
  type: "long" | "short" | "select" | "link";
  default: string;
  onChange: (value: string) => void;
  title?: string;
  style?: React.CSSProperties;
  active?: boolean;
  options?: string[];
  maxLength?: number;
}) => {
  const [active, setActive] = useState(props.active ? props.active : false);
  const [val, setVal] = useState<string>(props.default);
  const [newVal, setNewVal] = useState<string>(props.default);
  const [charsLeft, setCharsLeft] = useState<number>();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        fontSize: "1.4em",
        ...props.style,
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.title && (
          <p style={{ fontSize: "0.7em", margin: 0, paddingRight: 15 }}>
            {props.title}
          </p>
        )}
        {active ? (
          <>
            {props.type === "long" ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <textarea
                  autoFocus
                  onChange={(e) => {
                    setNewVal(e.target.value);
                    if (props.maxLength !== undefined)
                      setCharsLeft(props.maxLength - e.target.value.length);
                  }}
                  defaultValue={val ? val : props.default}
                  maxLength={props.maxLength}
                />
                {charsLeft !== undefined && !Number.isNaN(charsLeft) && (
                  <p
                    style={{
                      color: charsLeft <= 10 ? "red" : "green",
                      fontSize: "10px ",
                    }}
                  >
                    Characters left: {charsLeft}
                  </p>
                )}
              </div>
            ) : props.type === "select" ? (
              <select
                autoFocus
                onChange={(e) => {
                  setNewVal(e.target.value);
                }}
                defaultValue={val ? val : props.default}
              >
                {props.options?.map((o) => (
                  <option key={o} value={o}>
                    {o}
                  </option>
                ))}
              </select>
            ) : (
              <input
                autoFocus
                style={{
                  outline: 0,
                  border: "unset",
                  borderBottom: "solid 1px",
                  padding: 0,
                }}
                onChange={(e) => {
                  setNewVal(e.target.value);
                }}
                defaultValue={val ? val : props.default}
              />
            )}
            <FaSave
              size={parseFloat(props.style.fontSize.valueOf().toString()) * 7}
              style={{ marginLeft: 10 }}
              onClick={(e) => {
                e.stopPropagation();
                props.onChange(newVal);
                setActive(false);
                setVal(newVal);
              }}
            />
          </>
        ) : //not active:
        props.type === "link" ? (
          <>
            <a href={val} target="_blank" rel="noreferrer">
              {val}
            </a>
            <FaEdit
              size={35}
              onClick={(e) => {
                e.stopPropagation();
                setActive(true);
              }}
            />
            <FaMinus
              size={24}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </>
        ) : (
          <p
            style={{
              fontSize: 0.8 + "em",
              margin: 0,
              marginRight: 10 + "px",
              textAlign: "center",
            }}
          >
            {val ? val : props.default}
            <FaEdit
              size={parseFloat(props.style.fontSize.valueOf().toString()) * 7}
              style={{ marginLeft: 10 }}
              onClick={(e) => {
                e.stopPropagation();
                setActive(true);
              }}
            />
          </p>
        )}
      </div>
    </div>
  );
};

export default EditableProfile;
