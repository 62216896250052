import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Role } from "AppContext";
import { Link } from "react-router-dom";
import {
  Button,
  Checkbox,
  Icon,
  Input,
  Menu,
  Modal,
  Popup,
} from "semantic-ui-react";
import UserDropdown from "./UserMenu";
import { useState } from "react";
import firebaseService from "firebase_services/firebaseService";
import ReactFlagsSelect from "react-flags-select";
import { IoClose } from "react-icons/io5";
import EditableProfile from "./EditableProfile";
import ReactCountryFlag from "react-country-flag";
import EarthImg from "../../img/Earth.png";
import { UserAvatar } from "./userStyles";
import { FaEdit } from "react-icons/fa";
import { Rings, ThreeDots } from "react-loader-spinner";
import {
  friendlyIdFromString,
  labelValueState,
  socialMediaList,
  statesList,
  valueLabelState,
} from "utils";
import { Profile } from "context/klydo/KlydoTypes";
import anonimusPhoto from "../../img/anonimus.png";
import EditableSocial from "./EditableSocial";
import KSocialIcon from "themes-views/KSocialIcons";

export function MyProfile(props: {
  user: firebase.User & Role;
  profile: Profile;
}) {
  const p = firebaseService.userProfile;
  const [selectCountry, setSelectCountry] = useState(false);
  const [editEmail, setEditEmail] = useState(p.email !== undefined);
  const [email, setEmail] = useState(p.email ?? props.user.email);
  const [description, setDescription] = useState(p.description ?? "");
  const [country, setCountry] = useState(p.country ?? "EA");
  const [photoUrl, setPhotoUrl] = useState<any>(
    p.photoUrl ?? props.user.photoURL,
  );
  const [loading, setLoading] = useState(false);
  const [clock, setClock] = useState(p.friendlyClock ?? "");
  const [state, setState] = useState(p.state ?? "AL");
  const [updateClock, setUpdateClock] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [openClockModal, setOpenClockModal] = useState(false);
  const [socialMedia, setSocialMedia] = useState(p.socialMedia ?? []);
  const [newSocial, setNewSocial] = useState("");
  let val = "";

  return (
    <div>
      <div>
        <Menu fixed={"top"} pointing inverted style={{ height: 75 }}>
          <Menu.Item header>
            <Link to={"/"}>
              <Icon className="left arrow" />
            </Link>
          </Menu.Item>
          <Menu.Item position={"right"}>
            <UserDropdown user={props.user} profile={props.profile} />
          </Menu.Item>
        </Menu>
      </div>{" "}
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Rings
            height="200"
            width="100%"
            color="black"
            radius="50"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
          />{" "}
        </div>
      ) : (
        <div
          style={{
            paddingTop: 160,
            paddingLeft: 50,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: 200,
              height: 200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ position: "absolute" }}>
              <UserAvatar
                style={{ height: 150 }}
                src={photoUrl || anonimusPhoto}
                size="small"
                circular
                referrerPolicy="no-referrer"
              />
            </span>
            <div style={{ height: 15, width: 15 }}></div>
            <Input
              id="user-photo"
              onChange={(e) => {
                const fr = new FileReader();
                const fr2 = new FileReader();
                fr.onload = function () {
                  setPhotoUrl(fr.result);
                };
                fr2.onload = function () {
                  setTimeout(() => {
                    const url =
                      "https://api.cloudinary.com/v1_1/KlydoClock/auto/upload";
                    const formData = new FormData();
                    formData.append("file", file);
                    formData.append("folder", "profile");
                    formData.append("upload_preset", "profiles");
                    return fetch(url, {
                      method: "POST",
                      body: formData,
                    })
                      .then((response) => {
                        return response.json();
                      })
                      .then((res) => {
                        const img = res.secure_url;
                        if (img) {
                          if (
                            file.type === "image/gif" ||
                            (file.type === "image/webp" &&
                              fr2.result.toString().indexOf("ANMF") !== -1)
                          ) {
                            firebaseService
                              .updateUserProfile(
                                { fb: "animated", user: null, value: "delete" },
                                false,
                              )
                              .then(() => {
                                firebaseService.updateUserAnimatedPhoto(img, {
                                  fb: "photoUrl",
                                  user: "photoURL",
                                  value: img,
                                });
                              });
                          } else {
                            firebaseService.updateUserProfile(
                              { fb: "animated", user: null, value: "delete" },
                              false,
                            );
                            firebaseService.updateUserProfile({
                              fb: "photoUrl",
                              user: "photoURL",
                              value: img,
                            });
                          }
                        }
                      });
                  }, 0);
                };
                const file = e.target.files[0];
                console.log(file);

                if (!file) return;
                fr.readAsDataURL(file);
                fr2.readAsText(file);
              }}
              type="file"
              style={{ width: 100 + "%", height: 100 + "%", opacity: 0 }}
            />
          </div>

          {selectCountry ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ReactFlagsSelect
                selected={country}
                searchable
                onSelect={(code) => {
                  setCountry(code);
                  setSelectCountry(false);
                  firebaseService.updateUserProfile(
                    { fb: "country", user: null, value: code },
                    false,
                  );
                  if (state && code !== "US") {
                    firebaseService.updateUserProfile({
                      fb: "state",
                      user: null,
                      value: "delete",
                    });
                  }
                }}
              />{" "}
              <IoClose
                size={20}
                style={{
                  backgroundColor: "white",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectCountry(false);
                }}
              />
            </div>
          ) : (
            <div style={{ position: "relative", width: 55, height: 55 }}>
              {country === "EA" || !!!country ? (
                <img
                  src={EarthImg}
                  alt="earth"
                  style={{ width: 55, height: 55 }}
                />
              ) : (
                <ReactCountryFlag
                  svg
                  countryCode={country}
                  style={{ width: 55, height: 55 }}
                />
              )}
              <div style={{ position: "absolute", top: 33, left: 47 }}>
                <FaEdit
                  style={{
                    backgroundColor: "white",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectCountry(true);
                  }}
                />
              </div>
            </div>
          )}
          {country === "US" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <EditableProfile
                type="select"
                default={valueLabelState(state)}
                onChange={(v) => {
                  firebaseService.updateUserProfile({
                    fb: "state",
                    user: null,
                    value: labelValueState(v),
                  });
                }}
                options={statesList.map((v) => v.label)}
                style={{ fontSize: "2em" }}
              />
            </div>
          ) : (
            <></>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "50%",
            }}
          >
            <EditableProfile
              type={"short"}
              default={props.user.displayName}
              style={{ fontSize: "2.9em" }}
              onChange={(v) => {
                firebaseService.updateUserProfile({
                  fb: "name",
                  user: "displayName",
                  value: v,
                });
              }}
            />
            {editEmail ? (
              <EditableProfile
                type={"short"}
                default={email}
                style={{ fontSize: "2.5em" }}
                onChange={(v) => {
                  firebaseService.updateUserProfile(
                    { fb: "email", user: null, value: v },
                    false,
                  );
                }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  fontSize: "2.5em",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: 0.8 + "em",
                      margin: 0,
                      marginRight: 4 + "px",
                      textAlign: "center",
                    }}
                  >
                    {props.user.email}
                  </p>
                </div>
              </div>
            )}
            <Checkbox
              style={{ paddingBottom: 10 }}
              checked={editEmail}
              onChange={(e) => setEditEmail(!editEmail)}
              label="Display email for users"
              className="checkbox"
            />
            <div style={{ display: "flex" }}>
              {socialMedia.map((s, i) => (
                <EditableSocial
                  network={s.network}
                  key={i}
                  onChange={(v) =>
                    setSocialMedia((prev) => {
                      prev[i].link = v;
                      firebaseService.updateUserProfile({
                        fb: "socialMedia",
                        user: null,
                        value: prev,
                      });
                      return prev;
                    })
                  }
                  onRemove={() => {
                    setSocialMedia((prev) => {
                      prev.splice(i, 1);
                      firebaseService.updateUserProfile({
                        fb: "socialMedia",
                        user: null,
                        value: prev,
                      });
                      console.log(prev);
                      return prev;
                    });
                  }}
                  link={s.link}
                />
              ))}
              <Popup
                position="right center"
                basic
                on="click"
                pinned
                onClose={() => setNewSocial("")}
                trigger={
                  newSocial ? (
                    <KSocialIcon
                      network={newSocial}
                      title={newSocial}
                      style={{ height: 35, width: 35, margin: 5 }}
                    />
                  ) : socialMedia.length === 0 ? (
                    <Button basic color="blue" content="Add Social Media" />
                  ) : (
                    <Button
                      style={{ height: 35, width: 35, margin: 5 }}
                      basic
                      circular
                      icon="plus"
                    />
                  )
                }
              >
                {newSocial ? (
                  <>
                    <EditableProfile
                      active={newSocial !== ""}
                      type={"short"}
                      default={""}
                      style={{ fontSize: "2em" }}
                      onChange={(val) => {
                        if (val === "") {
                          setNewSocial("");
                          return;
                        }
                        setSocialMedia((prev) => {
                          prev.push({ network: newSocial, link: val });
                          firebaseService.updateUserProfile({
                            fb: "socialMedia",
                            user: null,
                            value: prev,
                          });
                          return prev;
                        });
                        setNewSocial("");
                      }}
                    />
                  </>
                ) : (
                  socialMediaList.map((s) => (
                    <KSocialIcon
                      network={s}
                      key={s}
                      title={s}
                      style={{ height: 35, width: 35, margin: 5 }}
                      onClick={() => {
                        setNewSocial(s);
                      }}
                    />
                  ))
                )}
              </Popup>
            </div>
            <EditableProfile
              active={!description}
              type={"long"}
              maxLength={420}
              style={{ fontSize: "2em" }}
              default={description}
              onChange={(v) => {
                firebaseService.updateUserProfile(
                  { fb: "description", user: null, value: v },
                  false,
                );
              }}
            />

            {!clock ? (
              <Button
                basic
                style={{ margin: 15 }}
                onClick={() => {
                  setError(undefined);
                  setOpenClockModal(true);
                }}
              >
                Connect clock
              </Button>
            ) : (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <EditableProfile
                    active={error !== undefined}
                    title={"Connected Clock:"}
                    type={"short"}
                    style={{ fontSize: "2em" }}
                    default={clock}
                    onChange={(v) => {
                      setError(undefined);
                      setUpdateClock(true);
                      firebaseService
                        .updateClock(v.trim())
                        .then(() => {
                          setUpdateClock(false);
                        })
                        .catch((e) => {
                          setUpdateClock(false);
                          setError(e.message);
                        });
                    }}
                  />
                  <ThreeDots
                    height="20"
                    width="40"
                    radius="9"
                    color="black"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    visible={updateClock}
                  />
                </div>
                <p style={{ color: "red" }}>{error}</p>
                <Button
                  basic
                  style={{ margin: 15 }}
                  onClick={() => {
                    setError(undefined);
                    setOpenClockModal(true);
                  }}
                >
                  Disconnect clock
                </Button>
              </>
            )}
          </div>
        </div>
      )}
      <Modal
        style={{ marginTop: "-20%" }}
        centered={false}
        size={"mini"}
        open={openClockModal}
        onClose={() => setOpenClockModal(false)}
      >
        <Modal.Header>{`Clock Connection`}</Modal.Header>
        <Modal.Content>
          {clock ? (
            <p>Are you sure you want to disconnect this clock?</p>
          ) : (
            <>
              <p>Please enter a clock id to connect</p>
              <input
                autoFocus
                style={{
                  outline: 0,
                  border: "unset",
                  borderBottom: "solid 1px",
                  padding: 0,
                }}
                onChange={(e) => {
                  val = e.target.value;
                }}
                placeholder="Clock ID"
              />
              <p style={{ color: "red" }}>{error}</p>
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setOpenClockModal(false)}>
            Cancel
          </Button>
          <Button
            loading={updateClock}
            positive
            onClick={() => {
              setUpdateClock(true);
              if (clock) {
                firebaseService.disconnectClock().then(() => {
                  setUpdateClock(false);
                  setOpenClockModal(false);
                  setClock(undefined);
                });
              } else {
                firebaseService
                  .updateClock(val.trim())
                  .then(() => {
                    setUpdateClock(false);
                    setOpenClockModal(false);
                    setClock(val);
                  })
                  .catch((e) => {
                    setUpdateClock(false);
                    setError(e.message);
                  });
              }
            }}
          >
            {clock ? "Yes" : "Connect"}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
