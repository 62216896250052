import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ThemesView } from "./themes-views/ThemesView";
import LoginContainer from "./LoginView/LoginContainer";
import Register from "./LoginView/RegisterContainer";
import withSplashScreen from "./LoginView/SplashScreen";
import ForgotPassword from "./LoginView/ForgotPasssowrdContainer";
import ForgotPasswordWebsite from "./LoginView/ForgotPasswordWebsiteContainer";
import LoginWebsite from "./LoginView/LoginContainerWebsite";
import ResetPassword from "./LoginView/ResetPasswordContainer";
import ResetPasswordWebsite from "./LoginView/ResetPasswordWebsiteContainer";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import AppContext, { Role } from "./AppContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import GiftHomePage from "./themes-views/Gifts/GiftHomePage";
import GiftWizard from "./themes-views/Gifts/GiftWizard";
import { GiftContextProvider } from "./themes-views/Gifts/Context/GiftContextProvider";
import GiftDone from "./themes-views/Gifts/GiftDone";
const queryClient = new QueryClient();

const App = (props: { user: firebase.User & Role }) => {
  // create a query client

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={{ user: props.user }}>
        <GiftContextProvider>
          <BrowserRouter>
            <Routes>
              {props.user ? (
                <Route path="/*" element={<ThemesView user={props.user} />} />
              ) : (
                <Route path="login" element={<LoginContainer />} />
              )}
              <Route path="/gift" element={<GiftHomePage />} />
              <Route path="/gift/wizard" element={<GiftWizard />} />
              <Route path="/gift/done" element={<GiftDone />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/forgot-password-website"
                element={<ForgotPasswordWebsite />}
              />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/reset-password-website"
                element={<ResetPasswordWebsite />}
              />
              <Route path="/website" element={<LoginWebsite />} />
              <Route
                path="*"
                element={<Navigate to={props.user ? "/" : "login"} />}
              />
            </Routes>
          </BrowserRouter>
        </GiftContextProvider>
      </AppContext.Provider>
    </QueryClientProvider>
  );
};

export default withSplashScreen(App);
