import React, { useEffect, useState } from "react";
import { FaEdit, FaSave } from "react-icons/fa";

const Editable = (props: {
  type: "long" | "short";
  title: string;
  default: string;
  onChange: (value: string) => void;
  style?: React.CSSProperties;
  obj: { hook?: (v: string) => void };
}) => {
  const [active, setActive] = useState(false);
  const [newVal, setNewVal] = useState<string>();
  const [val, setVal] = useState<string>();

  props.obj.hook = (v: string) => {
    setActive(false);
    setNewVal(v);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        fontSize: "2em",
        ...props.style,
      }}
    >
      <p style={{ fontSize: 0.6 + "em", margin: 0 }}>{props.title}:</p>
      {active ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          {props.type == "long" ? (
            <textarea
              autoFocus
              onChange={(e) => {
                setVal(e.target.value);
              }}
              defaultValue={newVal ? newVal : props.default}
            />
          ) : (
            <input
              onKeyUp={(e) => console.log(e)}
              onSubmit={(e) => console.log(e)}
              autoFocus
              style={{
                outline: 0,
                border: "unset",
                borderBottom: "solid 1px",
                padding: 0,
              }}
              onChange={(e) => {
                setVal(e.target.value);
              }}
              defaultValue={newVal ? newVal : props.default}
            />
          )}
          <FaSave
            style={{ marginLeft: 8 + "px" }}
            onClick={(e) => {
              e.stopPropagation();
              props.onChange(val);
              setActive(false);
              setNewVal(val);
            }}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: 1 + "em", margin: 0, marginRight: 4 + "px" }}>
            {newVal ? newVal : props.default}{" "}
            <FaEdit
              onClick={(e) => {
                e.stopPropagation();
                setActive(true);
              }}
            />
          </p>
        </div>
      )}
    </div>
  );
};

export default Editable;
