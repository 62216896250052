import {
  Button,
  Checkbox,
  Dropdown,
  DropdownItemProps,
  Grid,
  Icon,
  Input,
  Menu,
} from "semantic-ui-react";
import { Link, Route, Routes } from "react-router-dom";
import UserDropdown from "./user/UserMenu";
import { Klydo } from "../context/klydo/KlydoTypes";
import ThemesListItem from "./ThemesListItem";
import ThemeContainer from "./theme-views/ThemeContainer";
import firebaseService from "../firebase_services/firebaseService";
import { Role } from "../AppContext";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useEffect, useState } from "react";
import { MyProfile } from "./user/MyProfile";
import ButtonLoader from "./ButtonLoader";
import { BatchUpload } from "./BatchUpload";
import PacksCreationContainer from "./Packs/PacksCreationContainer";
import PacksContainer from "./Packs/PacksContainer";
import usePacks from "../hooks/usePacks";

let isMyKlydos = true;
let isHideDraft = false;
let searchValue = "";
let chosenUser = "";

export function ThemesView(props: { user: firebase.User & Role }) {
  const [displayMyKlydos, setDisplayMyKlydos] = useState<boolean>(isMyKlydos);
  const [loadList, setLoadList] = useState(false);
  const [klydos, setKlydos] = useState(firebaseService.klydosList);
  const [allKlydos, setAllKlydos] = useState(
    props.user.role === "admin" ? firebaseService.allKlydos : [],
  );
  const [profile, setProfile] = useState(firebaseService.userProfile);
  const [usersDropdown, setUserDropDown] = useState<Array<DropdownItemProps>>();
  const [filteredKlydos, setFilteredKlydos] = useState<Array<Klydo>>();

  const filterList = (list?: Array<Klydo>) => {
    let klds = list || klydos;
    if (isHideDraft) {
      klds = klds.filter((k) => k.public);
      if (searchValue.length) {
        klds = klds.filter(
          (k) =>
            (k.loopUrl && k.loopUrl.toLowerCase().includes(searchValue)) ||
            (k.name && k.name.toLowerCase().includes(searchValue)) ||
            k.id.includes(searchValue),
        );
      }
    } else {
      if (searchValue.length) {
        klds = klds.filter(
          (k) =>
            (k.loopUrl && k.loopUrl.toLowerCase().includes(searchValue)) ||
            (k.name && k.name.toLowerCase().includes(searchValue)) ||
            k.id.includes(searchValue),
        );
      } else klds = undefined;
    }
    setFilteredKlydos(klds);
  };

  useEffect(() => {
    setKlydos([...firebaseService.klydosList]);
    firebaseService.addListener("profile", (pro: any) => {
      setProfile(pro);
    });
    firebaseService.addListener("my_klydos", (myKlydos: Partial<Klydo[]>) => {
      if (props.user.role === "admin" && isMyKlydos) setKlydos([...myKlydos]);
      else setKlydos([...myKlydos]);
    });
    if (props.user.role === "admin")
      firebaseService.addListener(
        "admin_klydos",
        (adminKlydos: Partial<Klydo[]>) => {
          setAllKlydos([...adminKlydos]);
          if (!isMyKlydos) {
            if (chosenUser != "") {
              if (isHideDraft || searchValue.length)
                filterList(adminKlydos.filter((k) => k.userId == chosenUser));
              else setKlydos(adminKlydos.filter((k) => k.userId == chosenUser));
            } else setKlydos([...adminKlydos]);
          }
        },
      );
    return () => {
      firebaseService.removeListener("profile");
      firebaseService.removeListener("my_klydos");
      if (props.user.role === "admin")
        firebaseService.removeListener("admin_klydos");
    };
  }, [props.user.role]);

  useEffect(() => {
    if (props.user.role === "admin") {
      const usersList = Array<any>();
      firebaseService.users.forEach((u) => {
        if (
          (u.customClaims &&
            (u.customClaims.role === "editor" ||
              u.customClaims.role === "admin")) ||
          !u.customClaims
        )
          usersList.push({
            key: u.uid,
            text: u.displayName ?? u.email,
            value: u.uid,
          });
      });
      usersList.sort((a, b) => a.text?.localeCompare(b.text));

      setUserDropDown(usersList);
    }
  }, [props.user.role]);

  const { data: packs } = usePacks();

  const onDropChange = (e, f) => {
    chosenUser = f.value;
    const lst =
      f.value != ""
        ? firebaseService.allKlydos.filter((k) => k.userId == f.value)
        : firebaseService.allKlydos;
    setKlydos(lst);
    filterList(lst);
  };

  const updateKlydosList = () => {
    return new Promise<void>((v, x) => {
      if (displayMyKlydos) {
        setLoadList(true);
        firebaseService
          .adminAllKlydos()
          .then(() => {
            setLoadList(false);
            setDisplayMyKlydos(false);
            setKlydos(firebaseService.allKlydos);
            isMyKlydos = false;
            v();
          })
          .catch((e) => {
            console.log(e);
            x();
          });
      } else {
        setFilteredKlydos(undefined);
        searchValue = "";
        isHideDraft = false;
        chosenUser = "";
        setKlydos(firebaseService.klydosList);
        setDisplayMyKlydos(true);
        isMyKlydos = true;
        v();
      }
    });
  };

  return (
    <>
      <div>
        <Menu fixed={"top"} pointing inverted>
          <Menu.Item>
            <Link
              state={{ isNewTheme: true }}
              to={"/klydo/new"}
              className="button primary"
            >
              <Button color="blue">
                <Icon name="edit" /> New
              </Button>
            </Link>
          </Menu.Item>
          {props.user.role === "admin" && (
            <Menu.Item>
              <Link to={"/batch-upload"} className="button primary">
                <Button color="blue">Batch Upload</Button>
              </Link>
            </Menu.Item>
          )}
          {props.user.role === "admin" && (
            <Menu.Item>
              <ButtonLoader
                color="blue"
                title={displayMyKlydos ? "Manage Klydos" : "Back to My Klydos"}
                loading={loadList}
                onClick={updateKlydosList}
              />
            </Menu.Item>
          )}
          <Menu.Item>
            <Link
              state={{ isNewTheme: true }}
              to={"/pack/new"}
              className="button primary"
            >
              <Button color="blue">
                <Icon name="edit" /> New Pack
              </Button>
            </Link>
          </Menu.Item>
          {packs?.length > 0 && (
            <Menu.Item>
              <Link to={"/packs"} className="button primary">
                <Button color="blue">My Packs</Button>
              </Link>
            </Menu.Item>
          )}
          {props.user.role === "admin" && !displayMyKlydos && (
            <Menu.Item>
              <div>
                <Dropdown
                  style={{ width: 300 }}
                  className="selectDropdown"
                  search
                  selection
                  clearable
                  closeOnChange
                  options={usersDropdown}
                  placeholder="Select user"
                  onChange={onDropChange}
                />

                <Input
                  style={{ marginLeft: 10, marginRight: 10 }}
                  placeholder="Search..."
                  icon="search"
                  onChange={(e, d) => {
                    const val = d.value.toLowerCase();
                    searchValue = val;
                    filterList();
                  }}
                />
                <Checkbox
                  style={{ paddingBottom: 10 }}
                  onChange={(e, d) => {
                    isHideDraft = d.checked;
                    filterList();
                  }}
                  label={<label style={{ color: "white" }}>Hide draft</label>}
                  className="checkbox"
                />
              </div>
            </Menu.Item>
          )}
          <Menu.Item position={"right"}>
            <UserDropdown user={props.user} profile={profile} />
          </Menu.Item>
        </Menu>
      </div>
      <Routes>
        <Route
          path="/my-profile"
          element={<MyProfile user={props.user} profile={profile} />}
        />
        <Route
          path="/batch-upload"
          element={<BatchUpload user={props.user} profile={profile} />}
        />
        <Route
          path="pack/*"
          element={
            <PacksCreationContainer
              user={props.user}
              profile={profile}
              // klydos={props.user.role === "admin" ? allKlydos : klydos}
            />
          }
        />
        <Route
          path="/packs"
          element={
            <PacksContainer
              user={props.user}
              profile={profile}
              packs={packs}
              // klydos={props.user.role === "admin" ? allKlydos : klydos}
            />
          }
        />
        <Route
          path="klydo/*"
          element={
            <ThemeContainer
              user={props.user}
              profile={profile}
              klydos={props.user.role === "admin" ? allKlydos : klydos}
            />
          }
        />
        <Route
          path="/"
          element={
            // loadList ? <div style={{
            //   width: "100%",
            //   height: "100%",
            //   display: "flex",
            //   justifyContent: "center",
            //   alignItems: "center"
            // }}><Rings
            //     height="200"
            //     width="100%"
            //     color="black"
            //     radius="50"
            //     wrapperStyle={{}}
            //     wrapperClass=""
            //     visible={loadList}
            //     ariaLabel="rings-loading"
            //   /> </div> :
            <Grid style={{ paddingTop: "85px" }} container spacing={0}>
              {(filteredKlydos || klydos).map((klydo: Klydo, index: number) => {
                return <ThemesListItem key={klydo.id} klydo={{ ...klydo }} />;
              })}
            </Grid>
          }
        />
      </Routes>
    </>
  );
}
