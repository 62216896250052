import axios, { AxiosProgressEvent } from "axios";

export type UploadFileInput = {
  file: File | string;
  folder: string;
  upload_preset?: string;
  onUploadProgress?: (progress: number) => void;
};

const uploadFile = async (input: UploadFileInput) => {
  try {
    const response = await axios.post(
      "https://api.cloudinary.com/v1_1/KlydoClock/auto/upload",
      {
        file: input.file,
        folder: input.folder,
        upload_preset: input.upload_preset || "Bezalel_preset",
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (input.onUploadProgress) {
            const percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            input.onUploadProgress(percentCompleted);
          }
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Upload error:", error);
    throw new Error("Upload failed");
  }
};

export default uploadFile;
